import { toast } from '@/app/wrappers/Toaster/Toaster';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { getBatchUploadErrorMessage, useBatchFileCreate } from '@/services/useBatchFiles';
import { BatchFileType } from '@/shared/types';
import { Spinner } from '@checkrx/pay-component-library';
import dayjs from 'dayjs';
import { useState } from 'react';
import { TAX_YEARS } from '../utils';

export const BulkUpdateDialog = () => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const {
    error: batchUploadError,
    isLoading: batchCreateIsLoading,
    isError: batchCreateIsError,
    mutate: uploadBatchFile,
  } = useBatchFileCreate();

  const handleClose = () => {
    setOpen(false);
    setFile(null);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Stub: Just set the first file if one exists
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    uploadBatchFile(
      {
        file: file,
        type: BatchFileType.Taxes1099Update,
        runAt: dayjs().startOf('minute').toDate(),
      },
      {
        onSuccess: () => {
          toast({
            message: 'Successfully uploaded bulk update and awaiting processing',
            type: 'success',
            duration: 5000,
          });
          handleClose();
        },
      }
    );
  };

  const columnGroups = [
    {
      title: 'Required columns',
      columns: [
        {
          name: 'workerCheckrPayId',
          description:
            "Worker's ID - use Checkr Pay ID when workerIdType is 'checkrPayId' (e.g. 6e9ce577-4b68-45a6-839a-551a47d670a1) or use customer metadata when workerIdType is 'metadata'",
        },
        {
          name: 'workerIdType',
          description: "Must be either 'checkrPayId' or 'metadata'",
        },
      ],
    },
    {
      title: 'Optional columns',
      columns: [
        {
          name: 'manuallyExcluded',
          description: 'Set to "true" to exclude worker from tax actions',
        },
        {
          name: 'nonPlatformEarningsAmount',
          description: 'Amount earned outside Checkr Pay (in cents, e.g. $500 = 50000)',
        },
        {
          name: 'overrideTotalEarningsAmount',
          description:
            'Override total earnings calculation (in cents, e.g. $500 = 50000). This will replace both external and platform earnings with a single override amount. Only use for special cases where the standard earnings calculation needs to be bypassed.',
        },
        {
          name: 'hyphenateLastName',
          description: 'Set to "true" to hyphenate last name on tax forms',
        },
      ],
    },
  ];

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outline" size="sm">
        Bulk Update Tax Info
      </Button>

      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent className="max-w-3xl">
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium">Bulk Update Tax Information</h3>
              <p className="text-sm text-gray-500">
                Upload a spreadsheet to update tax settings for multiple workers at once
              </p>
            </div>

            <div className="space-y-4">
              <div className="rounded-lg border p-4">
                <h4 className="font-medium mb-2">Upload File</h4>

                <div className="space-y-4">
                  <Input
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    className="cursor-pointer file:cursor-pointer"
                  />
                </div>

                {batchCreateIsError ? (
                  <div className="text-xs text-red-500">
                    {getBatchUploadErrorMessage(batchUploadError)}
                  </div>
                ) : null}

                <div className="mt-4 space-y-4">
                  <div>
                    <h5 className="text-sm font-medium mb-2">CSV Format</h5>
                    <div className="text-xs space-y-3">
                      {columnGroups.map((group) => (
                        <div key={group.title}>
                          <p>
                            <strong>{group.title}:</strong>
                          </p>
                          {group.columns.map((column) => (
                            <div key={column.name}>
                              <p className="font-medium mt-2">{column.name}</p>
                              <p className="text-gray-500">{column.description}</p>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <h5 className="text-sm font-medium mb-2">Example CSV:</h5>
                    <pre className="text-xs bg-gray-100 p-2 rounded block overflow-x-auto whitespace-pre-wrap break-words max-w-[680px]">
                      workerIdType,workerCheckrPayId,manuallyExcluded,nonPlatformEarningsAmount,overrideTotalEarningsAmount,hyphenateLastName
                      <br />
                      checkrPayId,6e9ce577-4b68-45a6-839a-551a47d670a1,true,50000,75000,false
                    </pre>
                  </div>

                  <div className="text-xs text-gray-500">
                    <p className="font-medium">Notes:</p>
                    <ul className="list-disc list-inside space-y-1">
                      <li>
                        Boolean fields accept &quot;true&quot; or &quot;false&quot; (case
                        insensitive)
                      </li>
                      <li>
                        Changes will be applied to the currently selected tax year{' '}
                        {`(${TAX_YEARS[0]})`}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={handleUpload} disabled={!file || batchCreateIsLoading}>
                {batchCreateIsLoading
                  ? ((<Spinner size="30px" />) as unknown as string)
                  : 'Upload and Apply Changes'}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};


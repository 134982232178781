import { Button } from '@/components/ui/button';
import { Dialog } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { MenuButton } from '@/components/ui/menu-button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Skeleton } from '@/components/ui/skeleton-loader';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { usePayouts } from '@/services/usePayouts';
import { useWorkers } from '@/services/useWorkers';
import { useGetOneTaxes } from '@/services/useWorkerTaxes';
import DollarAmount from '@/shared/components/DollarAmount';
import { GenericSection } from '@/shared/components/List/List';
import { ListView } from '@/shared/components/ListView/ListView';
import { Worker } from '@/shared/types';
import { generateAndDownloadCsv } from '@/shared/util';
import { formatCentsAsDollars } from '@/shared/utils/formatters';
import { Icon } from '@checkrx/pay-component-library';
import dayjs from 'dayjs';
import { useQueryState } from 'nuqs';
import { useState } from 'react';
import { AddExternalEarningsIndividual } from './Individiual/AddExternalEarningsIndividual';
import { FileIndividual1099 } from './Individiual/FileIndividual1099';
import { GenerateIndividual1099 } from './Individiual/GenerateIndividual1099';
import { OverrideEarningsIndividual } from './Individiual/OverrideEarningsIndividual';
import { RequestIndividualCorrection } from './Individiual/RequestIndividualCorrection';
import { TaxYears, WorkerWithEarnings } from './taxes.types';
import { TaxStatus } from './TaxStatus';
import { checkIsIneligible } from './TaxWizard/utils';
import { getWorkersWithPayoutTotals, TAX_YEARS, viewTaxPdf } from './utils';

export const TaxesTable = () => {
  const [search, setSearch] = useQueryState('search', { defaultValue: '' });
  const [selectedStatus, setSelectedStatus] = useQueryState('status', { defaultValue: 'all' });
  const [taxFilingYear, setTaxFilingYear] = useQueryState('year', {
    defaultValue: TAX_YEARS[0],
    parse: (value) => Number(value) as TaxYears,
  });
  const { data: fetchedWorkers, isError, isLoading } = useWorkers();
  const [selectedWorker, setSelectedWorker] = useState<WorkerWithEarnings>();

  // modals
  const [showGenerate1099Modal, setShowGenerate1099Modal] = useState(false);
  const [showConfirmFileModal, setShowConfirmFileModal] = useState(false);
  const [showRequestCorrectionModal, setShowRequestCorrectionModal] = useState(false);
  const [showAddExternalEarningsModal, setShowAddExternalEarningsModal] = useState(false);
  const [showOverrideEarningsModal, setShowOverrideEarningsModal] = useState(false);
  const {
    data: fetchedPayouts,
    isError: payoutsError,
    isLoading: payoutsLoading,
  } = usePayouts({
    startDate: dayjs(`01-01-${taxFilingYear}`).toDate(),
    endDate: dayjs(`12-31-${taxFilingYear}`).toDate(),
  });

  const { mutateAsync: getOneTaxes } = useGetOneTaxes();

  const filterWorkerByStatus = (w: WorkerWithEarnings): boolean => {
    const isIneligible = checkIsIneligible(w);
    if (selectedStatus === 'all') return true;
    if (selectedStatus === 'INELIGIBLE') {
      return isIneligible;
    }
    if (!w?.['1099nec']?.[taxFilingYear]?.status && !isIneligible) {
      return selectedStatus === 'NOT_STARTED';
    }
    return w?.['1099nec']?.[taxFilingYear]?.status === selectedStatus;
  };
  const filterWorkerByName = (w: Worker): boolean => {
    if (search === '') return true;
    return (w.profile?.legalName ?? '').toLowerCase().includes(search.toLowerCase());
  };

  const workersWithPayoutTotals = getWorkersWithPayoutTotals(
    fetchedWorkers ?? [],
    fetchedPayouts ?? [],
    taxFilingYear
  );

  const filteredWorkers = workersWithPayoutTotals?.filter((w) => {
    return filterWorkerByStatus(w) && filterWorkerByName(w);
  });

  const handleOpenConfirmFileModal = (worker: WorkerWithEarnings) => {
    setSelectedWorker(worker);
    setShowConfirmFileModal(true);
  };

  const handleOpenRequestCorrectionModal = (worker: WorkerWithEarnings) => {
    setSelectedWorker(worker);
    setShowRequestCorrectionModal(true);
  };

  const handleOpenGenerate1099Modal = (worker: WorkerWithEarnings) => {
    setSelectedWorker(worker);
    setShowGenerate1099Modal(true);
  };

  const handleOpenAddExternalEarningsModal = (worker: WorkerWithEarnings) => {
    setSelectedWorker(worker);
    setShowAddExternalEarningsModal(true);
  };

  const handleOpenOverrideEarningsModal = (worker: WorkerWithEarnings) => {
    setSelectedWorker(worker);
    setShowOverrideEarningsModal(true);
  };

  const handleViewPdf = async (worker: Worker) => {
    const newTaxObj = await getOneTaxes({ workerProfileId: worker.id, year: taxFilingYear });
    viewTaxPdf(newTaxObj.payeeUrl ?? worker?.['1099nec']?.[taxFilingYear]?.payeeUrl ?? '');
  };

  const getViewPdfState = (worker: WorkerWithEarnings) => {
    if (worker?.['1099nec']?.[taxFilingYear]?.manuallyExcluded) return 'hidden';
    return worker?.['1099nec']?.[taxFilingYear]?.payeeUrl ? 'enabled' : 'hidden';
  };

  const addExternalEarningsState = (worker: WorkerWithEarnings) => {
    if (worker?.['1099nec']?.[taxFilingYear]?.manuallyExcluded) return 'hidden';
    if (worker?.['1099nec']?.[taxFilingYear]?.status) return 'hidden';
    return 'enabled';
  };

  const generate1099NecState = (worker: WorkerWithEarnings) => {
    if (worker?.['1099nec']?.[taxFilingYear]?.manuallyExcluded) return 'hidden';
    if (worker?.['1099nec']?.[taxFilingYear]?.status === 'CREATED') return 'hidden';
    if (worker?.['1099nec']?.[taxFilingYear]?.status === 'FILED') return 'hidden';
    if (worker?.['1099nec']?.[taxFilingYear]?.status) return 'disabled';
    if (worker?.['1099nec']?.[taxFilingYear]?.nonPlatformIncome === 0) return 'disabled';
    if (worker?.aboundTinVerification?.tinVerificationStatus !== 'MATCH') return 'disabled';
    if (worker.totalEarnings < 60000) return 'disabled';
    return 'enabled';
  };

  const getFile1099State = (worker: WorkerWithEarnings) => {
    if (worker?.['1099nec']?.[taxFilingYear]?.manuallyExcluded) return 'hidden';
    return worker?.['1099nec']?.[taxFilingYear]?.status === 'CREATED' ? 'enabled' : 'hidden';
  };

  const sections: GenericSection<WorkerWithEarnings>[][] = [
    [
      {
        id: 'legalName',
        title: 'Worker',
        content: (row) => {
          if (row?.workerBusinessInformation?.businessName) {
            return row.workerBusinessInformation.businessName;
          }
          return row.profile?.legalName || row.id;
        },
        width: 280,
      },
      {
        id: 'earnings',
        title: 'Checkr Pay earnings',
        content: (row) =>
          payoutsLoading ? <Skeleton /> : <DollarAmount amountCents={row.earnedCentsTotal} />,
        width: 160,
      },
      {
        id: 'externalEarnings',
        title: (
          <div className="flex items-center gap-1">
            External earnings
            <Tooltip>
              <TooltipTrigger>
                <Icon name="help-circle" color="primary" />
              </TooltipTrigger>
              <TooltipContent>Earnings reported from outside of Checkr Pay</TooltipContent>
            </Tooltip>
          </div>
        ),
        content: (row) =>
          payoutsLoading ? <Skeleton /> : <DollarAmount amountCents={row.externalEarningsTotal} />,
        width: 168,
        minScreenWidth: 1200,
      },
      {
        id: 'totalEarnings',
        title: 'Total earnings',
        content: (row) =>
          payoutsLoading ? (
            <Skeleton />
          ) : (
            <div className="flex flex-col">
              <DollarAmount amountCents={row.totalEarnings} />
              {(row?.['1099nec']?.[taxFilingYear]?.overrideTotalEarningsAmount ?? 0) > 0 && (
                <div className="text-xs text-yellow-500">Manually overridden</div>
              )}
            </div>
          ),
        width: 160,
      },
    ],
    [
      {
        id: 'status',
        title: 'Status',
        content: (row) => (payoutsLoading ? <Skeleton /> : <TaxStatus worker={row} />),
        width: 240,
      },
      {
        id: 'actions',
        title: '',
        content: (row) =>
          taxFilingYear === TAX_YEARS[0] ? (
            <MenuButton
              variant="ghost"
              options={[
                {
                  label: 'View PDF',
                  action: () => handleViewPdf(row),
                  state: getViewPdfState(row),
                },
                {
                  label: 'Generate 1099-NEC',
                  action: () => handleOpenGenerate1099Modal(row),
                  state: generate1099NecState(row),
                },
                {
                  label: 'Add external earnings',
                  action: () => handleOpenAddExternalEarningsModal(row),
                  state: addExternalEarningsState(row),
                },
                {
                  label: 'Override total earnings',
                  action: () => handleOpenOverrideEarningsModal(row),
                  state: addExternalEarningsState(row),
                },
                {
                  label: 'File 1099',
                  action: () => handleOpenConfirmFileModal(row),
                  state: getFile1099State(row),
                },
                {
                  label: 'Request correction',
                  action: () => handleOpenRequestCorrectionModal(row),
                  state:
                    row?.['1099nec']?.[taxFilingYear]?.status === 'FILED' ? 'enabled' : 'hidden',
                },
              ]}
            />
          ) : null,
        width: 40,
      },
    ],
  ];

  const handleGenerateCsv = () => {
    generateAndDownloadCsv(filteredWorkers ?? [], (worker) => ({
      id: worker.id,
      email: worker?.profile?.email,
      phoneNumber: worker?.profile?.phoneNumber,
      firstName: worker?.profile?.legalName?.split(' ')[0] || '',
      // We want the last name to include the rest of the names
      lastName: worker?.profile?.legalName?.split(' ').slice(1).join(' ') || '',
      taxForm: '1099-NEC',
      taxYear: taxFilingYear,
      onPlatformEarnings: formatCentsAsDollars(worker.earnedCentsTotal),
      offPlatformEarnings: formatCentsAsDollars(worker.externalEarningsTotal),
      totalEarnings: formatCentsAsDollars(worker.totalEarnings),
      deliveryMethod: 'Paperless',
      workerStatus: worker?.['1099nec']?.[taxFilingYear]?.status ?? 'NOT_STARTED',
      error: worker?.['1099nec']?.[taxFilingYear]?.error ?? '',
    }));
  };

  return (
    <div className="flex flex-col gap-4 bg-primaryWhite p-4 h-full">
      <ListView
        sections={sections}
        data={filteredWorkers}
        loading={isLoading || payoutsLoading}
        error={isError || payoutsError}
        header={
          <div className="flex w-full min-w-fit h-fit items-center justify-between">
            <div className="flex flex-row items-center gap-5">
              <Input
                value={search}
                className="w-[400px]"
                placeholder="Search by name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(e.target.value);
                }}
              />
              <Select value={selectedStatus} onValueChange={setSelectedStatus}>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Any Status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">Any Status</SelectItem>
                  <SelectItem value="INELIGIBLE">Ineligible</SelectItem>
                  <SelectItem value="NOT_STARTED">Not started</SelectItem>
                  <SelectItem value="CREATED">Created</SelectItem>
                  <SelectItem value="FILED">Filed</SelectItem>
                  <SelectItem value="ACCEPTED">Accepted</SelectItem>
                  <SelectItem value="REJECTED">Rejected</SelectItem>
                  <SelectItem value="QUEUED">Queued</SelectItem>
                  <SelectItem value="FAILED">Failed</SelectItem>
                </SelectContent>
              </Select>
              <Select
                value={taxFilingYear.toString()}
                onValueChange={(value) => setTaxFilingYear(Number(value) as TaxYears)}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select Year" />
                </SelectTrigger>
                <SelectContent>
                  {TAX_YEARS.map((year) => (
                    <SelectItem key={year} value={year.toString()}>
                      {year}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {(search !== '' || selectedStatus !== 'all' || taxFilingYear !== TAX_YEARS[0]) && (
                <Button
                  variant="outline"
                  onClick={() => {
                    setSearch('');
                    setSelectedStatus('all');
                    setTaxFilingYear(TAX_YEARS[0]);
                  }}
                >
                  Clear all
                </Button>
              )}
            </div>
            <Button variant="secondary" onClick={handleGenerateCsv}>
              Generate CSV
            </Button>
          </div>
        }
      />
      <Dialog open={showGenerate1099Modal} onOpenChange={() => setShowGenerate1099Modal(false)}>
        {selectedWorker && (
          <GenerateIndividual1099
            onClose={() => setShowGenerate1099Modal(false)}
            worker={selectedWorker}
            onSuccess={() => {
              setShowGenerate1099Modal(false);
            }}
          />
        )}
      </Dialog>
      <Dialog
        open={showAddExternalEarningsModal}
        onOpenChange={() => setShowAddExternalEarningsModal(false)}
      >
        {selectedWorker && (
          <AddExternalEarningsIndividual
            onClose={() => setShowAddExternalEarningsModal(false)}
            worker={selectedWorker}
            onSuccess={() => {
              setShowAddExternalEarningsModal(false);
            }}
          />
        )}
      </Dialog>
      <Dialog
        open={showOverrideEarningsModal}
        onOpenChange={() => setShowOverrideEarningsModal(false)}
      >
        {selectedWorker && (
          <OverrideEarningsIndividual
            onClose={() => setShowOverrideEarningsModal(false)}
            worker={selectedWorker}
            onSuccess={() => {
              setShowOverrideEarningsModal(false);
            }}
          />
        )}
      </Dialog>
      <Dialog open={showConfirmFileModal} onOpenChange={() => setShowConfirmFileModal(false)}>
        {selectedWorker && (
          <FileIndividual1099
            worker={selectedWorker}
            onClose={() => setShowConfirmFileModal(false)}
            onSuccess={() => {
              setShowConfirmFileModal(false);
            }}
          />
        )}
      </Dialog>
      <Dialog
        open={showRequestCorrectionModal}
        onOpenChange={() => setShowRequestCorrectionModal(false)}
      >
        {selectedWorker && (
          <RequestIndividualCorrection
            worker={selectedWorker}
            onClose={() => setShowRequestCorrectionModal(false)}
            onSuccess={() => {
              setShowRequestCorrectionModal(false);
            }}
          />
        )}
      </Dialog>
    </div>
  );
};


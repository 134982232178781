/**
 * A Query that allows us to fetch and search statements from the backend
 */
import { fetchTransactions } from '@/services/useTransactions';
import { Statement } from '@/shared/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import serverApi from './serverApi';

async function fetchStatements() {
  const response = await serverApi.get('/dashboard/v0/finance/statements');
  return response.data;
}

export const useStatements = () => {
  return useQuery({
    queryKey: ['customer', 'statements'],
    queryFn: () => fetchStatements(),
    select: (data: { statements: Array<Statement> }): Array<Statement> => {
      // Reverse the order of the statements
      const { statements } = data;
      return [...statements].reverse(); // Return new array, not mutated in place
    },
  });
};

async function downloadStatement(statementId: string) {
  const response = await serverApi.get<Blob>(`/dashboard/v0/finance/statements/${statementId}`, {
    responseType: 'blob',
  });

  fileDownload(response.data, `statement_${statementId}.pdf`);
}

export const useDownloadStatementPdf = () => {
  return useMutation({
    mutationFn: downloadStatement,
  });
};

async function generateStatementCsv({
  statementId,
  month,
}: {
  statementId: string;
  month: string;
}) {
  // we need to get the last day of the previous month as well
  // there is cases where a transaction is submitted after bank close on the last day
  // of the previous month and will show on the next months statement,
  // the map below will just ignore the extras if there is any.
  const startDate = dayjs(month).startOf('month').businessDaysSubtract(1).toDate();
  const endDate = dayjs(month).endOf('month').toDate();
  const [{ transactions }, { data }] = await Promise.all([
    fetchTransactions(startDate, endDate, 5000),
    serverApi.get<string>(`/dashboard/v0/finance/statements/${statementId}/html`),
  ]);

  const transactionsMap = new Map(transactions.map((txn) => [txn.transactionId, txn]));
  const parser = new DOMParser();
  const doc = parser.parseFromString(data, 'text/html');
  const table = doc.querySelector('table');
  if (!table) {
    throw new Error('No table found in the HTML document');
  }

  let csvContent = '';
  const rows = table.querySelectorAll('tr');
  // THESE ARE HARDCODED EXPECTED TO BE NON TRANSACTION ROWS FROM UNIT
  const HEADER_ROWS = [0, 1];
  const FOOTER_ROWS = [rows.length - 1];
  const INFO_ROW = [...HEADER_ROWS, ...FOOTER_ROWS];
  rows.forEach((row, rowIndex) => {
    const rowData: string[] = [];
    // this is actually selecting the id of the transaction, which happens to be in the className...
    const transactionId = row.className.split('-')[1] ?? '';
    row.querySelectorAll('th').forEach((cell) => {
      rowData.push(`"${cell.textContent?.replace(/"/g, '""')}"`);
    });
    row.querySelectorAll('td').forEach((cell) => {
      rowData.push(`"${cell.textContent?.replace(/"/g, '""')}"`);
    });

    // add extra content
    if (rowIndex === 0) {
      // If it's the header row push our extra headers
      // add payout id
      rowData.push('"Payout ID"');
      // add worker id
      rowData.push('"Worker ID"');
    } else if (!INFO_ROW.includes(rowIndex) && transactionsMap.has(transactionId)) {
      // if its not one of the header or footer rows we need to add the extra data
      if (transactionsMap.get(transactionId)?.metadata) {
        // add payout id
        rowData.push(`"${transactionsMap.get(transactionId)?.metadata?.payoutId}"`);
        // add worker id
        rowData.push(`"${transactionsMap.get(transactionId)?.metadata?.profile}"`);
      } else {
        rowData.push('""');
        rowData.push('""');
      }
    }
    csvContent += rowData.join(',') + (rowIndex < rows.length - 1 ? '\n' : '');
  });
  fileDownload(csvContent, `statement_${statementId}.csv`);
}

export const useDownloadStatementCsv = () => {
  return useMutation({
    mutationFn: generateStatementCsv,
  });
};

/**
 * Content of the modal that opens when you request to make a Worker
 */
import { useCreateOneWorker } from '@/services/useWorkers';
import { ReactComponent as ErrorGraphic } from '@/shared/assets/error-graphic.svg';
import { ReactComponent as SuccessGraphic } from '@/shared/assets/success-graphic.svg';
import { Legalese } from '@/shared/components/BatchUpload.styled';
import { ResultsRow } from '@/shared/components/CreationComponents';
import { Worker } from '@/shared/types';
import { Button, Check } from '@checkrx/pay-component-library';
import { AxiosError, isAxiosError } from 'axios';
import { useState } from 'react';

import {
  ButtonRow,
  CenteredErrorText,
  CenteredSuccessText,
  CheckRow,
  ContentWrapper,
  DescriptionText,
  ImgWrapper,
  LabelRow,
  LabelText,
  ValueText,
} from '@/shared/components/ConfirmationModals.styled';

const successToMessage = (worker: Worker) => {
  return (
    <CenteredSuccessText>
      Successfully created new pending worker with ID {worker.id}
    </CenteredSuccessText>
  );
};
const errorToMessage = (err: Error) => {
  if (isAxiosError(err)) {
    const {
      data: { error_message: errorMessage, details: errorDetails },
    } = err?.response || { data: {} };

    let details = errorDetails;
    if (details?.error) {
      details = details?.error?.details || [];
    }

    return (
      <>
        <CenteredErrorText key="error">{`${errorMessage}`}</CenteredErrorText>
        {(details || []).map(({ message, type }: { message: string; type: string }) => (
          <CenteredErrorText key={type}>{message}</CenteredErrorText>
        ))}
      </>
    );
  } else {
    return <CenteredErrorText>{`${err.message}`}</CenteredErrorText>;
  }
};

interface WorkerConfirmationModalProps {
  candidateId?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phoneNumber: string;
  // Function to Clear out the Parents' state on success
  clearParentFn: () => void;
  metadata?: string;
}
export default function WorkerConfirmationModal({
  candidateId,
  firstName,
  lastName,
  email,
  phoneNumber,
  clearParentFn,
  metadata,
}: WorkerConfirmationModalProps) {
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const {
    isLoading: createOneIsLoading,
    isError: createOneIsError,
    isSuccess: createOneIsSuccess,
    error: createOneError,
    data: createOneData,
    mutate: createOneWorker,
  } = useCreateOneWorker();

  const onSubmitOneWorker = async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // Create the worker
    createOneWorker({
      candidateId: candidateId,
      firstName,
      lastName,
      email,
      phoneNumber,
      metadata: metadata ? metadata : undefined,
    });

    // Sleep for a second before clearing the parent's state to avoid weird UX
    await new Promise((r) => setTimeout(r, 1000));

    // Clear the parents' state
    clearParentFn();
  };

  // Errors should show a polite message with our error graphic
  if (createOneIsError) {
    return (
      // We know one of these is non-null if we hit the above conditional
      <ImgWrapper>
        <ErrorGraphic />
        <ResultsRow>
          {errorToMessage(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (createOneError as AxiosError)!
          )}
        </ResultsRow>
      </ImgWrapper>
    );
  }

  // Success should show a hooray graphic with a message about the completion
  if (createOneIsSuccess) {
    return (
      <ImgWrapper>
        <SuccessGraphic />
        <ResultsRow>{successToMessage(createOneData)}</ResultsRow>{' '}
      </ImgWrapper>
    );
  }

  return (
    // Otherwise we're showing the data and asking for confirmation
    <ContentWrapper>
      <DescriptionText>
        Please double check worker information below, then confirm that you wish to proceed. If
        something looks incorrect, you can close this modal to make edits. After you confirm and
        submit, we will attempt to create a worker with this information.
      </DescriptionText>
      <LabelRow>
        <LabelText>Email:</LabelText>
        <ValueText>{email}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Phone Number:</LabelText>
        <ValueText>{phoneNumber}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Checkr Candidate Id:</LabelText>
        <ValueText>{candidateId || ''}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>First Name:</LabelText>
        <ValueText>{firstName}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Last Name:</LabelText>
        <ValueText>{lastName}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Worker Metadata:</LabelText>
        <ValueText>{metadata || ''}</ValueText>
      </LabelRow>
      <CheckRow>
        <Check
          checked={hasConfirmed}
          onClick={() => {
            setHasConfirmed(!hasConfirmed);
          }}
        />
        <Legalese>
          I&apos;ve confirmed the information above and authorize Checkr Pay to create this worker.
        </Legalese>
      </CheckRow>
      <ButtonRow>
        <Button
          text="Submit"
          sizeVariant="big"
          colorVariant="brand"
          disabled={!hasConfirmed}
          loading={createOneIsLoading}
          width="155px"
          onClick={onSubmitOneWorker}
        />
      </ButtonRow>
    </ContentWrapper>
  );
}

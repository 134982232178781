import { FC } from 'react';
import { WorkerWithEarnings } from './taxes.types';
import { taxesStatusRenderMap, TAX_YEARS } from './utils';

type Props = {
  worker: WorkerWithEarnings;
};

export const TaxStatus: FC<Props> = ({ worker }) => {
  const status = worker['1099nec']?.[TAX_YEARS[0]]?.status;
  const getStatus = () => {
    if (worker.totalEarnings < 600_00) {
      return {
        status: taxesStatusRenderMap['INELIGIBLE'],
        reason: 'Total earnings are less than $600',
      };
    }
    if (worker?.aboundTinVerification?.tinVerificationStatus !== 'MATCH') {
      return {
        status: taxesStatusRenderMap['NEEDS_ATTENTION'],
        reason: 'TIN verification mismatch (Contact support)',
      };
    }
    if (worker['1099nec']?.[TAX_YEARS[0]]?.manuallyExcluded) {
      return {
        status: taxesStatusRenderMap['MANUALLY_EXCLUDED'],
      };
    }
    return { status: taxesStatusRenderMap[status ?? 'NOT_STARTED'] };
  };

  const currentStatus = getStatus();

  return (
    <div className="flex flex-col gap-1">
      <div className="text-sm font-medium">{currentStatus.status}</div>
      {currentStatus?.reason && <div className="text-xs text-gray-500">{currentStatus.reason}</div>}
    </div>
  );
};


/**
 * An Error page that force-clears all state and redirects you to login
 */
import { useEffect } from 'react';

import { useAuth } from '@/services/useAuth';

import error from '@/shared/assets/error-graphic.svg';
import StandaloneSidebar from '@/shared/components/StandaloneSidebar';
import { Button } from '@checkrx/pay-component-library';
import { useNavigate } from 'react-router-dom';

import { ErrorContentBox, ErrorTitle, Graphic, Text } from './ErrorPage.styled';
import { ErrorPageProps } from './ErrorPage.types';

function HardResetErrorPage({ resetErrorBoundary }: ErrorPageProps) {
  const { logout } = useAuth();
  const navigate = useNavigate();

  // Use an effect to reset client-side state (AKA Auth token) and redirect to login!
  useEffect(() => {
    // First clear the error boundary
    if (resetErrorBoundary) {
      resetErrorBoundary();
    }
    // Second reset client-side state
    logout();
    // The above will automatically cause a re-render (which for auth-guarded
    // pages will cause a redirect), but we actually want to manually redirect
    // to 'login'. Otherwise non-AuthGuarded pages (EG /authenticate) would try
    // to rerender and just get another 401.
    navigate('/login');
  }, [logout, resetErrorBoundary, navigate]);

  return (
    <>
      <StandaloneSidebar />
      <ErrorContentBox>
        <Graphic src={error} />
        <ErrorTitle>Uh oh! You&apos;ve been logged out!</ErrorTitle>
        <Text>You will be automatically redirected to log back in.</Text>
        <Button
          className="error-page-refresh-btn"
          width="200px"
          sizeVariant="small"
          colorVariant="dark"
          //   leftIcon={<RefreshIcon />}
          text="Back to Safety"
          disabled={true}
        />
      </ErrorContentBox>
    </>
  );
}

export default HardResetErrorPage;

/**
 * Layout for the Dashboard for Admins to do things.
 */
import Sidebar from '@/shared/components/Sidebar/Sidebar';
import { SidebarItemT } from '@/shared/components/Sidebar/SidebarItem';
import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';

const TabWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export default function AdminDashboardLayout() {
  if (process.env.NODE_ENV !== 'development') {
    return <Navigate to="/customer/payouts" />;
  }

  const adminSidebarItems: SidebarItemT[] = [
    {
      text: 'Overview',
      to: '/admin',
      icon: 'home',
    },
    {
      text: 'Button samples',
      to: '/admin/buttons',
      icon: 'square',
    },
    {
      text: 'Create Customer',
      to: '/admin/customer',
      icon: 'user-plus',
    },
  ];
  return (
    <>
      <Sidebar items={adminSidebarItems} />
      {/* <AdminSidebar /> */}
      <TabWrapper>
        <Outlet />
      </TabWrapper>
    </>
  );
}

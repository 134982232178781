/**
 * Just a placeholder view with some button component testing
 */
import { useAuth } from '@/services/useAuth';
import { usePingQuery } from '@/services/usePingQuery';
import DateRangePicker from '@/shared/components/DateRangePicker';
import { Button, Check, Spinner, Table } from '@checkrx/pay-component-library';
import React, { useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

// Override the default TabWrapper with something specific
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 20px;
  justify-content: start;
  gap: 15px;
`;

export default function AdminButtonTab() {
  const [count, setCount] = useState(0);
  const { token, login } = useAuth();
  const { data: ping } = usePingQuery();

  const { showBoundary } = useErrorBoundary();
  return (
    <Wrapper>
      <div>
        <DateRangePicker
          onChange={() => {
            // console.log(p);
          }}
        />
      </div>
      <Button
        width="128px"
        text="Throw a runtime Error"
        onClick={() => {
          showBoundary(new Error('Uh Oh error!'));
        }}
      />
      <Button width="128px" text="EDIT" disabled />
      <Button
        width="240px"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          setCount(count + 1);
          if (login) {
            login(`${count + 1}`);
          }
        }}
      />
      <Button
        width="240px"
        text="Click to Ping Server"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
        }}
      />
      <Spinner />
      <p>Count: {count}</p>
      <p>Token: {token}</p>
      <pre>{JSON.stringify(ping, null, 2)}</pre>
      <Table
        title="Example Table"
        data={[
          {
            id: '1',
            name: 'John Doe',
            email: 'an.arbitrarily.long.email@example.com',
            age: 25,
            action: <Button text="John Doe" colorVariant="dark" />,
          },
          {
            id: '2',
            name: 'Jane Doe',
            email: 'jane.doe@example.com',
            age: 30,
            action: <Button text="Jane Doe" colorVariant="dark" />,
          },
          {
            id: '3',
            name: 'Bob Smith',
            email: 'bob.smith@example.com',
            age: 40,
            action: <Button text="Bob Smith" colorVariant="dark" />,
          },
        ]}
        columns={[
          { title: 'ID', field: 'id', flex: 1 },
          { title: 'Name', field: 'name', flex: 2 },
          { title: 'Email', field: 'email', flex: 3 },
          { title: 'Age', field: 'age', flex: 1 },
          { title: 'Action', field: 'action', flex: 3, isText: false },
        ]}
        width="600px"
      />
      <Check checked={true} />
    </Wrapper>
  );
}

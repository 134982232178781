import { toast } from '@/app/wrappers/Toaster/Toaster';
import { Button } from '@/components/ui/button';
import { DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { useUpdateTaxDetails } from '@/services/useWorkerTaxes';
import DollarAmount from '@/shared/components/DollarAmount';
import { useState } from 'react';
import { WorkerWithEarnings } from '../taxes.types';
import { TAX_YEARS } from '../utils';

type Props = {
  worker: WorkerWithEarnings;
  onSuccess?: () => void;
  onClose?: () => void;
};

export const OverrideEarningsIndividual = ({ worker, onSuccess, onClose }: Props) => {
  const [overrideEarnings, setOverrideEarnings] = useState<number>(0);
  const updateTaxDetails = useUpdateTaxDetails();

  const handleClose = () => {
    setOverrideEarnings(0);
    onClose?.();
  };

  const handleSave = () => {
    updateTaxDetails.mutate(
      {
        workerProfileId: worker.id,
        year: TAX_YEARS[0],
        overrideTotalEarningsAmount: overrideEarnings,
      },
      {
        onSuccess: () => {
          toast({
            message: 'Successfully saved override earnings',
            type: 'success',
            duration: 5000,
          });
          setOverrideEarnings(0);
          onSuccess?.();
        },
      }
    );
  };

  return (
    <DialogContent className="max-w-2xl">
      <DialogHeader>
        <DialogTitle>Override TotalEarnings</DialogTitle>
        <p className="text-sm text-muted-foreground">
          Override total earnings for{' '}
          {worker?.workerBusinessInformation?.businessName ||
            worker.profile?.legalName ||
            worker.profile?.email}
          .
        </p>
      </DialogHeader>

      <div className="py-4 space-y-6">
        <div className="rounded-md border p-4 space-y-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-medium">
                {worker.workerBusinessInformation?.businessName ||
                  worker.profile?.legalName ||
                  worker.profile?.email}
              </p>
              <p className="text-sm text-gray-500">
                <DollarAmount amountCents={worker.totalEarnings} /> current earnings
              </p>
            </div>
          </div>

          <div className="space-y-2">
            <span className="text-sm font-medium">Override earnings amount</span>
            <Input
              className="w-[280px]"
              placeholder="Override earnings"
              value={
                overrideEarnings
                  ? Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format((overrideEarnings || 0) / 100)
                  : undefined
              }
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, '');
                if (value === '') return;
                if (value.includes('$')) {
                  setOverrideEarnings(
                    parseInt(value.slice(1).replaceAll(',', '').split('.').join(''))
                  );
                } else {
                  setOverrideEarnings(parseInt(value.replaceAll(',', '')));
                }
              }}
            />
          </div>

          <div className="flex justify-between text-sm">
            <span className="font-medium">New total earnings:</span>
            <DollarAmount amountCents={overrideEarnings} />
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <Button variant="outline" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={updateTaxDetails.isLoading}>
          {updateTaxDetails.isLoading ? 'Saving...' : 'Save Override Earnings'}
        </Button>
      </div>
    </DialogContent>
  );
};


import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

interface TetherProps {
  children: React.ReactNode;
  target: React.RefObject<HTMLElement>;
  align?: 'top' | 'bottom' | 'left' | 'right';
  offset?: { vertical?: number; horizontal?: number };
  show: boolean;
}

const Tether: React.FC<TetherProps> = ({
  children,
  target,
  align = 'bottom',
  offset = { vertical: 0, horizontal: 0 },
  show = false,
}) => {
  const [portalContainer] = useState(() => document.createElement('div'));
  const tetherRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.appendChild(portalContainer);
    return () => {
      document.body.removeChild(portalContainer);
    };
  }, [portalContainer]);

  useEffect(() => {
    if (!show) return;
    const handleScroll = () => {
      if (target.current && tetherRef.current) {
        const targetRect = target.current.getBoundingClientRect();
        const tetherRect = tetherRef.current.getBoundingClientRect();

        switch (align) {
          case 'top':
            tetherRef.current.style.top = `${
              targetRect.top - tetherRect.height - (offset.vertical ?? 0)
            }px`;
            tetherRef.current.style.left = `${targetRect.left + (offset.horizontal ?? 0)}px`;
            break;
          case 'bottom':
            tetherRef.current.style.top = `${targetRect.bottom + (offset.vertical ?? 0)}px`;
            tetherRef.current.style.left = `${targetRect.left + (offset.horizontal ?? 0)}px`;
            break;
          case 'left':
            tetherRef.current.style.top = `${targetRect.top + (offset.vertical ?? 0)}px`;
            tetherRef.current.style.left = `${
              targetRect.left - tetherRect.width - (offset.horizontal ?? 0)
            }px`;
            break;
          case 'right':
            tetherRef.current.style.top = `${targetRect.top + (offset.vertical ?? 0)}px`;
            tetherRef.current.style.left = `${targetRect.right + (offset.horizontal ?? 0)}px`;
            break;
          default:
            break;
        }
      }
    };

    handleScroll(); // Initial position
    window.addEventListener('scroll', handleScroll, true);
    window.addEventListener('resize', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
      window.removeEventListener('resize', handleScroll, true);
    };
  }, [align, target, offset.vertical, offset.horizontal, show]);

  if (!show) return null;
  return ReactDOM.createPortal(
    <div ref={tetherRef} style={{ position: 'absolute', zIndex: 1050 }}>
      {children}
    </div>,
    portalContainer
  );
};

export default Tether;

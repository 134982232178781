/**
 * A Query that allows us to fetch and search a customer account from the backend
 */
import { ExternalAccount } from '@/shared/types';
import { useQuery } from '@tanstack/react-query';
import serverApi from './serverApi';

async function fetchExternalAccount() {
  const response = await serverApi.get('/dashboard/v0/finance/external-account');
  return response.data.externalAccount;
}

export const useExternalAccount = <TData = ExternalAccount>(
  select?: (data: ExternalAccount) => TData
) => {
  return useQuery({
    queryKey: ['customer', 'customerProfile', 'externalAccount'],
    queryFn: () => fetchExternalAccount(),
    select,
  });
};

// Select just the Routing and Account Numbers
const selectRoutingNumber = (account: ExternalAccount): string => account.routingNumber;
export const useExternalAccountRoutingNumber = () => useExternalAccount(selectRoutingNumber);

const selectAccountNumber = (account: ExternalAccount): string => account.accountNumber;
export const useExternalAccountNumber = () => useExternalAccount(selectAccountNumber);

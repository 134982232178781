export enum TabPermissions {
  none = 'NONE',
  read = 'READ',
  write = 'WRITE',
}

export interface Permissions {
  // Permissions to view and edit User accounts
  usersTab?: TabPermissions;
  // Permissions to view and edit API Config
  apiTab?: TabPermissions;
  // Permissions to view and edit workers taxes
  taxTab?: TabPermissions;
  // Permissions to view and edit workers
  settingsTab?: TabPermissions;
  // Permissions to view and edit workers
  workersTab?: TabPermissions;
  // Permissions to view and edit payouts
  payoutsTab?: TabPermissions;
  // Permissions to reverse a payout
  executeRepayments?: TabPermissions;
  // Permissions to view and edit Financial info
  financeTab?: TabPermissions;
  // Special permissions for reading account numbers
  sensitiveAccountData?: TabPermissions;
}

import { colors } from '@/shared/styles';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Toast, ToastT } from './toaster-types';

const ToastContainer = styled.div<{ type?: ToastT; duration: number }>`
  width: 400px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10000;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  animation: slide-down ${(p) => p.duration}ms ease-in;
  opacity: 0;
  pointer-events: auto;

  @keyframes slide-down {
    0% {
      transform: translateY(-48px);
      opacity: 0;
    }
    10%,
    90% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
      transform: translateY(-4px);
    }
  }

  color: ${colors.trueWhite};
  color: ${(p) => {
    if (p.type === 'success') return colors.trueWhite;
    if (p.type === 'error') return colors.trueWhite;
    if (p.type === 'info') return colors.labelGrey;
  }};
  background: ${(p) => {
    if (p.type === 'success') return colors.accentGreen;
    if (p.type === 'error') return colors.accentRed;
    if (p.type === 'info') return colors.trueWhite;
  }};
`;

const OPEN_TOAST = 'OPEN_TOAST';
declare global {
  interface WindowEventMap {
    OPEN_TOAST: CustomEvent;
  }
}

export const toast = (toast: Omit<Toast, 'id'>) => {
  window.dispatchEvent(new CustomEvent(OPEN_TOAST, { detail: toast }));
};

export const Toaster = () => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  useEffect(() => {
    const handleToast = ({ detail: toast }: { detail: Toast }) => {
      const generatedId = Date.now();
      const completedToast: Toast = { ...toast, id: generatedId };
      const toastsCopy = [completedToast, ...toasts];
      setToasts(toastsCopy);
      setTimeout(() => {
        setToasts((curr) => curr.filter((t) => t.id !== completedToast.id));
      }, completedToast.duration);
    };
    window.addEventListener(OPEN_TOAST, handleToast);

    return () => window.removeEventListener(OPEN_TOAST, handleToast);
  }, [toasts]);

  return (
    <>
      {toasts.map((toast: Toast) => (
        <ToastContainer key={toast.id} type={toast.type} duration={toast.duration}>
          {toast.message}
        </ToastContainer>
      ))}
    </>
  );
};

import { Card } from '@/components/ui/card';
import List from '@/shared/components/List/List';
import { Icon } from '@checkrx/pay-component-library';
import { ReactNode, useState } from 'react';
import { taxWizardColorMap } from './utils';

type Section = {
  title: string;
  count: number;
  accentColor?: string;
  items: Array<{
    id: string;
    label: string;
    description?: ReactNode;
  }>;
  emptyMessage?: string;
};

type Props = {
  sections: Section[];
  defaultExpanded?: boolean;
  defaultExpandedSection?: string;
  onSectionClick?: (section: Section) => void;
};

export const TaxTotals = ({
  sections,
  defaultExpanded = true,
  defaultExpandedSection,
  onSectionClick,
}: Props) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [expandedSection, setExpandedSection] = useState<string>(
    defaultExpandedSection ?? sections[0]?.title ?? ''
  );

  const selectedSection = sections.find((section) => section.title === expandedSection);

  const listSections = [
    [
      {
        id: 'label',
        width: 504,
        content: (item: { label: string; description?: ReactNode }) => (
          <div className="flex gap-1">
            {item.label}
            {item.description && (
              <span className="text-muted-foreground flex gap-1"> - {item.description}</span>
            )}
          </div>
        ),
      },
    ],
  ];

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="flex flex-wrap gap-4">
          {sections.map((section, index) => (
            <button
              key={index}
              onClick={() => {
                setExpandedSection(section.title);
                onSectionClick?.(section);
              }}
              className="flex-1 min-w-[200px] text-left hover:bg-gray-50 p-2 rounded-lg transition-colors"
            >
              <h3 className="font-medium">{section.title}</h3>
              <p
                className="text-2xl font-bold"
                style={{
                  color: section.accentColor || taxWizardColorMap.default,
                }}
              >
                {section.count}
              </p>
            </button>
          ))}
        </div>

        <div className="border-t pt-4">
          <div>
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => setExpanded((curr) => !curr)}
            >
              <Icon name={expanded ? 'chevron-up' : 'chevron-down'} />
              <h3 className="font-medium">
                <span style={{ color: selectedSection?.accentColor || taxWizardColorMap.default }}>
                  {selectedSection?.items.length}
                </span>{' '}
                {selectedSection?.title}
              </h3>
            </div>
            {expanded && (
              <div className="h-[15vh] mt-2">
                <List
                  items={selectedSection?.items ?? []}
                  sections={listSections}
                  emptyStateMessage={selectedSection?.emptyMessage || 'No items found'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};


export enum CustomerView {
  overview = 'overview',
  payouts = 'payouts',
  workers = 'workers',
  finance = 'finance',
  taxes = 'taxes',
  api = 'api',
  users = 'users',
  settings = 'settings',
  logout = 'logout',
}

// IDs of the various loading guards
// Having this types out forces the caller to specify the level at which
// they want to show a loading screen
export enum LoadingScope {
  global = 'global',
  authentication = 'authentication',
  login = 'login',
  customerDashboard = 'customerDashboard',
  batchUpload = 'batchUpload',
}

export type DateString = string;

export type WorkerType = 'business' | 'individual';
export type WorkerBusinessInformation = {
  businessName: string;
  formationDate: DateString;
  ein: string;
  entityType:
    | 'sole_proprietorship'
    | 'partnership'
    | 'corporation'
    | 's_corporation'
    | 'llc'
    | 'nonprofit'
    | 'cooperative';
};

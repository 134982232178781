import { colors } from '@/shared/styles';
import { Icon } from '@checkrx/pay-component-library';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

export type DropdownOption = {
  label: string;
  value: string;
};

type CoreDropdownProps = {
  options: DropdownOption[];
  onChange: (selectedOption: DropdownOption) => void;
  placeholder?: string;
};

const DropdownContainer = styled.div`
  position: relative;
  width: 240px;
`;

const DropdownButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: ${colors.trueWhite};
  color: #48525e;
  border-bottom: 1px solid #8793a1;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
`;

const HiddenSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const CoreDropdown: React.FC<CoreDropdownProps> = ({
  options,
  onChange,
  placeholder = 'Select an option',
}) => {
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  const handleButtonClick = () => {
    if (selectRef.current) {
      selectRef.current.focus();
      selectRef.current.click();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const option = options.find((opt) => opt.value === selectedValue);
    if (option) {
      setSelectedOption(option);
      onChange(option);
    }
  };

  return (
    <DropdownContainer>
      <DropdownButton onClick={handleButtonClick}>
        <div>{selectedOption ? selectedOption.label : placeholder}</div>
        <Icon name="chevron-down" color="primary" />
      </DropdownButton>
      <HiddenSelect ref={selectRef} onChange={handleChange} value={selectedOption?.value || ''}>
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </HiddenSelect>
    </DropdownContainer>
  );
};

export default CoreDropdown;

import { useBankingAccountBalance } from '@/services/useBankingAccount';
import { useBatchFileCreate, getBatchUploadErrorMessage } from '@/services/useBatchFiles';
import { useCustomerPayoutGroupDefinitions } from '@/services/useCustomerProfile';
import { ReactComponent as ErrorGraphic } from '@/shared/assets/error-graphic.svg';
import { ReactComponent as SuccessGraphic } from '@/shared/assets/success-graphic.svg';
import {
  BatchUploadBoldSpan,
  BatchUploadErrorMessage,
  BatchUploadFileInput,
  BatchUploadSuccessMessage,
  BatchUploadWarning,
  ButtonRow,
  ConfirmationRow,
  HelpText,
  Label,
  ModalWrapper,
  UploadContainer,
} from '@/shared/components/BatchUpload.styled';
import DatePicker from '@/shared/components/DatePicker';
import useLoading from '@/shared/hooks/useLoading';
import {
  BatchFileType,
  BatchFileUploadType,
  LoadingScope,
  PayoutBatchData,
  Taxes1099BatchData,
  WorkerBatchData,
} from '@/shared/types';
import { Button, Check, Spinner } from '@checkrx/pay-component-library';
import dayjs from 'dayjs';
import Papa, { ParseResult } from 'papaparse';
import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../styles';
import {
  getErrorsFromCSVHeaders,
  getErrorsFromPayoutCSVData,
  getErrorsFromTaxesCSVData,
  getErrorsFromWorkerCSVData,
} from '../validation';
import DollarAmount from './DollarAmount';

const BatchUploadErrorList = styled(BatchUploadErrorMessage)`
  text-align: start;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.875rem;

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
`;

const SupportLink = styled.a`
  color: ${colors.accentTeal};
  font-size: 600;
  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: ${colors.accentTeal};
  }
`;

const BatchUploadErrorsContainer = styled.div`
  max-height: 20vh;
  overflow: auto;
`;

export default function BatchFileUpload({ type: batchFileType }: BatchFileUploadType) {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [csvSummary, setCsvSummary] = useState<ReactNode>(<></>);
  const [confirmedUpload, setConfirmedUpload] = useState(false);
  const [csvErrors, setCsvErrors] = useState<Array<string>>([]);
  const [showErrorOverride, setShowErrorOverride] = useState(false);
  const [runAt, setRunAt] = useState(dayjs().startOf('minute').toDate());

  const { data: payoutGroupDefinitions, isLoading: isPayoutGroupDefinitionsLoading } =
    useCustomerPayoutGroupDefinitions();

  const { data: balance, isLoading: isBalanceLoading } = useBankingAccountBalance();

  const {
    error: batchUploadError,
    isLoading: batchCreateIsLoading,
    isError: batchCreateIsError,
    isSuccess: batchCreateIsSuccess,
    mutate: uploadBatchFile,
  } = useBatchFileCreate();

  const setLoading = useLoading(
    batchCreateIsLoading || isPayoutGroupDefinitionsLoading || isBalanceLoading,
    LoadingScope.batchUpload
  );

  useEffect(() => {
    setLoading(batchCreateIsLoading);
  }, [batchCreateIsLoading, setLoading]);

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    // First clear state based on any previous file uploads
    setCsvErrors([]);
    setShowErrorOverride(false);
    setCsvSummary(<></>);
    setConfirmedUpload(false);
    setIsFilePicked(false);

    const selectedFile = event.target.files?.[0];
    if (!selectedFile) {
      return;
    }

    // Parse and process our files on the front end.
    // TODO(Carter - v2) – Display a mini-table of the workers/payouts about to be made
    switch (batchFileType) {
      case BatchFileType.Payout: {
        Papa.parse(selectedFile, {
          header: true,
          skipEmptyLines: true,
          transform: (value, col) => {
            if (col === 'amountCents') {
              return Number(value);
            }

            return value === '' ? undefined : value;
          },
          complete: function (results: ParseResult<PayoutBatchData>) {
            // TODO(Carter): Move this to util Functions
            const { data, errors, meta } = results;

            // Check For Errors parsing!
            if (errors && errors.length > 0) {
              setCsvErrors([
                'The format of your batch file is invalid. Please upload a valid CSV file.',
              ]);
              return;
            }

            const headers = meta.fields || [];
            const validDynamicHeaders =
              payoutGroupDefinitions?.map((definition) => definition.name) || [];

            const headerErrors = getErrorsFromCSVHeaders(
              BatchFileType.Payout,
              headers,
              validDynamicHeaders
            );
            if (headerErrors.length > 0) {
              setCsvErrors(headerErrors);
              return;
            }

            const dataErrors = getErrorsFromPayoutCSVData(data, payoutGroupDefinitions || []);
            // For data errors, we allow them to continue if they want!
            if (dataErrors.length > 0) {
              setCsvErrors(dataErrors);
              setShowErrorOverride(true);
            }

            const count = data.length;
            const totalCents = data.reduce((total, next) => {
              return total + (next?.amountCents || 0);
            }, 0);
            setCsvSummary(
              <BatchUploadWarning>
                <BatchUploadBoldSpan>WARNING: </BatchUploadBoldSpan>
                Clicking Upload will permanently create{' '}
                <BatchUploadBoldSpan>{count}</BatchUploadBoldSpan> payouts.
                <br />
                These payouts will have a total value of{' '}
                <DollarAmount amountCents={totalCents} dollarSize="1rem" centSize=".875rem" />. Your
                current account balance is{' '}
                <DollarAmount amountCents={balance ?? 0} dollarSize="1rem" centSize=".875rem" />.
                <br />
                This action is typically irreversible. If this looks incorrect, please double-check
                your batch upload file, or contact Checkr Pay Support.
              </BatchUploadWarning>
            );
            // Finish here by setting the files on success
            setSelectedFile(selectedFile);
            setIsFilePicked(true);
          },
        });
        return;
      }

      case BatchFileType.Worker: {
        Papa.parse(selectedFile, {
          header: true,
          skipEmptyLines: true,
          transform: (value) => (value === '' ? undefined : value),
          complete: function (results: ParseResult<WorkerBatchData>) {
            const { data, meta, errors } = results;

            // Check For Errors parsing!
            if (errors && errors.length > 0) {
              setCsvErrors([
                'The format of your batch file is invalid. Please upload a valid CSV file.',
              ]);
              return;
            }

            // Validate header
            const headers = meta.fields || [];
            const headerErrors = getErrorsFromCSVHeaders(BatchFileType.Worker, headers);
            if (headerErrors.length > 0) {
              setCsvErrors(headerErrors);
              return;
            }

            // Validate data
            const dataErrors = getErrorsFromWorkerCSVData(data);
            if (dataErrors.length > 0) {
              setCsvErrors(dataErrors);
              return;
            }

            const count = data.length;
            setCsvSummary(
              <BatchUploadWarning>
                <BatchUploadBoldSpan>WARNING: </BatchUploadBoldSpan>
                Clicking Upload will permanently create{' '}
                <BatchUploadBoldSpan>{count}</BatchUploadBoldSpan> workers. This action is typically
                irreversible. If this looks incorrect, please double-check your batch upload file,
                or contact Checkr Pay Support.
              </BatchUploadWarning>
            );

            // Finish here by setting the files on success
            setSelectedFile(selectedFile);
            setIsFilePicked(true);
          },
        });
        return;
      }
      case BatchFileType.Taxes1099Create: {
        Papa.parse(selectedFile, {
          header: true,
          skipEmptyLines: true,
          transform: (value) => (value === '' ? undefined : value),
          complete: function (results: ParseResult<Taxes1099BatchData>) {
            const { data, meta, errors } = results;

            // Check For Errors parsing!
            if (errors && errors.length > 0) {
              setCsvErrors([
                'The format of your batch file is invalid. Please upload a valid CSV file.',
              ]);
              return;
            }

            // Validate header
            const headers = meta.fields || [];
            const headerErrors = getErrorsFromCSVHeaders(BatchFileType.Taxes1099Create, headers);
            if (headerErrors.length > 0) {
              setCsvErrors(headerErrors);
              return;
            }

            // Validate data
            const dataErrors = getErrorsFromTaxesCSVData(data);
            if (dataErrors.length > 0) {
              setCsvErrors(dataErrors);
              return;
            }

            const count = data.length;
            setCsvSummary(
              <BatchUploadWarning>
                <BatchUploadBoldSpan>WARNING: </BatchUploadBoldSpan>
                Clicking Upload will generate up to{' '}
                <BatchUploadBoldSpan>{count}</BatchUploadBoldSpan> 1099-necs for your workers.{' '}
                Following this, you can double-check the PDFs of the 1099s and file via the table
                below, or ask Checkr Pay support to help you file all generated tax forms at once.
              </BatchUploadWarning>
            );

            // Finish here by setting the files on success
            setSelectedFile(selectedFile);
            setIsFilePicked(true);
          },
        });
        return;
      }
      default:
        throw new Error(`Encountered CSV upload of unknown type ${batchFileType}`);
    }
  };

  const handleFileSubmission = async () => {
    if (isFilePicked && selectedFile) {
      uploadBatchFile({
        file: selectedFile,
        type: batchFileType,
        runAt,
      });
    }
  };

  const renderBatchSuccessMessage = () => {
    return (
      <ModalWrapper style={{ justifyContent: 'center', alignItems: 'center' }}>
        <SuccessGraphic />
        <BatchUploadSuccessMessage>
          Success! Your file has been submitted for processing.
          <br />
          Please refresh the page to view your batch file status.
        </BatchUploadSuccessMessage>
      </ModalWrapper>
    );
  };

  const renderBatchErrorMessage = () => {
    if (batchUploadError) {
      const errorDetail = getBatchUploadErrorMessage(batchUploadError)
      return (
        <ModalWrapper style={{ justifyContent: 'center', alignItems: 'center' }}>
          <ErrorGraphic />
          <BatchUploadErrorMessage>{errorDetail}</BatchUploadErrorMessage>
        </ModalWrapper>
      );
    }
  };

  const renderUploadButton = () => {
    if (batchCreateIsSuccess) {
      return renderBatchSuccessMessage();
    }

    if (batchCreateIsError) {
      return renderBatchErrorMessage();
    }

    return (
      <ModalWrapper>
        <div>
          <Label htmlFor="batchFile" required>
            File
          </Label>
          <BatchUploadFileInput
            type="file"
            id="batchFile"
            name="batch-file"
            onChange={selectFile}
          />
        </div>

        {batchFileType === BatchFileType.Payout ? (
          <div>
            <Label htmlFor="runAt" required>
              Scheduled Processing Date
            </Label>
            <DatePicker
              id="runAt"
              name="runAt"
              ariaDescribedBy="runAtHelpText"
              selected={runAt}
              minDate={new Date()}
              maxDate={dayjs().add(31, 'days').toDate()}
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
              shouldCloseOnSelect={false} // fixes a bug when typing into the time input
              onChange={(data) => {
                if (data) {
                  // dayjs with null creates an invalid date object
                  setRunAt(dayjs(data).startOf('minute').toDate());
                } else {
                  setRunAt(dayjs().startOf('minute').toDate());
                }
              }}
            />
            <HelpText id="runAtHelpText">
              Date and time to process the selected file.
              <br />
              Files can be processed at 5 minute intervals.
            </HelpText>
          </div>
        ) : null}

        {csvSummary}
        {csvErrors && csvErrors.length > 0 && (
          <BatchUploadErrorsContainer>
            {showErrorOverride ? (
              <BatchUploadErrorList>
                It looks like some of the rows in your batch file contain invalid data. Please
                review the errors below. If you would like to continue despite the errors, we can
                process this batch file as-is. The valid rows will complete, with the invalid rows
                listed below failing. If you have any questions about these warnings, or would like
                assistance with your batch file, we&apos;re here to help - please visit our{' '}
                <SupportLink
                  href="https://customersupport.checkrpay.com/hc/en-us"
                  target="_blank"
                  rel="noreferrer"
                >
                  Customer Support Center.
                </SupportLink>
              </BatchUploadErrorList>
            ) : (
              <BatchUploadErrorList>
                It looks like your batch file is invalid. Please review the errors below. If you
                have any questions about these errors, or would like assistance with your batch
                file, we&apos;re here to help - please visit our{' '}
                <SupportLink
                  href="https://customersupport.checkrpay.com/hc/en-us"
                  target="_blank"
                  rel="noreferrer"
                >
                  Customer Support Center.
                </SupportLink>
              </BatchUploadErrorList>
            )}
            <BatchUploadErrorList>
              <ul>
                {csvErrors.map((errString) => (
                  <li key={errString}>{errString}</li>
                ))}
              </ul>
            </BatchUploadErrorList>
          </BatchUploadErrorsContainer>
        )}
        {isFilePicked && (
          <ConfirmationRow>
            <Check
              checked={confirmedUpload}
              onClick={() => {
                setConfirmedUpload(!confirmedUpload);
              }}
            />
            <Label
              inline
              required
              onClick={() => {
                setConfirmedUpload(!confirmedUpload);
              }}
            >
              I understand and authorize Checkr Pay to process this batch file
            </Label>
          </ConfirmationRow>
        )}

        <ButtonRow>
          <Button
            text={
              batchCreateIsLoading ? ((<Spinner size="30px" />) as unknown as string) : 'Upload'
            }
            sizeVariant="big"
            colorVariant="brand"
            onClick={handleFileSubmission}
            width="70%"
            disabled={!confirmedUpload || batchCreateIsLoading}
          />
        </ButtonRow>
      </ModalWrapper>
    );
  };

  return <UploadContainer>{renderUploadButton()}</UploadContainer>;
}

import { colors } from '@/shared/styles';
import Icon, { IconColor, IconName } from '@checkrx/pay-component-library/dist/Icon/Icon';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Tooltip from '../Tooltip/Tooltip';

export type SidebarItemT = {
  text: string;
  icon: IconName;
  iconColor?: IconColor;
  disabled?: boolean;
  showNewBadge?: boolean;
  to?: string;
  onClick?: () => void;
  active?: boolean;
  collapsed?: boolean;
};

const ItemWrapper = styled.div<{ $active?: boolean; disabled: boolean }>`
  height: 32px;
  min-height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  background: ${(p) => (p.$active ? colors.accentTeal : 'transparent')};
  border-radius: 6px;
  padding-left: 12px;
  padding-right: 12px;
  transition: filter 0.2s ease-in-out;

  &:hover {
    filter: ${(p) => (p.$active ? 'brightness(1)' : 'brightness(0.7)')};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 32px;
`;

const StyledText = styled.h2<{ $active?: boolean; disabled: boolean }>`
  font-size: 1rem;
  color: ${(p) => {
    if (p.disabled) return colors.dividerGrey;
    return p?.$active ? colors.trueWhite : colors.labelGrey;
  }};
  font-weight: ${(p) => (p?.$active ? 600 : 400)};
  line-height: 20px;
`;

const SidebarItem: FC<SidebarItemT> = ({
  text,
  to,
  icon,
  iconColor,
  disabled = false,
  active = false,
  onClick,
  collapsed = false,
}) => {
  const content = (
    <>
      <ContentWrapper data-testid={`sidebar-link-${text}`}>
        <Icon name={icon} color={disabled ? 'disabled' : active ? 'white' : iconColor} />
        {!collapsed && (
          <StyledText $active={active} disabled={disabled}>
            {text}
          </StyledText>
        )}
      </ContentWrapper>
    </>
  );

  const wrappedContent =
    collapsed && text ? (
      <Tooltip content={text} align="right" enabled={true}>
        {content}
      </Tooltip>
    ) : (
      content
    );

  if (to) {
    return (
      <ItemWrapper as={Link} to={to} $active={active} disabled={disabled}>
        {wrappedContent}
      </ItemWrapper>
    );
  }

  return (
    <ItemWrapper onClick={disabled ? undefined : onClick} $active={active} disabled={disabled}>
      {wrappedContent}
    </ItemWrapper>
  );
};

export default SidebarItem;


import { useEffect, useRef } from 'react';

export const useDocumentEvent = <K extends keyof DocumentEventMap>(
  eventName: K,
  handler: (e: DocumentEventMap[K]) => void
) => {
  const savedHandler = useRef(handler);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const listener: typeof handler = (e) => {
      savedHandler.current(e);
    };
    document.addEventListener(eventName, listener);
    return () => document.removeEventListener(eventName, listener);
  }, [eventName]);
};

import { ReactComponent as QuestionCircle } from '@/shared/assets/question-circle.svg';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../styles';

const BATCH_FILE_SUPPORT_URL =
  'https://customersupport.checkrpay.com/hc/en-us/articles/16659143247636-Batch-File-Templates-and-FAQ-s-';

const HelpCenterText = styled.div`
  font-size: 1rem;
  color: ${colors.textGrey};
  font-weight: 400;
  line-height: 20px;
`;
const HelpCenterWrapper = styled.a`
  display: flex;
  gap: 12px;
  width: 100%;
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
    transition: 0.2s;
  }
`;

export const BatchBoxAndHelpLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
`;

export const BatchFileHelpLink: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <BatchBoxAndHelpLinkWrapper>
      {children}
      <HelpCenterWrapper href={BATCH_FILE_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
        <QuestionCircle />
        <HelpCenterText>How do batch uploads work?</HelpCenterText>
      </HelpCenterWrapper>
    </BatchBoxAndHelpLinkWrapper>
  );
};

import { Taxes } from './taxes.types';
import { DateString, WorkerBusinessInformation, WorkerType } from './types';

export enum WorkerStatus {
  Onboarded = 'onboarded',
  Pending = 'pending',
  RequirePII = 'require_pii',
  ManualReview = 'manual_review',
  Active = 'active',
  Denied = 'failed',
  Closed = 'closed',
  PendingClosure = 'pending_closure',
}

const WORKER_PAYOUT_METHODS = ['ACHDirectDeposit', 'CheckrPayBankAccount'] as const;

export type WorkerPayoutMethod = (typeof WORKER_PAYOUT_METHODS)[number];

export interface Worker {
  id: string;
  status: WorkerStatus;
  backgroundCheckId?: string;
  metadata?: string;
  createdAt: DateString;
  payoutMethods?: {
    available?: WorkerPayoutMethod[];
    selected?: WorkerPayoutMethod;
  };
  profile?: {
    legalName?: string;
    email?: string;
    phoneNumber?: string;
    address?: {
      street: string;
      street2?: string;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  };
  '1099nec'?: Record<number, Taxes>;
  workerType?: WorkerType;
  workerBusinessInformation?: WorkerBusinessInformation;
  aboundTinVerification?: {
    tinVerificationStatus: 'MATCH' | 'MISMATCH' | 'PENDING';
  };
}


import styled from 'styled-components';
import { colors } from '../styles';

// Styling
export const Column = styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const LogoImg = styled.img`
  height: 24px;
`;

export const Divider = styled.div`
  margin-bottom: 15px;
  background: ${colors.dividerGrey};
  height: 1px;
  width: 100%;
`;

export const SidebarSubtitle = styled.h3`
  font-size: 1rem;
  color: ${colors.textGrey};
  font-weight: 500;
  width: 100%;
  opacity: 0.5;
`;

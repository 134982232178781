/**
 * A Query that allows us to fetch and search payouts from the backend
 *
 * When we query payouts, we can pass in a few filtering params and
 * a search string.
 */
import { useDebouncedMutation } from '@/shared/hooks/useDebouncedMutation';
import { Payout, PayoutGroupDefinitionFilterSelections, PayoutStatus } from '@/shared/types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import serverApi from './serverApi';
dayjs.extend(utc);

// Refetch our payout list every Minute
const REFETCH_AFTER_SECONDS = 60;

async function fetchPayouts({
  startDate,
  endDate,
  workerId,
  email,
  legalName,
}: {
  startDate?: Date;
  endDate?: Date;
  workerId?: string;
  email?: string;
  legalName?: string;
}) {
  // Get Date times between which we'll query. For date selecting, use
  // beginning of the start date, and end of last date (or 'now' if today)
  let start = dayjs.utc(startDate).startOf('day');
  let end = dayjs.utc(endDate).endOf('day');
  // If we're looking at today, make sure it is using the correct time
  if (end.isAfter(dayjs.utc())) {
    end = dayjs.utc();
  }

  // If there's less than 24 hours between start and end date (Due to UTC),
  // Set the start date to 24 hours before the end date.
  if (end.diff(start, 'hour') < 24) {
    start = end.subtract(24, 'hour');
  }

  // TODO(Carter): We have to call the endpoint twice to get both queued and unqueued
  // payouts separately, but we should merge this in the backend in the future.
  const responseQueued = await serverApi.get('/dashboard/v0/payouts', {
    params: {
      startDate: start.toDate(),
      endDate: end.toDate(),
      workerId: workerId && workerId.length > 0 ? workerId : undefined,
      email: email && email.length > 0 ? email : undefined,
      legalName: legalName && legalName.length > 0 ? legalName : undefined,
      queued: true,
    },
  });

  const response = await serverApi.get('/dashboard/v0/payouts', {
    params: {
      startDate: start.toDate(),
      endDate: end.toDate(),
      workerId: workerId && workerId.length > 0 ? workerId : undefined,
      email: email && email.length > 0 ? email : undefined,
      legalName: legalName && legalName.length > 0 ? legalName : undefined,
      queued: false,
    },
  });

  return {
    payouts: [
      // The list of non-queued payouts
      ...(response?.data?.payouts || []),
      // The list of payouts that have not been issued yet. This include on-hold payouts,
      // or failed ones due to reasons like insufficient funds.
      ...(responseQueued?.data?.payouts || []),
    ],
  };
}

// Filter criteria
const statusFilterCriteria = (payout: Payout, selectedStatus?: PayoutStatus | null) => {
  return !selectedStatus || payout.status === selectedStatus;
};

const searchTermCriteria = (payout: Payout, searchString?: string | null) => {
  if (!searchString || searchString.length === 0) {
    return true;
  }

  const searchWords = searchString.toLowerCase().split(' ');
  const serialized = JSON.stringify(payout).toLowerCase();
  return searchWords.some((word) => serialized.includes(word));
};

const payoutGroupDefinitionFilterCriteria = (
  payout: Payout,
  payoutGroupDefinitionFilterSelections?: PayoutGroupDefinitionFilterSelections
) => {
  if (!payoutGroupDefinitionFilterSelections) {
    return true;
  }

  return Object.keys(payoutGroupDefinitionFilterSelections).every((groupName) => {
    const selectedGroupValue = payoutGroupDefinitionFilterSelections[groupName];

    // no filter selected
    if (!selectedGroupValue || selectedGroupValue.length === 0) {
      return true;
    }

    return payout?.groups?.[groupName] === selectedGroupValue;
  });
};

export const usePayouts = ({
  startDate,
  endDate,
  workerId,
  email,
  legalName,
  searchString,
  selectedStatus,
  payoutGroupDefinitionFilterSelections,
}: {
  startDate?: Date;
  endDate?: Date;
  workerId?: string;
  email?: string;
  legalName?: string;
  searchString?: string;
  selectedStatus?: PayoutStatus | null;
  payoutGroupDefinitionFilterSelections?: PayoutGroupDefinitionFilterSelections;
}) => {
  return useQuery({
    queryKey: ['customer', 'payouts', startDate, endDate, workerId, email, legalName],
    queryFn: () =>
      fetchPayouts({
        startDate: startDate ?? undefined,
        endDate: endDate ?? undefined,
        workerId: workerId ?? undefined,
        email: email ?? undefined,
        legalName: legalName ?? undefined,
      }),
    refetchInterval: 1000 * REFETCH_AFTER_SECONDS,
    // Implement semi-fuzzy word search on the front end only.
    select: (data: { payouts: Array<Payout> }): Array<Payout> => {
      const { payouts } = data;

      const filtered = payouts.filter(
        (payout) =>
          statusFilterCriteria(payout, selectedStatus) &&
          searchTermCriteria(payout, searchString) &&
          payoutGroupDefinitionFilterCriteria(payout, payoutGroupDefinitionFilterSelections)
      );

      // Sort payouts in place by creation date (recent first)
      return filtered.sort(
        (a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      );
    },
  });
};

async function postCreateOnePayout(
  workerId: string,
  amountCents: number,
  metadata?: string,
  description?: string
) {
  const response = await serverApi.post('/dashboard/v0/payouts', {
    workerId,
    amountCents,
    metadata,
    description,
  });
  return response.data;
}

export const useCreateOnePayout = (options?: {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const queryClient = useQueryClient();

  return useDebouncedMutation({
    mutationFn: ({
      workerId,
      amount,
      metadata,
      description,
    }: {
      workerId: string;
      amount: number;
      metadata?: string;
      description?: string;
    }) => postCreateOnePayout(workerId, amount, metadata, description),
    onSuccess: () => {
      // Clear all payouts and transactions on success
      queryClient.invalidateQueries({ queryKey: ['customer', 'payouts', 'bankingAccount'] });
      queryClient.invalidateQueries({ queryKey: ['customer', 'transactions'] });
      options?.onSuccess?.();
    },
    onError: (error: unknown) => {
      options?.onError?.(error);
    },
  });
};

const postRepayPayout = async (payoutId: string) => {
  const response = await serverApi.post(`/dashboard/v0/payouts/${payoutId}/repay`);
  return response.data;
};

export const useRepayPayout = () => {
  const queryClient = useQueryClient();

  return useDebouncedMutation({
    mutationFn: ({ payoutId }: { payoutId: string }) => postRepayPayout(payoutId),
    onSuccess: () => {
      // Clear all payouts and transactions on success
      queryClient.invalidateQueries({ queryKey: ['customer', 'payouts', 'bankingAccount'] });
      queryClient.invalidateQueries({ queryKey: ['customer', 'transactions'] });
    },
  });
};

const postCancelPayout = async (payoutId: string) => {
  const response = await serverApi.post(`/dashboard/v0/payouts/${payoutId}/cancel`);
  return response.data;
};

export const useCancelPayout = () => {
  const queryClient = useQueryClient();

  return useDebouncedMutation({
    mutationFn: ({ payoutId }: { payoutId: string }) => postCancelPayout(payoutId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer', 'payouts', 'bankingAccount'] });
      queryClient.invalidateQueries({ queryKey: ['customer', 'transactions'] });
    },
  });
};

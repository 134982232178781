import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { postAuthenticationToken, postLogoutProfile } from './login';

const TOKEN = 'token';

export const useAuth = () => {
  const navigate = useNavigate();
  const defaultTokenValue = '';
  const [token, setToken] = useState(() => {
    try {
      const value = window.localStorage.getItem(TOKEN);
      if (value) {
        return value;
      } else {
        window.localStorage.setItem(TOKEN, defaultTokenValue);
        return defaultTokenValue;
      }
    } catch (err) {
      return defaultTokenValue;
    }
  });

  const setSessionToken = (sessionToken: string) => {
    window.localStorage.setItem(TOKEN, sessionToken);
    setToken(sessionToken);
  };

  const login = useCallback(async (stytchToken: string) => {
    const { sessionToken } = await postAuthenticationToken(stytchToken);
    setSessionToken(sessionToken);
    window.location.replace(`${window.location.origin}/customer/payouts`);
  }, []);

  const logout = useCallback(async () => {
    try {
      await postLogoutProfile();
      setToken(defaultTokenValue);
      window.localStorage.removeItem(TOKEN);
      navigate('/login');
    } catch (err) {
      // we want to await, but if it fails we still want to reset their view.
      setToken(defaultTokenValue);
      window.localStorage.removeItem(TOKEN);
      navigate('/login');
    }
  }, [navigate]);

  return { token, setSessionToken, login, logout };
};

import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../styles';

const Container = styled.div`
  border: 1px solid ${colors.accentTeal};
  color: ${colors.trueWhite};
  display: flex;
`;

const Badge = styled.div`
  align-items: center;
  background: ${colors.accentTeal};
  display: flex;
  padding: 0 15px;
`;
const Message = styled.div`
  color: ${colors.accentTeal};
  padding: 10px 15px;

  a {
    text-decoration: underline;
  }
`;

export const NewFeatureAlert: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Container>
      <Badge>New</Badge>
      <Message>{children}</Message>
    </Container>
  );
};

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';
import { Icon } from '@checkrx/pay-component-library';

import { cn } from '@/lib/utils';
import { ReactNode } from 'react';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

interface TooltipContentProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> {
  sideOffset?: number;
}

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  TooltipContentProps
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

interface TooltipLabelProps {
  label: ReactNode;
  tooltipText: string;
}

const TooltipLabel: React.FC<TooltipLabelProps> = ({ label, tooltipText }) => {
  return (
    <Tooltip>
      <div className='flex gap-1 items-center'>
        {label}
        <TooltipTrigger>
          <Icon name="help-circle" color="primary"/>
        </TooltipTrigger>
        <TooltipContent>
          <div className="max-w-[400px]">
            {tooltipText}
          </div>
        </TooltipContent>
      </div>
    </Tooltip>
  )
}

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider, TooltipLabel };


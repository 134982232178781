import { ControlsRow } from '@/shared/components/CreationComponents';
import { BetweenWrapper } from '@/shared/components/Wrappers.styled';
import {
  CustomerProfile,
  PayoutGroupDefinition,
  PayoutGroupDefinitionFilterSelections,
} from '@/shared/types';
import { Dropdown } from '@checkrx/pay-component-library';

type Props = {
  customerProfile?: CustomerProfile;
  onPayoutGroupDefinitionSelect: (arg: PayoutGroupDefinitionFilterSelections) => void;
  payoutGroupDefinitions?: Array<PayoutGroupDefinition>;
};

export default function PayoutGroupDefinitionFilters({
  onPayoutGroupDefinitionSelect,
  payoutGroupDefinitions,
}: Props) {
  // customer has nothing setup
  if (!payoutGroupDefinitions || payoutGroupDefinitions.length === 0) {
    return null;
  }

  const orderedPayoutGroupDefinitions = payoutGroupDefinitions.sort((a, b) => a.order - b.order);

  return (
    <BetweenWrapper>
      <ControlsRow>
        {orderedPayoutGroupDefinitions.map((definition) => (
          <Dropdown
            key={definition.tagKey}
            options={[
              { label: `Any ${definition.name}`, value: '' },
              ...definition.allowedValues.map((value) => {
                return {
                  label: value.name,
                  value: value.tagValue,
                };
              }),
            ]}
            closeOnOutsideClick
            onSelect={(opt) => {
              onPayoutGroupDefinitionSelect({
                [definition.name]: opt.value.length === 0 ? null : opt.label,
              });
            }}
          />
        ))}
      </ControlsRow>
    </BetweenWrapper>
  );
}

/*
  Show the feature promo for a recent user, or a bit after the feature relase
*/
// function shouldShowFeaturePromo(customerProfile: CustomerProfile | undefined) {
//   if (customerProfile) {
//     const featureLaunchDate = dayjs('2024-03-30');
//     const customerProfileCreationDate = dayjs(customerProfile.createdAt);
//     const effectiveCutoffDate = featureLaunchDate.isAfter(customerProfileCreationDate)
//       ? featureLaunchDate
//       : customerProfileCreationDate;

//     return effectiveCutoffDate.isAfter(dayjs().subtract(1, 'month'));
//   } else {
//     return false;
//   }
// }

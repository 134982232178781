import { CardTitle } from '@/shared/components/Card';
import { colors } from '@/shared/styles';
import styled from 'styled-components';

import { useCustomer } from '@/services/useCustomerProfile';
import DollarAmount from '@/shared/components/DollarAmount';
import useLoading from '@/shared/hooks/useLoading';
import { LoadingScope } from '@/shared/types';
import { useEffect } from 'react';

const FloatedBalanceCardWrapper = styled.div`
  padding: 24px 40px 24px 24px;
  background: ${colors.primaryWhite};
  max-height: 208px;
  height: auto;
  min-height: 172px;
  gap: 15px;
`;

const Text = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${colors.labelGrey};
  line-height: 18px;
`;

const BalanceRow = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 20px;
  align-items: center;
  justify-content: start;
  width: 100%;
  min-width: 100%;
`;

export default function FloatedBalanceCard() {
  const { data: customer, isLoading } = useCustomer();
  const setLoading = useLoading(isLoading, LoadingScope.customerDashboard);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  if ((customer?.maximumFloat || 0) <= 0) {
    return null;
  }

  return (
    <FloatedBalanceCardWrapper>
      <CardTitle>Floated Balance</CardTitle>
      <Text>Your current floated balance and maximum float:</Text>
      <BalanceRow>
        {!isLoading && (
          <>
            <DollarAmount
              amountCents={customer?.floatedBalance || 0}
              preambleSize="1rem"
              dollarSize="2rem"
              centSize="1rem"
              color={colors.accentOrange}
            />
            <p> / </p>
            <DollarAmount
              amountCents={customer?.maximumFloat || 0}
              color={colors.primaryBlue}
              preambleSize=".875rem"
              dollarSize="1rem"
              centSize=".875rem"
            />
          </>
        )}
      </BalanceRow>
    </FloatedBalanceCardWrapper>
  );
}

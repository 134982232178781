import dayjs from 'dayjs';
import { useState } from 'react';

import { Button, Dropdown, TextInput } from '@checkrx/pay-component-library';

import { useCustomerPayoutGroupDefinitions } from '@/services/useCustomerProfile';
import { usePayouts } from '@/services/usePayouts';
import { DateRangeSelector } from '@/shared/components/DateRangeSelector';
import { GenericSection } from '@/shared/components/List/List';
import { ListView } from '@/shared/components/ListView/ListView';
import { NewFeatureAlert } from '@/shared/components/NewFeatureAlert';
import {
  CustomerProfile,
  Payout,
  PayoutGroupDefinitionFilterSelections,
  PayoutStatus,
  PayoutPurpose,
} from '@/shared/types';
import { generateAndDownloadCsv } from '@/shared/util';
import { formatCentsAsDollars } from '@/shared/utils/formatters';
import PayoutGroupDefinitionFilters from './PayoutGroupDefinitionFilters';
import { PayoutSheet } from './PayoutSheet';
import { PayoutStatusText } from './PayoutStatusText';
import { useGetFeatureToggle } from '@/shared/feature-toggles/feature-toggles';

const payoutCompletionDisplayDate = (payout: Payout) => {
  const completed = payout.completedAt || payout.status === PayoutStatus.Completed;
  const completionDate =
    completed && !payout.completedAt ? payout.expectedCompletionDate : payout.completedAt;
  const completionFormat = payout.completedAt ? 'MMM D YYYY, h:mma' : 'MMM D YYYY';
  return completed ? (
    dayjs(completionDate).format(completionFormat)
  ) : payout.expectedCompletionDate ? (
    <div className="flex flex-col">
      <div className="text-textGrey text-[12px]">Expected</div>
      {dayjs(payout.expectedCompletionDate).format('MMM D YYYY')}
    </div>
  ) : null;
};

const payoutCompletionDisplayCsv = (payout: Payout) => {
  return payout.completedAt
    ? dayjs(payout.completedAt).format('MM-DD-YYYY HH:mm:ss')
    : payout.expectedCompletionDate
      ? dayjs(payout.expectedCompletionDate).format('MM-DD-YYYY')
      : null;
};

export const payoutPurposeDisplay = (payout: Payout) => {
  switch(payout.purpose) {
    case PayoutPurpose.Reimbursement:
      return 'Reimbursement';
    case PayoutPurpose.Earning:
    default:
      return 'Earning';
  }
};

type Props = {
  payWorkerId?: string;
  customerProfile: CustomerProfile;
};

export default function PayoutsTable({ payWorkerId, customerProfile }: Props) {
  const allTimeStartDate = dayjs(customerProfile.createdAt).toDate();
  const defaultStartDate = dayjs().subtract(1, 'week').startOf('day').toDate();
  const defaultEndDate = dayjs().add(1, 'day').toDate();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const [searchString, setSearchString] = useState('');

  // Worker filtering state
  const [selectedStatus, setSelectedStatus] = useState<PayoutStatus | ''>('');
  const [workerId] = useState(payWorkerId || '');
  const [workerEmail] = useState('');
  const [workerLegalName] = useState('');

  const [selectedPayout, setSelectedPayout] = useState<Payout | undefined>();
  const [selectedPayoutGroupDefinitions, setSelectedPayoutGroupDefinitions] = useState<
    PayoutGroupDefinitionFilterSelections | undefined
  >();
  const [showPayoutSheet, setShowPayoutSheet] = useState(false);

  const {
    data: fetchedPayouts,
    isLoading: isPayoutsLoading,
    isError,
  } = usePayouts({
    startDate,
    endDate,
    workerId,
    email: workerEmail,
    legalName: workerLegalName,
    searchString,
    selectedStatus: selectedStatus !== '' ? selectedStatus : null,
    payoutGroupDefinitionFilterSelections: selectedPayoutGroupDefinitions,
  });

  const showPayoutPurpose = useGetFeatureToggle('showPayoutPurpose');

  const { data: payoutGroupDefinitions, isLoading: isPayoutGroupDefinitionsLoading } =
    useCustomerPayoutGroupDefinitions();

  const isLoading = isPayoutsLoading || isPayoutGroupDefinitionsLoading;

  const sections: GenericSection<Payout>[][] = [
    [
      {
        id: 'email',
        title: 'Email',
        content: (row) => row.workerResource?.profile?.email,
        width: 150,
      },
      {
        id: 'name',
        title: 'Name',
        content: (row) => row.workerResource?.profile?.legalName,
        width: 150,
        minScreenWidth: 1412,
      },
      {
        id: 'description',
        title: 'Description',
        content: (row) => row.description,
        width: 150,
        minScreenWidth: 1068,
      },
      {
        id: 'createdDate',
        title: 'Created',
        content: (row) => dayjs(row.createdAt).format('MM/DD/YYYY'),
        width: 150,
        minScreenWidth: 1068,
      },
      {
        id: 'completionDate',
        title: 'Completion Date',
        content: (row) => payoutCompletionDisplayDate(row),
        width: 168,
      },
      {
        id: 'status',
        title: 'Status',
        content: (row) => <PayoutStatusText payout={row} />,
        width: 100,
      },
      {
        id: 'amount',
        title: 'Amount',
        content: (row) => formatCentsAsDollars(row.amountCents),
        width: 100,
      },
      ...(showPayoutPurpose ? [ {
        id: 'purpose',
        title: 'Purpose',
        content: (row: Payout) => payoutPurposeDisplay(row),
        width: 120,
        minScreenWidth: 1068,
        tooltipText: "Payouts with purpose 'Earning' (the default) are included in Checkr Pay's tax calculations, whereas payouts with 'Reimbursement' purpose are not.  This may be set when creating payouts via API.",
      }] : [])
    ],
    [
      {
        id: 'metadata',
        title: 'Metadata',
        content: (row) => row.metadata,
        width: 200,
        minScreenWidth: 1524,
      },
    ],
  ];

  return (
    <>
      <div className="flex flex-col gap-4 bg-primaryWhite p-4 h-full">
        <NewFeatureAlert>
          New Payouts Table: Explore the new table by clicking on a row to view actions and more
          information about the payout.
        </NewFeatureAlert>
        <ListView
          sections={sections}
          data={fetchedPayouts}
          loading={isLoading}
          error={isError}
          onRowClick={(row) => {
            setSelectedPayout(row);
            setShowPayoutSheet(true);
          }}
          header={
            <>
              <div className="flex w-full min-w-fit h-fit items-start justify-between">
                <div className="flex flex-row items-center justify-start w-fit gap-5">
                  <TextInput
                    value={searchString}
                    width={400}
                    placeholder="Filter by search term"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchString(e.target.value || '');
                    }}
                  />
                  <DateRangeSelector
                    allTimeStartDate={allTimeStartDate}
                    defaultStartDate={defaultStartDate}
                    defaultEndDate={defaultEndDate}
                    onChange={(_, start, end) => {
                      setStartDate(start);
                      setEndDate(end);
                    }}
                  />
                  <Dropdown
                    options={[
                      { label: 'Any Status', value: '' },
                      { label: 'Queued', value: PayoutStatus.Queued },
                      { label: 'Pending', value: PayoutStatus.Pending },
                      { label: 'Completed', value: PayoutStatus.Completed },
                      { label: 'Canceled', value: PayoutStatus.Canceled },
                      { label: 'Failed', value: PayoutStatus.Failed },
                    ]}
                    closeOnOutsideClick
                    onSelect={(opt) => {
                      setSelectedStatus(opt.value as PayoutStatus);
                    }}
                  />
                </div>
                <Button
                  text="Generate CSV"
                  sizeVariant="big"
                  colorVariant="brand"
                  width="165px"
                  icon="file-text"
                  onClick={() =>
                    generateAndDownloadCsv(fetchedPayouts || [], (payout) => ({
                      payoutAmount: formatCentsAsDollars(payout.amountCents),
                      payoutId: payout.id,
                      workerId: payout.workerId,
                      workerEmail: payout.workerResource?.profile?.email,
                      completionDate: payoutCompletionDisplayCsv(payout),
                      payoutStatus: payout.status,
                      payoutSummary: payout.description,
                      metadata: payout?.metadata || '',
                      ...(payoutGroupDefinitions || []).reduce(
                        (all, definition) => {
                          all[definition.name] = payout.groups?.[definition.name] || '';
                          return all;
                        },
                        {} as Record<string, string>
                      ),
                      ...(showPayoutPurpose ? { payoutPurpose: payout.purpose || '' } : {}),
                    }))
                  }
                />
              </div>
              <PayoutGroupDefinitionFilters
                customerProfile={customerProfile}
                payoutGroupDefinitions={payoutGroupDefinitions}
                onPayoutGroupDefinitionSelect={(
                  selectedDefinitions: PayoutGroupDefinitionFilterSelections
                ) => {
                  setSelectedPayoutGroupDefinitions((prev) => ({
                    ...prev,
                    ...selectedDefinitions,
                  }));
                }}
              />
            </>
          }
        />
      </div>
      <PayoutSheet
        payout={selectedPayout}
        open={showPayoutSheet}
        onOpenChange={setShowPayoutSheet}
      />
    </>
  );
}


import { colors } from '@/shared/styles';
import React, { ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';
import Tether from '../MenuButton/Tether';

interface TooltipProps {
  children: ReactNode;
  content: ReactNode | string;
  width?: number;
  align?: 'top' | 'bottom' | 'left' | 'right';
  offset?: { vertical?: number; horizontal?: number };
  enabled?: boolean;
}

const TooltipContent = styled.div<{ width?: number }>`
  background-color: ${colors.primaryWhite};
  border: 1px solid ${colors.dividerGrey};
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  color: ${colors.textGrey};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: ${(props) => `${props.width}px` || 'auto'};
  max-width: 480px;
  word-wrap: break-word;
`;

const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  width,
  align = 'top',
  offset = { vertical: 8, horizontal: 0 },
  enabled = true,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => enabled && setIsVisible(true);
  const handleMouseLeave = () => enabled && setIsVisible(false);

  return (
    <div ref={targetRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      <Tether target={targetRef} align={align} offset={offset} show={isVisible}>
        <TooltipContent width={width}>
          {typeof content === 'string' ? content : content}
        </TooltipContent>
      </Tether>
    </div>
  );
};

export default Tooltip;

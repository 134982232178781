import { toast } from '@/app/wrappers/Toaster/Toaster';
import { Button } from '@/components/ui/button';
import { DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useFileOneTaxes } from '@/services/useWorkerTaxes';
import DollarAmount from '@/shared/components/DollarAmount';
import { WorkerWithEarnings } from '../taxes.types';
import { TAX_YEARS } from '../utils';

type Props = {
  worker: WorkerWithEarnings;
  onSuccess?: () => void;
  onClose?: () => void;
};

export const FileIndividual1099 = ({ worker, onSuccess, onClose }: Props) => {
  const fileOneTaxes = useFileOneTaxes();

  const handleFile = () => {
    fileOneTaxes.mutate(
      {
        workerProfileId: worker.id,
        year: TAX_YEARS[0],
      },
      {
        onSuccess: () => {
          toast({
            message: 'Successfully queued filing',
            type: 'success',
            duration: 5000,
          });
          onSuccess?.();
        },
      }
    );
  };

  const overrideTotalEarningsAmount =
    worker?.['1099nec']?.[TAX_YEARS[0]]?.overrideTotalEarningsAmount ?? 0;

  return (
    <DialogContent className="max-w-2xl">
      <DialogHeader>
        <DialogTitle>Review and File 1099</DialogTitle>
        <p className="text-sm text-muted-foreground">
          Please review the information below before filing this worker&apos;s 1099-NEC.
        </p>
      </DialogHeader>

      <div className="py-4">
        <div className="space-y-4">
          <div className="flex justify-between">
            <span className="text-sm font-medium">Earner:</span>
            <span>{worker.profile?.legalName ?? '----'}</span>
          </div>

          <div className="flex justify-between">
            <span className="text-sm font-medium">Email:</span>
            <span>{worker.profile?.email ?? '----'}</span>
          </div>

          <div className="flex justify-between">
            <span className="text-sm font-medium">Platform Earnings:</span>
            <DollarAmount amountCents={worker.earnedCentsTotal} />
          </div>

          {worker.externalEarningsTotal > 0 && (
            <div className="flex justify-between">
              <span className="text-sm font-medium">External Earnings:</span>
              <DollarAmount amountCents={worker.externalEarningsTotal} />
            </div>
          )}

          <div className="flex justify-between">
            <span className="text-sm font-medium">Total Earnings:</span>
            <DollarAmount amountCents={worker.totalEarnings} />
          </div>

          {overrideTotalEarningsAmount > 0 && (
            <p className="text-sm text-amber-500">
              Note: Total earnings have been manually overridden to{' '}
              <DollarAmount amountCents={overrideTotalEarningsAmount} />
            </p>
          )}
        </div>

        <div className="mt-6 rounded-md border p-4">
          <h4 className="font-medium mb-2">Important Notes</h4>
          <ul className="list-disc list-inside space-y-1 text-sm text-gray-600">
            <li>Make sure worker information is accurate</li>
            <li>Verify earnings amount is correct</li>
            <li>Ensure all required forms are completed</li>
          </ul>
        </div>
      </div>

      <div className="flex justify-between">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleFile} disabled={fileOneTaxes.isLoading}>
          {fileOneTaxes.isLoading ? 'Filing...' : 'File Now'}
        </Button>
      </div>
    </DialogContent>
  );
};


import { trackCoreCustomerEvent } from '@/analytics';
import { useGetInTouch } from '@/services/useGetInTouch';
import { colors } from '@/shared/styles';
import { IconName } from '@checkrx/pay-component-library/dist/Icon/Icon';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import checkrPayPhones from './checkr-pay-phones.png';
import CoreDropdown from './CoreDropdown';
import checkrPayLogo from './logo.png';
import { ReactComponent as MoneyIcon } from './money-icon.svg';
import { ReactComponent as RevenueIcon } from './revenue-icon.svg';
import { ReactComponent as SupportIcon } from './support-icon.svg';

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 1000px;
  max-width: 960px;
  overflow-y: auto;
`;

const TitleSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  max-width: 864px;
  margin-bottom: 48px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  line-height: 1.25;
  letter-spacing: 1%;
  color: #1a2026;
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const Subtitle = styled.h2`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #1a2026c7;
  line-height: 1.5;
  letter-spacing: 0%;
  font-family: 'Public Sans', sans-serif;
`;

const ImageGraphic = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 320px;
  margin-bottom: 48px;
`;

const Logo = styled.img`
  width: auto;
  height: 24px;
  margin-bottom: 36px;
  @media (max-width: 854px) {
    margin-bottom: 24px;
  }
`;

const CardRow = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 36px;

  @media (max-width: 854px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  width: 264px;
  gap: 8px;
  border-radius: 12px;
  display: flex;
  justify-content: left;
  text-align: left;
  flex-direction: column;
  padding: 18px 18px;
  border: 1px solid #e1e6eb;
  @media (max-width: 854px) {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    gap: 18px;
  }
`;

const CardIcon = styled.div`
  padding-bottom: 8px;
  min-width: 36px;
  min-height: 36px;
  @media (max-width: 854px) {
    padding-bottom: 0;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CardTitle = styled.h3`
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  text-align: left;
  color: #1a2026;
  font-family: 'Public Sans', sans-serif;
`;

const CardText = styled.p`
  font-size: 14px;
  line-height: 1.4;
  color: #1a2026c7;
  text-align: left;
  font-weight: 400;
  font-family: 'Public Sans', sans-serif;
`;

const DemoCard = styled.div`
  width: 100%;
  max-width: 856px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  height: 120px;
`;

const CompletedMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 42px;
  text-align: center;
`;

const FormRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
const Button = styled.button`
  background-color: #0a57a4;
  color: ${colors.trueWhite};
  border-radius: 4px;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  height: 36px;
  &:hover {
    background-color: #04417f;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #e1e6eb;
    color: #1a20267a;
  }
`;

const Bold = styled.span`
  font-weight: 700;
`;

type BGCData = {
  accountId: string | null;
  companyName: string | null;
  companyPhone: string | null;
  companyWebsite: string | null;
  email: string | null;
  fullName: string | null;
  userId: string | null;
};

export default function BGCCustomerOnboardingHome() {
  const [requested, setRequested] = useState(false);
  const [completed, setCompleted] = useState(false);
  const { mutateAsync: getInTouch } = useGetInTouch();
  const [searchParams] = useSearchParams();
  const [payoutVolume, setPayoutVolume] = useState<string>('');
  const [activeEarners, setActiveEarners] = useState<string>('');
  const bgcData: BGCData = {
    accountId: searchParams.get('accountId'),
    companyName: searchParams.get('companyName'),
    companyPhone: searchParams.get('companyPhone'),
    companyWebsite: searchParams.get('companyWebsite'),
    email: searchParams.get('email'),
    fullName: searchParams.get('fullName'),
    userId: searchParams.get('userId'),
  };
  const handleRequestDemo = (type: 'request' | 'calculate') => {
    getInTouch({
      name: bgcData?.fullName ?? '',
      email: bgcData?.email ?? '',
      phoneNumber: bgcData?.companyPhone ?? '',
      companyName: bgcData?.companyName ?? '',
      accountId: bgcData?.accountId ?? '',
      companyWebsite: bgcData?.companyWebsite ?? '',
      payoutVolume: payoutVolume,
      activeEarners: activeEarners,
    });
    if (type === 'request') {
      setRequested(true);
    }
    if (type === 'calculate') {
      setCompleted(true);
    }
  };

  // track amplitude event
  useEffect(() => {
    if (bgcData.userId && bgcData.email && bgcData.companyName) {
      trackCoreCustomerEvent(
        bgcData.userId,
        bgcData.email,
        bgcData.companyName,
        'Core Customer visited pay CTA'
      );
    }
  }, [bgcData.userId, bgcData.email, bgcData.companyName]);

  const cards: {
    icon: IconName;
    title: string;
    text: string;
  }[] = [
    {
      icon: 'bookmark',
      title: 'Seamless Payment Experience',
      text: `Manage payroll across multiple payout methods - 
      instant payment or direct deposits - all within a single, intuitive platform`,
    },
    {
      icon: 'users',
      title: 'White Glove Support',
      text: `Comprehensive managed earner support, operations & 
      covered loss liability. We handle all payment support related issues`,
    },
    {
      icon: 'bookmark',
      title: 'Unlock New Revenue Stream',
      text: `Earn predictable interchange revenue share 
      through our base product, which is free for earners`,
    },
  ];
  const payoutVolumeOptions = [
    { label: 'Less than $50,000', value: 'Less than $50,000' },
    { label: '$50,000 - $100,000', value: '$50,000 - $100,000' },
    { label: '$100,000 - $500,000', value: '$100,000 - $500,000' },
    { label: '$500,000 - $1,000,000', value: '$500,000 - $1,000,000' },
    { label: 'More than $1,000,000', value: 'More than $1,000,000' },
  ];

  const activeEarnersOptions = [
    { label: 'Less than 100', value: 'Less than 100' },
    { label: '100 - 500', value: '100 - 500' },
    { label: '500 - 1,000', value: '500 - 1,000' },
    { label: '1,000 - 5,000', value: '1,000 - 5,000' },
    { label: 'More than 5,000', value: 'More than 5,000' },
  ];

  const iconsArray = [
    <MoneyIcon key="money" />,
    <SupportIcon key="support" />,
    <RevenueIcon key="revenue" />,
  ];

  return (
    <HomeWrapper>
      <a href="https://www.checkrpay.com" target="_blank" rel="noopener noreferrer">
        <Logo src={checkrPayLogo} alt="Checkr Pay Logo" />
      </a>
      <TitleSection>
        <Title>Want to save on the cost of your background checks?</Title>
        <Subtitle>
          Checkr Pay allows you to offer instant payments to your W2 and 1099 workforce, while
          generating revenue for your business through interchange.{' '}
          <Bold>Answer the questions below to see how much you could earn.</Bold>
        </Subtitle>
        {!completed ? (
          <FormRow>
            <CoreDropdown
              options={payoutVolumeOptions}
              onChange={(v) => setPayoutVolume(v.value)}
              placeholder="Total monthly payout volume"
            />
            <CoreDropdown
              options={activeEarnersOptions}
              onChange={(v) => setActiveEarners(v.value)}
              placeholder="Total number of active earners"
            />
            <Button
              onClick={() => handleRequestDemo('calculate')}
              disabled={payoutVolume === '' || activeEarners === ''}
            >
              Contact Sales
            </Button>
          </FormRow>
        ) : (
          <CompletedMessage>
            <Subtitle>
              <Bold>We&apos;ve received your information!</Bold> Our team will be in touch to
              provide you with your potential savings.
            </Subtitle>
          </CompletedMessage>
        )}
      </TitleSection>

      <ImageGraphic src={checkrPayPhones} alt="Checkr Pay Phones" />

      <CardRow>
        {cards.map((card, index) => (
          <Card key={index}>
            <CardIcon>{iconsArray[index]}</CardIcon>
            <CardContent>
              <CardTitle>{card.title}</CardTitle>
              <CardText>{card.text}</CardText>
            </CardContent>
          </Card>
        ))}
      </CardRow>

      <DemoCard>
        {requested ? (
          <CompletedMessage>
            <Title>Thank You!</Title>
            <Subtitle>A member of our team will be in touch soon.</Subtitle>
          </CompletedMessage>
        ) : (
          <>
            <Subtitle>
              Interested in learning more about how Checkr Pay can help Checkr attract and retain
              top talent? Click the button below and someone on our team will get in touch.
            </Subtitle>
            <Button onClick={() => handleRequestDemo('request')}>Request Demo</Button>
          </>
        )}
      </DemoCard>
    </HomeWrapper>
  );
}

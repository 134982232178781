/**
 * A 404-not found Error page that links back to home
 */
import { useNavigate } from 'react-router-dom';

import error from '@/shared/assets/error-graphic.svg';
import StandaloneSidebar from '@/shared/components/StandaloneSidebar';
import { Button } from '@checkrx/pay-component-library';

import { ErrorContentBox, ErrorTitle, Graphic, Text } from './ErrorPage.styled';

function NotFoundErrorPage() {
  const navigate = useNavigate();

  return (
    <>
      <StandaloneSidebar />
      <ErrorContentBox>
        <Graphic src={error} />
        <ErrorTitle>Uh oh! We can&apos;t find that page!</ErrorTitle>
        <Text>
          Looks like that link was a dead end! Click below to head back to your dashboard. If you
          think is a mistake on our end, contact Checkr Pay support.
        </Text>
        <Button
          className="error-page-refresh-btn"
          width="200px"
          sizeVariant="small"
          colorVariant="dark"
          //   leftIcon={<RefreshIcon />}
          text="Back to Safety"
          onClick={() => {
            navigate('/customer');
          }}
        />
      </ErrorContentBox>
    </>
  );
}

export default NotFoundErrorPage;

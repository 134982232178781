import { useCustomer } from '@/services/useCustomerProfile';
import { PageLayout } from '@/shared/components/PageLayout/PageLayout';
import { useGetFeatureToggle } from '@/shared/feature-toggles/feature-toggles';
import { BulkUpdateDialog } from './BulkUpdate/BulkUpdateDialog';
import { TaxesTable } from './TaxesTable';
import { TaxWizard } from './TaxWizard/TaxWizard';

export default function CustomerTaxesTab() {
  const { data: customer } = useCustomer();
  const showTaxes = useGetFeatureToggle('showTaxes');

  if (customer?.featureFlags?.taxesDisabled.enabled) {
    return (
      <div className="flex w-full h-full flex-col justify-center items-center mb-64">
        <div className="flex flex-col items-center gap-1 mb-6">
          <p className="text-3xl">You currently have Taxes disabled on your account</p>
        </div>

        <div className="flex flex-col items-center gap-1 mb-6">
          <p>Generate 1099-NECs, file, review, and request corrections,</p>
          <p>all available here on your dashboard.</p>
        </div>
        <a
          href="https://customersupport.checkrpay.com/"
          target="_blank"
          rel="noreferrer"
          className="text-xs opacity-50 underline hover:opacity-80 focus:opacity-80"
        >
          Reach out to get taxes enabled on your account.
        </a>
      </div>
    );
  }

  if (!showTaxes) {
    return (
      <PageLayout title="Taxes (1099-NEC)">
        <div className="flex w-full h-full flex-col justify-center items-center mb-64">
          <div className="flex flex-col items-center gap-1 mb-6">
            <p className="text-3xl">✨ Coming Soon! ✨</p>
          </div>

          <div className="flex flex-col items-center gap-1 mb-6">
            <p>
              🚀 In preparation for the 2024 Tax Filing Season, we&apos;re working hard on bringing
              you an amazing tax filing experience.
            </p>
            <p>🎉 Stay tuned - we can&apos;t wait to show you what we&apos;re building! 🌟</p>
          </div>
        </div>
      </PageLayout>
    );
  }
  return (
    <PageLayout
      title="Taxes (1099-NEC)"
      headerRight={
        <div className="flex items-center gap-4">
          <BulkUpdateDialog />
          <TaxWizard />
        </div>
      }
    >
      <div className="h-full overflow-y-hidden">
        <TaxesTable />
      </div>
    </PageLayout>
  );
}


import { WorkerWithEarnings } from '../taxes.types';
import { TAX_YEARS } from '../utils';

export const taxWizardColorMap = {
  default: '#4B5563',
  readyToGenerate: '#2563EB',
  readyToFile: '#16A34A',
  excluded: '#CA8A04',
};

export const checkIsLessThan600 = (worker: WorkerWithEarnings) => {
  return worker.totalEarnings < 600_00;
};

export const checkIsIneligible = (worker: WorkerWithEarnings) => {
  // Check if earnings are less than $600
  if (worker.totalEarnings < 600_00) {
    return true;
  }

  // Check if TIN verification doesn't match
  if (worker?.aboundTinVerification?.tinVerificationStatus !== 'MATCH') {
    return true;
  }

  return false;
};

export const checkIsEligibleToGenerate = (worker: WorkerWithEarnings) => {
  // check if manually excluded
  if (worker['1099nec']?.[TAX_YEARS[0]]?.manuallyExcluded) {
    return false;
  }

  // check if 1099 record exists with status
  if (worker['1099nec']?.[TAX_YEARS[0]]?.status) {
    return false;
  }

  // check if earnings are at least $600
  if (worker.totalEarnings < 600_00) {
    return false;
  }

  // check if TIN verification matches
  if (worker?.aboundTinVerification?.tinVerificationStatus !== 'MATCH') {
    return false;
  }

  return true;
};

export const checkIsEligibleToFile = (worker: WorkerWithEarnings) => {
  // Check if worker has a 1099-NEC record for the tax year
  const taxRecord = worker['1099nec']?.[TAX_YEARS[0]];
  if (!taxRecord) return false;

  // Check if worker is not manually excluded
  if (taxRecord.manuallyExcluded === true) return false;

  // Check if status is CREATED
  return taxRecord.status === 'CREATED';
};


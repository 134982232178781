/**
 * An Error page specifically for 403s!
 */
import { useQueryClient } from '@tanstack/react-query';

import { useNavigate } from 'react-router-dom';

import error from '@/shared/assets/error-graphic.svg';
import StandaloneSidebar from '@/shared/components/StandaloneSidebar';
import { Button } from '@checkrx/pay-component-library';

import { ErrorContentBox, ErrorTitle, Graphic, Text } from './ErrorPage.styled';
import { ErrorPageProps } from './ErrorPage.types';

function NotAllowedErrorPage({ resetErrorBoundary }: ErrorPageProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (
    <>
      <StandaloneSidebar />
      <ErrorContentBox>
        <Graphic src={error} />
        <ErrorTitle>You&apos;re not allowed to access that resource!</ErrorTitle>
        <Text>
          It looks like you&apos;re attempting to access a resource you don&apos; have permissions
          to access. Contact your account admin or click below to go back to your dashboard.
        </Text>
        <Button
          className="error-page-refresh-btn"
          width="200px"
          sizeVariant="small"
          colorVariant="dark"
          //   leftIcon={<RefreshIcon />}
          text="Back to Safety"
          onClick={async () => {
            // Invalidate all the cached queries
            await queryClient.invalidateQueries();

            if (resetErrorBoundary) {
              resetErrorBoundary();
            }
            navigate('/customer');
          }}
        />
      </ErrorContentBox>
    </>
  );
}

export default NotAllowedErrorPage;

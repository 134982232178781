import { TableStatusText } from '@/shared/components/TableComponents';
import { colors } from '@/shared/styles';
import { Payout, PayoutStatus } from '@/shared/types';

interface PayoutStatusProps {
  payout: Payout;
}

export const PayoutStatusText = ({ payout }: PayoutStatusProps) => {
  const status = payout?.status;
  if (!status) {
    return <></>;
  }

  // Branch on the status type
  switch (status) {
    case PayoutStatus.Pending:
      return <TableStatusText color={colors.accentOrange}>Pending</TableStatusText>;
    case PayoutStatus.Canceled:
      if ((payout?.repayments?.length ?? 0) > 0) {
        return <TableStatusText color={colors.accentRed}>Repaid</TableStatusText>;
      }
      return <TableStatusText color={colors.accentRed}>Canceled</TableStatusText>;
    case PayoutStatus.Failed:
      return <TableStatusText color={colors.accentRed}>Failed</TableStatusText>;
    case PayoutStatus.Completed:
      return <TableStatusText color={colors.accentGreen}>Completed</TableStatusText>;
    case PayoutStatus.Queued:
      return <TableStatusText color={colors.accentOrange}>Queued</TableStatusText>;
    default:
      return <p>N/A</p>;
  }
};

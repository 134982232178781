import { ReactComponent as Logo } from '@/shared/assets/white-logo.svg';
import styled from 'styled-components';

const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 384px;
  min-width: 384px;
  height: 100%;

  background: #009bb5;
`;

const StandaloneSidebar = () => (
  <SideBar>
    <Logo />
  </SideBar>
);

export default StandaloneSidebar;

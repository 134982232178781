/**
 * A Query that allows us to fetch and search users from the backend
 *
 * When we query users, we can pass in a few filtering params and
 * a search string.
 */
import { toast } from '@/app/wrappers/Toaster/Toaster';
import { CustomerProfile, Permissions } from '@/shared/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCustomerProfile } from '@/services/useCustomerProfile';
import { AxiosError } from 'axios';
import serverApi from './serverApi';

// Refetch our user list every Minute
const REFETCH_AFTER_SECONDS = 60;
const CHECKR_PAY_GIG_BOLT_CUSTOMER = '63d41f7eb1f29b0260ab58c6'
const PRODUCTION_ENVIRONMENT = 'production'

async function fetchUsers () {
  const response = await serverApi.get('/dashboard/v0/users');
  return response.data;
}

const isUserCheckrPayAdmin = (email: string) => {
  const [_, customerEmailOrigin] = email.split('@');
  return ['checkr.com', 'checkrpay.com'].includes(customerEmailOrigin || '');
}

export const useUsers = (searchString = '') => {
  // get the currently signed-in user
  const { data: customerProfile } = useCustomerProfile();

  return useQuery({
    queryKey: ['customer', 'users'],
    queryFn: () => fetchUsers(),
    refetchInterval: 1000 * REFETCH_AFTER_SECONDS,
    // Implement semi-fuzzy word search on the front end only.
    select: (data: { users: Array<CustomerProfile> }): Array<CustomerProfile> => {
      const { users: unfilteredUsersList } = data;
      let users: CustomerProfile[] = unfilteredUsersList;

      // Our hacky solution to filter out Checkr Admin Emails from the list
      // if the Customer in question isn't a Checkr Pay internal customer
      // We skip this if the user logged in is a Checkr Pay Admin
      if (process.env.REACT_APP_ENV === PRODUCTION_ENVIRONMENT &&
        !isUserCheckrPayAdmin(customerProfile?.email || '') &&
        customerProfile?.customer._id !== CHECKR_PAY_GIG_BOLT_CUSTOMER
      ) {
        users = unfilteredUsersList.filter((user: CustomerProfile) => {
          if (!isUserCheckrPayAdmin(user.email ?? '') &&
            user.customer._id !== CHECKR_PAY_GIG_BOLT_CUSTOMER
          ) {
            return user
          }
        })
      }

      if (searchString.length === 0) {
        return users;
      }

      const searchWords = searchString.toLowerCase().split(' ');

      return users.filter((user: CustomerProfile) => {
        const serialized = JSON.stringify(user).toLowerCase();
        return searchWords.some((word) => serialized.includes(word));
      });
    },
  });
};

async function postCreateOneUser (email: string, permissions: Permissions) {
  const response = await serverApi.post('/dashboard/v0/users', {
    email,
    permissions,
  });
  return response.data;
}

const putUpdateOneUser = async (
  email: string,
  permissions: Permissions
): Promise<{ customerProfile: CustomerProfile }> => {
  const response = await serverApi.put('/dashboard/v0/users', {
    email,
    permissions,
  });
  return response.data;
};

async function deleteOneUser (email: string) {
  const response = await serverApi.delete('/dashboard/v0/users', {
    // Unlike put and post, axios second param to delete is not body
    // Have to use the "data" config to send post body with DEL
    data: {
      email,
    },
  });
  return response.data;
}

export const useCreateOneUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ email, permissions }: { email: string; permissions: Permissions }) =>
      postCreateOneUser(email, permissions),
    // Catch 400s and 404s explicitly
    onSuccess: () => {
      // Clear all user queries
      return queryClient.invalidateQueries({
        queryKey: ['customer', 'users'],
      });
    },
  });
};

export const useUpdateOneUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ email, permissions }: { email: string; permissions: Permissions }) =>
      putUpdateOneUser(email, permissions),
    // Catch 400s and 404s explicitly
    onSuccess: () => {
      // Clear all user queries
      return queryClient.invalidateQueries({
        queryKey: ['customer', 'users'],
      });
    },
    onError: (err: AxiosError) => {
      toast({
        message: err.message,
        type: 'error',
        duration: 5000,
      });
    },
  });
};

export const useDeleteOneUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ email }: { email: string }) => deleteOneUser(email),
    // Catch 400s and 404s explicitly
    onSuccess: () => {
      // Clear all user queries
      return queryClient.invalidateQueries({
        queryKey: ['customer', 'users'],
      });
    },
    onError: (err: AxiosError) => {
      toast({
        message: err.message,
        type: 'error',
        duration: 5000,
      });
    },
  });
};

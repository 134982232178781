/**
 * Just a placeholder view with some button component testing
 */

export default function CustomerAPITab() {
  return (
    <>
      <p>TODO!</p>
      <p>API Management Tab Coming Soon!</p>
      {/* Extra div at the end for bottom spacing */}
      <div />
    </>
  );
}

import { Legalese } from '@/shared/components/BatchUpload.styled';
import {
  ContentWrapper,
  DescriptionText,
  LabelRow,
  LabelText,
  ValueText,
} from '@/shared/components/ConfirmationModals.styled';
import DollarAmount from '@/shared/components/DollarAmount';
import { Worker } from '@/shared/types';
import { formatCentsAsDollars } from '@/shared/utils/formatters';
import { FC } from 'react';

type Props = {
  balance: number;
  worker: Worker;
  paymentAmount: number;
  metadata: string;
  description: string;
};

export const ConfirmPayout: FC<Props> = ({
  balance,
  worker,
  paymentAmount,
  metadata,
  description,
}) => {
  return (
    <ContentWrapper>
      <DescriptionText>
        Please double check the payout and worker information below, then confirm that you wish to
        proceed. If something looks incorrect, you can close this modal to make edits. After you
        confirm and submit, all changes are final.
      </DescriptionText>
      <LabelRow>
        <LabelText>Current Account Balance:</LabelText>
        <ValueText>{formatCentsAsDollars(balance ?? 0)}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Worker Id:</LabelText>
        <ValueText>{worker.id}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Worker Name:</LabelText>
        <ValueText>{worker?.profile?.legalName}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Worker Email:</LabelText>
        <ValueText>{worker?.profile?.email}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Payout Amount:</LabelText>
        <DollarAmount amountCents={paymentAmount} />
      </LabelRow>
      <LabelRow>
        <LabelText>Payout Metadata:</LabelText>
        <ValueText>{metadata || ''}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Payout Description:</LabelText>
        <ValueText>{description || ''}</ValueText>
      </LabelRow>
      <Legalese>
        By clicking <strong>Confirm</strong> below, I acknowledge I have confirmed the information
        above and authorize Checkr Pay to process this payout.
      </Legalese>
    </ContentWrapper>
  );
};

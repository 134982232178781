/**
 * A Query that allows us to fetch and search for batch files from the backend
 *
 * When we query batch files, we can pass in a few filtering params (type and date)
 */
import { useDebouncedMutation } from '@/shared/hooks/useDebouncedMutation';
import { BatchFile, BatchFileStatus, BatchUploadErrorResponse, BatchFileType } from '@/shared/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import fileDownload from 'js-file-download';
import serverApi from './serverApi';
import { isAxiosError } from 'axios';

dayjs.extend(utc);

// Refetch our batchfiles every 30 seconds
const REFETCH_AFTER_SECONDS = 30;

async function fetchBatchFiles(
  startDate: Date,
  endDate: Date,
  type?: BatchFileType,
  status?: BatchFileStatus
): Promise<Array<BatchFile>> {
  // Get Date times between which we'll query. For date selecting, use
  // beginning of the start date, and end of last date (or 'now' if today)
  const start = dayjs.utc(startDate).startOf('day');
  let end = dayjs.utc(endDate).endOf('day');
  if (end.isAfter(dayjs.utc())) {
    end = dayjs.utc();
  }

  const response = await serverApi.get('/dashboard/v0/batch_files', {
    params: {
      startDate: start.toDate(),
      endDate: end.toDate(),
      type,
      status,
    },
  });
  return response.data?.batchFiles;
}

const sortReverseChronologically = (files: Array<BatchFile>): Array<BatchFile> => {
  return files.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
};

export const useBatchFiles = (
  startDate: Date,
  endDate: Date,
  type?: BatchFileType,
  status?: BatchFileStatus
) => {
  return useQuery({
    queryKey: ['customer', 'batchFiles', type, startDate, endDate, status],
    queryFn: () => fetchBatchFiles(startDate, endDate, type, status),
    refetchInterval: 1000 * REFETCH_AFTER_SECONDS,
    select: sortReverseChronologically,
  });
};

async function postBatchCreateWorkers(batchFile: File) {
  const response = await serverApi.post(
    '/dashboard/v0/workers/batch_create',
    {
      'batch-file': batchFile,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return response.data;
}

async function postBatchCreatePayouts(batchFile: File, runAt?: Date) {
  const response = await serverApi.post(
    '/dashboard/v0/payouts/batch_create',
    {
      'batch-file': batchFile,
      runAt: runAt?.toISOString(),
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response.data;
}

async function postBatchCreateTaxes1099s(batchFile: File) {
  const response = await serverApi.post(
    '/dashboard/v0/taxes/1099-nec/batch_create',
    {
      'batch-file': batchFile,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response.data;
}

async function postBatchUpdateTaxes1099s(batchFile: File) {
  const response = await serverApi.post(
    '/dashboard/v0/taxes/1099-nec/batch_update',
    {
      'batch-file': batchFile,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response.data;
}

export const useBatchFileCreate = () => {
  const queryClient = useQueryClient();

  return useDebouncedMutation({
    mutationFn: async ({
      file,
      runAt,
      type,
    }: {
      file: File;
      runAt?: Date;
      type: BatchFileType;
    }) => {
      switch (type) {
        case BatchFileType.Worker:
          return postBatchCreateWorkers(file);
        case BatchFileType.Payout:
          return postBatchCreatePayouts(file, runAt);
        case BatchFileType.Taxes1099Create:
          return postBatchCreateTaxes1099s(file);
        case BatchFileType.Taxes1099Update:
          return postBatchUpdateTaxes1099s(file);
        default:
          throw new Error(`Unexpected batch file type: ${type}`);
      }
    },
    onSuccess: (_, { type }) => {
      // Clear all batchFile queries as well as the queries for the corresponding mutation
      // that happened here (workers or payouts)
      return queryClient.invalidateQueries({
        queryKey: ['customer', 'batchFiles', type],
      });
    },
  });
};

async function fetchBatchCsvReturnFile(batchFileId: string, batchFileName: string) {
  const response = await serverApi.post(
    `/dashboard/v0/batch_files/return`,
    {
      batchFileId,
    },
    {
      responseType: 'blob',
    }
  );
  fileDownload(response.data, `return-${batchFileName}`);
}

export const useBatchCsvReturnFile = () => {
  return useMutation({
    mutationFn: ({ batchFileId, batchFileName }: { batchFileId: string; batchFileName: string }) =>
      fetchBatchCsvReturnFile(batchFileId, batchFileName),
  });
};

async function postBatchFileCancel(batchFileId: string) {
  const response = await serverApi.post<BatchFile>(
    `/dashboard/v0/batch_files/${batchFileId}/cancel`
  );
  return response.data;
}

export const useBatchFileCancel = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ batchFileId }: { batchFileId: string }) => postBatchFileCancel(batchFileId),
    onSuccess: (batchFile) => {
      return queryClient.invalidateQueries({
        queryKey: ['customer', 'batchFiles', batchFile.type],
      });
    },
  });
};

const errorMessageMap: Record<BatchUploadErrorResponse['details'][number], string> = {
  invalid_file_type: 'File type is invalid. Must be a CSV',
  invalid_file: 'File is not correctly formatted. Please check your file headers',
  invalid_schedule: 'Files can only be scheduled up to 31 days in advance',
  missing_batch_file: 'File is missing or empty. Make sure your file has some valid content',
  batch_file_already_processed:
    'File has already been processed. Try a file with a different name',
};

export const getBatchUploadErrorMessage = (
  batchUploadError: unknown
): string | undefined => {
  if (batchUploadError) {
    if(isAxiosError<BatchUploadErrorResponse>(batchUploadError)) {
      const errorMessageMapKey = batchUploadError.response?.data.details[0]
    
      if (errorMessageMapKey && errorMessageMap[errorMessageMapKey]) {
        return errorMessageMap[errorMessageMapKey]
      }
  }
  return  'We encountered an error processing your batch file. Please contact support.'}
}

/**
 * An overview of the customer's account
 */

import styled from 'styled-components';

import Card, { CardTitle, ContentCard } from '@/shared/components/Card';
import { Navigate } from 'react-router-dom';

const QuickFiguresRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: 30px;
  height: fit-content;
  min-height: 120px;
  width: 100%;
  min-width: 100%;
`;

// Same for bottom row but the bottom row is larger
const BottomRow = styled(QuickFiguresRow)`
  min-height: 340px;
`;

const QuickFigureCard = styled(Card)`
  flex: 1;
  height: 100%;
  min-height: fit-content;
`;

const VolumeGraphCard = styled(ContentCard)`
  width: 100%;
  min-width: 100%;
  height: fit-content;
  min-height: 410px;
`;

const RecentTransactionsCard = styled(ContentCard)`
  flex: 2; // 2x as large as Auto-Fund
  height: 100%;
  min-height: fit-content;
`;
const AutoFundCard = styled(RecentTransactionsCard)`
  flex: 1;
`;

export default function CustomerOverviewTab() {
  // Always just redirect.
  if (process.env.NODE_ENV) {
    return <Navigate to="/customer/payouts" />;
  }
  return (
    <>
      <QuickFiguresRow>
        <QuickFigureCard />
        <QuickFigureCard />
        <QuickFigureCard />
      </QuickFiguresRow>
      <VolumeGraphCard>
        <CardTitle>12-Month Payments Volume</CardTitle>
      </VolumeGraphCard>
      <BottomRow>
        <RecentTransactionsCard>
          <CardTitle>Recent Transactions</CardTitle>
        </RecentTransactionsCard>
        <AutoFundCard>
          <CardTitle>Auto-Fund</CardTitle>
        </AutoFundCard>
      </BottomRow>
      {/* Extra div at the end for bottom spacing */}
      <div />
    </>
  );
}

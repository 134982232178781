/**
 * Content of the modal that opens when you request to update a user
 */
import { useDeleteOneUser, useUpdateOneUser } from '@/services/useUsers';
import { CustomerProfile, Permissions, TabPermissions } from '@/shared/types';
import { Button, Spinner } from '@checkrx/pay-component-library';
import styled from 'styled-components';

import { toast } from '@/app/wrappers/Toaster/Toaster';
import {
  ButtonRow,
  ContentWrapper,
  DescriptionText,
} from '@/shared/components/ConfirmationModals.styled';
import { TableStatusText } from '@/shared/components/TableComponents';
import { colors } from '@/shared/styles';
import { useState } from 'react';
import UserPermissionsSelector from './UserPermissionsSelector';

const Wrapper = styled(ContentWrapper)`
  gap: 10px;
`;

const Title = styled.h2`
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.primaryBlue};
`;

const Container = styled.div`
  border: 1px solid ${colors.dividerGrey};
  border-radius: 10px;
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 15px;
  margin-top: -20px;
  margin-bottom: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.dividerGrey};
  margin-bottom: 15px;
`;

// TODO(Carter): This is duplicated from UsersTable – move to helper
const userToStatus = (user: CustomerProfile) => {
  if (user.disabled) {
    return <TableStatusText color={colors.accentRed}>Disabled</TableStatusText>;
  }

  if (user.lastLogin) {
    return <TableStatusText color={colors.accentGreen}>Active</TableStatusText>;
  }

  return <TableStatusText color={colors.accentOrange}>Pending</TableStatusText>;
};

const emptyPermissions = {
  usersTab: TabPermissions.none,
  apiTab: TabPermissions.none,
  taxTab: TabPermissions.none,
  settingsTab: TabPermissions.none,
  workersTab: TabPermissions.none,
  payoutsTab: TabPermissions.none,
  financeTab: TabPermissions.none,
  sensitiveAccountData: TabPermissions.none,
  executeRepayments: TabPermissions.none,
};

export default function UserEditModal({
  user,
  onClose,
}: {
  user: CustomerProfile;
  onClose: () => void;
}) {
  const [updatedPermissions, setUpdatedPermissions] = useState<Permissions>({
    ...emptyPermissions,
    ...user.permissions,
  });
  const { isLoading: updateOneIsLoading, mutateAsync: updateOneUser } = useUpdateOneUser();

  const { isLoading: deleteOneIsLoading, mutateAsync: deleteOneUser } = useDeleteOneUser();

  const onUpdateOneUser = async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // Create the user
    await updateOneUser({
      email: user.email,
      permissions: updatedPermissions,
    });
    onClose();
    toast({
      message: `Successfully updated ${user.email}`,
      type: 'success',
      duration: 5000,
    });
  };

  const onDeleteOneUser = async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // Delete the user
    await deleteOneUser({
      email: user.email,
    });
    onClose();
    toast({
      message: `Successfully disabled ${user.email}`,
      type: 'success',
      duration: 5000,
    });
  };

  return (
    // Otherwise we're showing the data and asking for confirmation
    <Wrapper>
      <Title>{user.email}</Title>
      {userToStatus(user)}
      <Divider />
      <Title>Edit Privileges</Title>
      <DescriptionText>Select user member privileges below.</DescriptionText>
      <Container>
        <UserPermissionsSelector
          permissions={updatedPermissions}
          setPermissions={(p) => {
            setUpdatedPermissions(p);
          }}
        />
      </Container>
      <ButtonRow>
        <Button
          text="Update User"
          sizeVariant="big"
          colorVariant="brand"
          //   disabled={!hasConfirmed}
          width="90%"
          onClick={onUpdateOneUser}
        />
        {updateOneIsLoading ? (
          <Spinner size="30px" />
        ) : (
          // Keep this here for positioning!
          <></>
        )}
      </ButtonRow>
      <ButtonRow>
        <Button
          text="Disable User"
          sizeVariant="small"
          colorVariant="red"
          //   disabled={!hasConfirmed}
          width="90%"
          onClick={onDeleteOneUser}
        />
        {deleteOneIsLoading ? (
          <Spinner size="30px" />
        ) : (
          // Keep this here for positioning!
          <></>
        )}
      </ButtonRow>
    </Wrapper>
  );
}

/**
 * Higher-Order component that listens for auth changes and navigates to
 * login if not authenticated.
 *
 * If Auth Changes (via the useAuth functions setAuthToken and removeAuthToken) everything
 * underneath this React Component will re-render.
 */
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '@/services/useAuth';

export const AuthGuard = ({ children }: { children: ReactNode }) => {
  const { token } = useAuth();
  if (!token) {
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};

import useLoading from '@/shared/hooks/useLoading';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LoadingGuard } from '@/app/wrappers/LoadingGuard';
import { useAuth } from '@/services/useAuth';
import { LoadingScope } from '@/shared/types';
import StandaloneSidebar from '../../shared/components/StandaloneSidebar';

const AuthenticatePage = () => {
  const [searchParams] = useSearchParams();
  const stytchToken = searchParams.get('token');
  const { login } = useAuth();

  useLoading(true, LoadingScope.authentication);

  useEffect(() => {
    if (stytchToken) {
      login(stytchToken);
    }
  }, [login, stytchToken]);

  return (
    <>
      <StandaloneSidebar />
      <LoadingGuard loadingScope={LoadingScope.authentication}>
        <></>
      </LoadingGuard>
    </>
  );
};

export default AuthenticatePage;

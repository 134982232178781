import { colors } from '@/shared/styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type Tab = {
  label: string;
  value: string;
};

type Props<T extends Tab> = {
  tabs: T[];
  onChange: (tab: Tab) => void;
  selectedTab: T['value'];
};

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.textGrey};
  border-radius: 7.75px;
  gap: 4px;
  padding: 3.5px 4px;
  position: relative;
`;

const TabItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
  padding: 4px 8px;
  white-space: nowrap;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  color: ${({ isSelected }) => (isSelected ? colors.primaryBlue : '#fff')};
  transition: color 0.3s ease-in-out;
`;

const Slider = styled.div<{ width: number; left: number; animate: boolean }>`
  position: absolute;
  height: calc(100% - 8px);
  background-color: white;
  border-radius: 6px;
  width: ${({ width }) => width}px;
  left: ${({ left }) => left}px;
  transition: ${({ animate }) => (animate ? 'all 0.1s ease-in-out' : 'none')};
`;

export const Tabs = <T extends Tab>({ tabs, onChange, selectedTab }: Props<T>) => {
  const [sliderPosition, setSliderPosition] = useState({ width: 0, left: 0 });
  const [animate, setAnimate] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const getSliderPosition = useCallback(() => {
    const selectedIndex = tabs.findIndex((tab) => tab.value === selectedTab);
    if (selectedIndex === -1) return { width: 0, left: 0 };

    const tabElement = document.getElementById(`tab-${selectedTab}`);
    if (!tabElement || !containerRef.current) return { width: 0, left: 0 };

    const { width, left } = tabElement.getBoundingClientRect();
    const containerLeft = containerRef.current.getBoundingClientRect().left;
    return { width, left: left - containerLeft };
  }, [tabs, selectedTab]);

  useEffect(() => {
    const position = getSliderPosition();
    setSliderPosition(position);

    // Enable animations after initial render
    if (!animate) {
      setTimeout(() => setAnimate(true), 0);
    }
  }, [selectedTab, tabs, animate, getSliderPosition]);

  return (
    <TabContainer ref={containerRef}>
      <Slider width={sliderPosition.width} left={sliderPosition.left} animate={animate} />
      {tabs.map((tab) => (
        <TabItem
          id={`tab-${tab.value}`}
          key={tab.value}
          onClick={() => onChange(tab)}
          isSelected={selectedTab === tab.value}
        >
          {tab.label}
        </TabItem>
      ))}
    </TabContainer>
  );
};

/**
 * Utility functions used across the entire dashboard
 */
import fileDownload from 'js-file-download';
import { jsonToCSV } from 'react-papaparse';

/**
 * Helper function that takes in a table data for a particular resource (Workers, Payouts etc)
 * filters it and downloads a CSV file from it.
 *
 * This is a generic function that takes any array data of any type T, as well
 * as a function that maps an object of type T to plain old JSON object.
 *
 * For example, if you have a type Cat, you'd call this function like so:
 *    const catToObject = (cat: Cat) => ({ name: cat.name, breed: cat.breed })
 *    generateAndDownloadCsv<Cat>(myArrayOfCats, catToObject)
 */
export async function generateAndDownloadCsv<T>(data: Array<T>, dataToJson: (d: T) => object) {
  const fileName = 'checkr-pay-data.csv';
  const filteredData = data.map(dataToJson);
  const csvFile = jsonToCSV(filteredData, { escapeFormulae: true });
  fileDownload(csvFile, fileName);
}

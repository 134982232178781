/**
 * Higher-Order component that Absorbs its children, rendering them in a box.
 *
 * When you use the useLoading() hook, it'll look for the nearest LoadingGuard
 * and change the CSS in it such that its components are blurred out and it renders a
 * spinner.
 */
import { LoadingScope } from '@/shared/types';
import { Spinner } from '@checkrx/pay-component-library';
import { ReactNode } from 'react';
import styled from 'styled-components';

// The reactive container that content lives in regardless of screen size
const LoadingWrapperContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 100%;
  position: relative;
  margin: auto;
`;

const LoadingContent = styled.div`
  display: none; // When Loading, display: inline-flex
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;
const LoadingFilter = styled.div`
  height: 100%;
  width: 100%;
  max-width: 100%;
  opacity: 1; // When loading: opacity: 0.2;
  filter: none; // When loading: filter: blur(5px) grayscale(100%);

  // Pass down default PageContainer flex behavior
  display: inline-flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

export const LoadingGuard = ({
  children,
  loadingScope,
}: {
  children: ReactNode;
  loadingScope: LoadingScope;
}) => {
  return (
    <LoadingWrapperContainer>
      <LoadingContent className={`content-loader-${loadingScope}`}>
        <Spinner />
      </LoadingContent>
      <LoadingFilter className={`content-overlay-${loadingScope}`}>{children}</LoadingFilter>
    </LoadingWrapperContainer>
  );
};

/**
 * A Query that allows us to fetch and search a customer account from the backend
 */
import { BankingAccount } from '@/shared/types';
import { useQuery } from '@tanstack/react-query';
import serverApi from './serverApi';

// Refetch our account info every 30 seconds
const REFETCH_AFTER_SECONDS = 30;

async function fetchBankingAccount() {
  // Get Date times between which we'll query. For date selecting, use
  // beginning of the start date, and end of last date (or 'now' if today)
  const response = await serverApi.get('/dashboard/v0/finance/account');
  return response.data.account;
}

export const useBankingAccount = <TData = BankingAccount>(
  select?: (data: BankingAccount) => TData
) => {
  return useQuery({
    queryKey: ['customer', 'customerProfile', 'bankingAccount'],
    refetchInterval: 1000 * REFETCH_AFTER_SECONDS,
    queryFn: () => fetchBankingAccount(),
    select,
  });
};

// Specific selectors for specific fields on the account
// Select just the account balance
const selectBankingAccountBalance = (account: BankingAccount): number => account.balance;
export const useBankingAccountBalance = () => useBankingAccount(selectBankingAccountBalance);

// Select just the Routing and Account Numbers
const selectBankingAccountRoutingNumber = (account: BankingAccount): string =>
  account.routingNumber;
export const useBankingAccountRoutingNumber = () =>
  useBankingAccount(selectBankingAccountRoutingNumber);
const selectBankingAccountNumber = (account: BankingAccount): string => account.accountNumber;
export const useBankingAccountNumber = () => useBankingAccount(selectBankingAccountNumber);

/**
 * Content of the modal that opens when you request to make a User
 */
import { useCreateOneUser } from '@/services/useUsers';
import { ReactComponent as ErrorGraphic } from '@/shared/assets/error-graphic.svg';
import { ReactComponent as SuccessGraphic } from '@/shared/assets/success-graphic.svg';
import { Legalese } from '@/shared/components/BatchUpload.styled';
import { ResultsRow } from '@/shared/components/CreationComponents';
import { CustomerProfile, Permissions } from '@/shared/types';
import { Button, Check, Spinner } from '@checkrx/pay-component-library';
import { AxiosError, isAxiosError } from 'axios';
import { useState } from 'react';

import {
  ButtonRow,
  CenteredErrorText,
  CenteredSuccessText,
  CheckRow,
  ContentWrapper,
  DescriptionText,
  ImgWrapper,
  LabelRow,
  LabelText,
  ValueText,
} from '@/shared/components/ConfirmationModals.styled';
import UserPermissionsSelector from './UserPermissionsSelector';

const successToMessage = (user: CustomerProfile) => {
  return (
    <CenteredSuccessText>
      Successfully created new active user with email {user.email}.
    </CenteredSuccessText>
  );
};
const errorToMessage = (err: Error) => {
  if (isAxiosError(err)) {
    const {
      data: { error_message: errorMessage, details: errorDetails },
    } = err?.response || { data: {} };

    let details = errorDetails;
    if (details?.error) {
      details = details?.error?.details || [];
    }

    return (
      <>
        <CenteredErrorText key="error">{`${errorMessage}`}</CenteredErrorText>
        {(details || []).map(({ message, type }: { message: string; type: string }) => (
          <CenteredErrorText key={type}>{message}</CenteredErrorText>
        ))}
      </>
    );
  } else {
    return <CenteredErrorText>{`${err.message}`}</CenteredErrorText>;
  }
};

interface UserConfirmationModalProps {
  email: string;
  permissions: Permissions;
  // Function to Clear out the Parents' state on success
  clearParentFn: () => void;
}
export default function UserConfirmationModal({
  email,
  permissions,
  clearParentFn,
}: UserConfirmationModalProps) {
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const {
    isLoading: createOneIsLoading,
    isError: createOneIsError,
    isSuccess: createOneIsSuccess,
    error: createOneError,
    data: createOneData,
    mutate: createOneUser,
  } = useCreateOneUser();

  const onSubmitOneUser = async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // Create the user
    createOneUser({
      email,
      permissions,
    });

    // Sleep for a second before clearing the parent's state to avoid weird UX
    await new Promise((r) => setTimeout(r, 1000));

    // Clear the parents' state
    clearParentFn();
  };

  // Errors should show a polite message with our error graphic
  if (createOneIsError) {
    return (
      // We know one of these is non-null if we hit the above conditional
      <ImgWrapper>
        <ErrorGraphic />
        <ResultsRow>
          {errorToMessage(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (createOneError as AxiosError)!
          )}
        </ResultsRow>
      </ImgWrapper>
    );
  }

  // Success should show a hooray graphic with a message about the completion
  if (createOneIsSuccess) {
    return (
      <ImgWrapper>
        <SuccessGraphic />
        <ResultsRow>{successToMessage(createOneData)}</ResultsRow>{' '}
      </ImgWrapper>
    );
  }

  return (
    // Otherwise we're showing the data and asking for confirmation
    <ContentWrapper>
      <DescriptionText>
        Please double check user information below, then confirm that you wish to proceed. Your user
        will then be able to log in with the email provided below
      </DescriptionText>
      <LabelRow>
        <LabelText>User email:</LabelText>
        <ValueText>{email}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Permissions:</LabelText>
      </LabelRow>
      <LabelRow>
        <UserPermissionsSelector
          permissions={permissions}
          setPermissions={() => {
            /* noop */
          }}
        />
      </LabelRow>
      <CheckRow>
        <Check
          checked={hasConfirmed}
          onClick={() => {
            setHasConfirmed(!hasConfirmed);
          }}
        />
        <Legalese>
          I&apos;ve confirmed the information above and authorize Checkr Pay to create this user.
        </Legalese>
      </CheckRow>
      <ButtonRow>
        <Button
          text="Submit"
          sizeVariant="big"
          colorVariant="brand"
          disabled={!hasConfirmed}
          width="155px"
          onClick={onSubmitOneUser}
        />
        {createOneIsLoading ? (
          <Spinner size="30px" />
        ) : (
          // Keep this here for positioning!
          <></>
        )}
      </ButtonRow>
    </ContentWrapper>
  );
}

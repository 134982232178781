import { useState } from 'react';
import styled from 'styled-components';

import { CardTitle, ContentCard } from '@/shared/components/Card';
import { isEmailAddress } from '@/shared/validation';
import { Button, Modal, TextInput } from '@checkrx/pay-component-library';
import UserConfirmationModal from './UserConfirmationModal';

import {
  ControlsCol,
  ControlsRow,
  CreateOneCol,
  ErrorText,
  LabelRow,
  LabelText,
  SubmitButtonBox,
} from '@/shared/components/CreationComponents';
import { Permissions, TabPermissions } from '@/shared/types';
import UserPermissionsSelector from './UserPermissionsSelector';

const CreateUsersCardWrapper = styled(ContentCard)`
  width: 100%;
  min-width: 100%;
  height: fit-content;
  min-height: fit-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`;

const emptyPermissions = {
  usersTab: TabPermissions.none,
  apiTab: TabPermissions.none,
  taxTab: TabPermissions.none,
  settingsTab: TabPermissions.none,
  workersTab: TabPermissions.none,
  payoutsTab: TabPermissions.none,
  financeTab: TabPermissions.none,
  sensitiveAccountData: TabPermissions.none,
  executeRepayments: TabPermissions.none,
};

export default function CreateUsersCard() {
  const [email, setEmail] = useState<string | null>(null);
  // Initialize with no permissions at all
  const [permissions, setPermissions] = useState<Permissions>(emptyPermissions);

  // Track error messages for each input
  const [emailErr, setEmailErr] = useState<string | null>(null);

  // Modal State for User Creation
  const [confirmUserModalOpen, setConfirmUserModalOpen] = useState(false);

  // Clear state if we succeed at creating the resource
  function clear() {
    setEmail(null);
    setPermissions(emptyPermissions);
  }

  const validateInputs = () => {
    let canSubmit = true;

    // Check Email
    if ((email || '').length < 1) {
      canSubmit = false;
      setEmailErr('User email is blank. Please enter user email.');
    } else if (!isEmailAddress(email)) {
      canSubmit = false;
      setEmailErr('User email is invalid. Please enter valid user email.');
    } else {
      setEmailErr(null);
    }
    return canSubmit;
  };

  const onSubmitOneUser = async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const canProceed = validateInputs();
    if (canProceed) {
      // Clear the error messages
      if (email) {
        setConfirmUserModalOpen(true);
      }
    }
  };

  // Listen for "Enter" key throughout the form
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      onSubmitOneUser(event);
    }
  };

  return (
    <CreateUsersCardWrapper onKeyDown={onKeyDown}>
      <Modal
        isOpen={confirmUserModalOpen}
        close={() => {
          setConfirmUserModalOpen(false);
        }}
        headerText="Confirm User Info"
        subHeaderText="Double check this information is correct before you create this user"
        modalContent={
          // NOTE(Carter): Null assertion is fine here because we validate above
          <UserConfirmationModal
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            email={email!}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            permissions={permissions!}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            clearParentFn={clear}
          />
        }
      />
      <CreateOneCol>
        <CardTitle>Add User</CardTitle>
        <ControlsRow>
          <ControlsCol>
            <LabelRow invalid={!!emailErr}>
              <LabelText>Email</LabelText>
              <LabelText>*</LabelText>
            </LabelRow>
            <TextInput
              width={280}
              placeholder="User's Email Address"
              height="60px"
              value={email ?? undefined}
              invalid={!!emailErr}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {emailErr && <ErrorText maxWidth="280px">{emailErr}</ErrorText>}
          </ControlsCol>
        </ControlsRow>
        <ControlsRow>
          <ControlsCol>
            <LabelRow>
              <LabelText>Permissions</LabelText>
            </LabelRow>
            <UserPermissionsSelector permissions={permissions} setPermissions={setPermissions} />
          </ControlsCol>
          <SubmitButtonBox>
            <Button
              text="Submit"
              sizeVariant="big"
              colorVariant="brand"
              width="155px"
              onClick={onSubmitOneUser}
            />
          </SubmitButtonBox>
        </ControlsRow>
      </CreateOneCol>
    </CreateUsersCardWrapper>
  );
}

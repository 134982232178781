import { colors } from '@/shared/styles';
import styled from 'styled-components';

export const DownloadButton = styled.button`
  display: flex;
  width: fit-content;
  height: fit-content;
  gap: 10px;
  align-items: center;

  color: ${colors.accentGreen};
  font-size: 0.875rem;
  line-height: 16px;
  font-weight: 600;
  background: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &:disabled {
    color: ${colors.labelGrey};
    cursor: not-allowed;
  }
`;

import useFeatureToggles, {
  DEFAULT_FEATURE_TOGGLES,
  ToggleKey,
  TOGGLE_KEYS,
} from './feature-toggles';

import { Toggle } from '@checkrx/pay-component-library';
import { createPortal } from 'react-dom';

type Props = {
  show: boolean;
};

export const FeatureToggles = ({ show }: Props) => {
  const { listActiveFeatureToggles, setFeatureToggle, removeFeatureToggle } = useFeatureToggles();

  if (!show) return null;

  const toggles = listActiveFeatureToggles();
  const handleToggleChange = (key: ToggleKey, value: boolean) => setFeatureToggle({ key, value });

  const notUsedInCode = (key: ToggleKey) => !TOGGLE_KEYS.includes(key as ToggleKey);

  return createPortal(
    <div className="flex flex-col fixed top-1/4 left-[calc(50%-200px)] w-[400px] min-h-[400px] bg-primaryWhite border rounded-xl p-3 shadow-lg z-[1000]">
      <div className="w-full text-[22px] text-primaryBlue font-bold mb-4">Toggles</div>
      <div className="flex-1 flex flex-col gap-5 overflow-y-auto scrollbar-thin">
        {Object.entries(toggles).map(([key, value]) => {
          const toggleKey = key as ToggleKey;
          return (
            <div key={toggleKey} className="flex w-full justify-between items-center">
              <span className="text-labelGrey">
                {toggleKey}{' '}
                {notUsedInCode(toggleKey) && (
                  <span className="text-labelGrey text-xs">
                    (not used in code){' '}
                    <span
                      className="text-accentTeal hover:brightness-[80%] cursor-pointer"
                      onClick={() => removeFeatureToggle({ key: toggleKey })}
                    >
                      remove
                    </span>
                  </span>
                )}
              </span>
              <div className="flex items-center gap-4">
                <span className="text-labelGrey text-xs">
                  Default: {DEFAULT_FEATURE_TOGGLES[toggleKey] ? 'On' : 'Off'}
                </span>
                <Toggle
                  isOn={value}
                  onClick={() => handleToggleChange(toggleKey, !value)}
                  disabled={notUsedInCode(toggleKey)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>,
    document.body
  );
};


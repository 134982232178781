import { toast } from '@/app/wrappers/Toaster/Toaster';

export const copyText = (text: string, toastMessage = 'Copied to clipboard') => {
  navigator.clipboard.writeText(text);
  toast({
    type: 'info',
    message: toastMessage,
    duration: 4500,
  });
};

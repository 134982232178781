import { BatchFileStatus } from '@/shared/types';

interface PayoutBatchStatusProps {
  status: BatchFileStatus;
}

export const PayoutBatchStatusText = ({ status }: PayoutBatchStatusProps) => {
  if (!status) {
    return <></>;
  }

  const baseClasses = 'px-2 py-1 rounded-full text-sm font-medium';

  switch (status) {
    case BatchFileStatus.Pending:
      return <p className={`${baseClasses} text-accentOrange`}>Pending</p>;
    case BatchFileStatus.Started:
      return <p className={`${baseClasses} text-accentOrange`}>Started</p>;
    case BatchFileStatus.Success:
      return <p className={`${baseClasses} text-accentGreen`}>Completed</p>;
    case BatchFileStatus.ManualReview:
      return <p className={`${baseClasses} text-accentOrange`}>Manual Review</p>;
    case BatchFileStatus.Error:
      return <p className={`${baseClasses} text-accentRed`}>Error</p>;
    case BatchFileStatus.Cancelled:
      return <p className={`${baseClasses} text-accentRed`}>Cancelled</p>;
    default:
      return <p className={`${baseClasses} text-textGrey`}>Unknown</p>;
  }
};


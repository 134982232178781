import { Icon } from '@checkrx/pay-component-library';
import * as React from 'react';
import { Button, ButtonProps } from './button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './dropdown-menu';

interface MenuOption {
  label: string;
  action: () => void;
  state?: 'enabled' | 'disabled' | 'hidden';
}

type MenuButtonProps = {
  options: MenuOption[];
} & ButtonProps;

const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(
  ({ options, ...props }, ref) => {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button ref={ref} {...props}>
            <Icon name="more-vertical" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {options.map((option, index) => {
            if (option.state === 'hidden') return null;
            return (
              <DropdownMenuItem
                key={index}
                onClick={() => {
                  if (option.state === 'disabled') return;
                  option.action();
                }}
                disabled={option.state === 'disabled'}
              >
                {option.label}
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }
);

MenuButton.displayName = 'MenuButton';

export { MenuButton };
export type { MenuButtonProps, MenuOption };


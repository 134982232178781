/**
 * Container for sidebars
 */
import { colors } from '@/shared/styles';
import styled from 'styled-components';

const SidebarContainer = styled.div`
  display: inline-flex;
  flex-flow: column;
  position: sticky;
  top: 0px;
  background-color: ${colors.primaryWhite};
  width: 240px;
  min-width: 240px;
  height: 100%;
  min-height: 100%;
  border-right: 1px solid ${colors.dividerGrey};
`;

export default SidebarContainer;

import styled from 'styled-components';

export const BetweenWrapper = styled.div`
  display: flex;
  width: 100%;
  min-width: fit-content;
  height: fit-content;
  align-items: start;
  justify-content: space-between;
`;

import { Customer, CustomerProfile, PayoutGroupDefinition } from '@/shared/types';
import { useQuery } from '@tanstack/react-query';
import serverApi from './serverApi';

// Refetch the customer profile every minute (EG to auto-log out)
const REFETCH_AFTER_SECONDS = 60;

async function fetchCustomerProfile() {
  const response = await serverApi.get<CustomerProfile>('/dashboard/v0/profile');
  return response.data;
}

export const useCustomerProfile = <TData = CustomerProfile>(
  select?: (data: CustomerProfile) => TData
) => {
  return useQuery({
    queryKey: ['customer', 'customerProfile'],
    queryFn: () => fetchCustomerProfile(),
    refetchInterval: 1000 * REFETCH_AFTER_SECONDS,
    refetchIntervalInBackground: true, // Refetch in background too
    select,
  });
};

// Define some specific selectors – these will let us select on specific fields
// of the customer profile, without causing renders on extraneous data!
// EG. A component using `useCustomerProfileEmail` will only rerender if the `email`
// field on the customer profile changes!
// NOTE(Carter): Must define this with a stable function name (not an anonymous function)
// or else selector will be different function and will fetch every time!
const selectCustomerProfileEmail = (profile: CustomerProfile): string => profile.email;
export const useCustomerProfileEmail = () => useCustomerProfile(selectCustomerProfileEmail);

const selectCustomerFromProfile = (profile: CustomerProfile): Customer => profile.customer;
export const useCustomer = () => useCustomerProfile(selectCustomerFromProfile);

const selectPermissionsFromProfile = (profile: CustomerProfile) => profile.permissions;
export const useCustomerProfilePermissions = () => useCustomerProfile(selectPermissionsFromProfile);

const selectPayoutGroupDefinitionsFromProfile = (
  profile: CustomerProfile
): PayoutGroupDefinition[] => profile.customer.payoutGroupDefinitions;
export const useCustomerPayoutGroupDefinitions = () =>
  useCustomerProfile(selectPayoutGroupDefinitionsFromProfile);

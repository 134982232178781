/**
 * A mutation that allows us to create deposits
 */
import { useDebouncedMutation } from '@/shared/hooks/useDebouncedMutation';
import { useQueryClient } from '@tanstack/react-query';
import serverApi from './serverApi';

async function postCreateDeposit (amountCents: number, description: string) {
  const response = await serverApi.post('/dashboard/v0/finance/fund_account', {
    amount: amountCents,
    description,
  });

  return response.data;
}

export const useCreateDeposit = () => {
  const queryClient = useQueryClient();

  return useDebouncedMutation({
    mutationFn: ({ amount, description }: {
      amount: number,
      description: string
    }) => postCreateDeposit(amount, description),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer', 'transactions'] });
    },
  });
};

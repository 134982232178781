/**
 * Tooling around the customer's financing
 */

import styled from 'styled-components';

import LedgerTable from './LedgerTable';
import StatementsTable from './StatementsTable';

import { useCustomerProfile, useCustomerProfilePermissions } from '@/services/useCustomerProfile';
import { BalanceOverview } from '@/shared/components/BalanceOverview/BalanceOverview';
import { PageLayout } from '@/shared/components/PageLayout/PageLayout';
import { Tabs } from '@/shared/components/Tabs/Tabs';
import { colors } from '@/shared/styles';
import { TabPermissions } from '@/shared/types';
import { Spinner } from '@checkrx/pay-component-library';
import { useState } from 'react';
import DepositCard from './DepositCard';
import FloatedBalanceCard from './FloatedBalanceCard';

const LoadingFinance = styled.div`
  width: 368px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: ${colors.dividerGrey};
  border-radius: 10px;
  padding-left: 40px;
  cursor: not-allowed;
  font-size: 14px;
  color: ${colors.textGrey};
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  height: fit-content;
  width: 100%;
  min-width: 100%;
`;

type FinanceTabT = 'ledger' | 'statements';

export default function CustomerFinanceTab() {
  const [selectedTab, setSelectedTab] = useState<FinanceTabT>('ledger');
  const { data: permissions } = useCustomerProfilePermissions();
  const { data: customerProfile, isLoading } = useCustomerProfile();
  const hasWritePermissions = permissions?.financeTab === TabPermissions.write;

  const tabs = [
    {
      label: 'Ledger',
      value: 'ledger',
    },
    {
      label: 'Statements',
      value: 'statements',
    },
  ];

  if (isLoading || !customerProfile)
    return (
      <LoadingFinance>
        <div>Loading finance</div> <Spinner size="24px" />
      </LoadingFinance>
    );

  return (
    <PageLayout
      title="Finance"
      headerLeft={
        <>
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            onChange={(t) => setSelectedTab(t.value as 'ledger' | 'statements')}
          />
          <BalanceOverview />
        </>
      }
    >
      <TopRow>
        <FloatedBalanceCard />
        <DepositCard />
      </TopRow>
      {hasWritePermissions ? (
        selectedTab === 'ledger' ? (
          <LedgerTable />
        ) : (
          <StatementsTable />
        )
      ) : (
        <>
          <LedgerTable />
          <StatementsTable />
        </>
      )}
      {/* Extra div at the end for bottom spacing */}
      <div />
    </PageLayout>
  );
}

import clsx from 'clsx';
import { ReactElement, useId } from 'react';
import { GenericSection } from './List';

type Props<T> = {
  item: T;
  index: number;
  sections: GenericSection<T>[][];
  onRowClick?: (row: T, rowIndex: number) => void;
  isLastItem?: boolean;
};

export const ListItem = <T,>({
  sections,
  item,
  index,
  onRowClick = undefined,
  isLastItem = false,
}: Props<T>): JSX.Element => {
  const key = item['id' as keyof T] as string;
  const uniqueId = useId();
  return (
    <div
      onClick={() => onRowClick?.(item, index)}
      key={key ?? undefined}
      className={clsx(
        'flex h-12 justify-between px-6 transition-colors duration-200',
        !isLastItem && 'border-b border-gray-200',
        onRowClick ? 'cursor-pointer hover:bg-gray-50' : 'cursor-default'
      )}
    >
      {sections.map((section, i) => (
        <div key={`${uniqueId}-section-${i}`} className="flex items-center gap-3">
          {section.map((s, index) => {
            if (s?.content) {
              return (
                <div
                  className="overflow-hidden text-ellipsis whitespace-nowrap"
                  style={{ width: s?.width ? `${s.width}px` : 'auto' }}
                  key={`${uniqueId}-section-${i}-${index}`}
                >
                  {s?.content?.(item, index)}
                </div>
              );
            }
            const fieldValue = item[s.id as keyof T];
            return (
              <div
                className="overflow-hidden text-ellipsis whitespace-nowrap"
                style={{ width: s?.width ? `${s.width}px` : 'auto' }}
                key={`${uniqueId}-section-${i}-${index}`}
              >
                {fieldValue as ReactElement}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};


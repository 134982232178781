import { CustomerView } from '@/shared/types';

export const pathToCustomerView = (path: string): CustomerView => {
  switch (path) {
    case '/customer':
      return CustomerView.overview;
    case '/customer/payouts':
      return CustomerView.payouts;
    case '/customer/workers':
      return CustomerView.workers;
    case '/customer/taxes':
      return CustomerView.taxes;
    case '/customer/finance':
      return CustomerView.finance;
    case '/customer/api':
      return CustomerView.api;
    case '/customer/users':
      return CustomerView.users;
    case '/customer/settings':
      return CustomerView.settings;
    case '/customer/logout':
      return CustomerView.logout;
    default:
      return CustomerView.overview;
  }
};

export const customerViewToTitle = (view: CustomerView): string => {
  switch (view) {
    case CustomerView.overview:
      return 'Overview';
    case CustomerView.payouts:
      return 'Payouts';
    case CustomerView.workers:
      return 'Workers';
    case CustomerView.taxes:
      return '1099s';
    case CustomerView.finance:
      return 'Finance';
    case CustomerView.api:
      return 'API Management';
    case CustomerView.users:
      return 'User Management';
    case CustomerView.settings:
      return 'Settings';
    case CustomerView.logout:
      return 'Logout';
    default:
      return 'Overview';
  }
};

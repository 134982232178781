import DatePicker from '@/shared/components/DatePicker';
import { ComponentProps, useState } from 'react';

export interface DateRangePickerProps extends ComponentProps<typeof DatePicker> {
  defaultStartDate?: Date;
  defaultEndDate?: Date;
  onChange: (dates: [Date, Date]) => void;
}

/**
 * A preconfigured DatePicker that only triggers onChange events when both
 * the start and end date have been selected.
 */
export default function DateRangePicker({
  defaultStartDate,
  defaultEndDate,
  onChange,
  ...rest
}: DateRangePickerProps) {
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate || new Date());
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate || new Date());

  return (
    <DatePicker
      {...rest}
      selected={startDate}
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={([start, end]) => {
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
          onChange([start, end]);
        }
      }}
    />
  );
}

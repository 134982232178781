import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const IframeContent = styled.div`
  background-color: #ffffff; // core dash bg color
  height: 1000px; // iframe height
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export default function BGCCustomerOnboardingLayout() {
  return (
    <IframeContent>
      <Outlet />
    </IframeContent>
  );
}

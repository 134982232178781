import { colors } from '@/shared/styles';
import styled from 'styled-components';

export const ErrorContentBox = styled.div`
  flex: 1;
  height: 100%;
  padding: 30px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 30px;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
`;

export const Graphic = styled.img`
  width: 100%;
  max-height: 51%;
`;

export const ErrorTitle = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  line-height: 36px;
  color: ${colors.accentRed};
  margin-top: -75px;
  text-align: center;
`;

export const Text = styled.p`
  font-size: 1rem;
  line-height: 18px;
  font-weight: 400;
  color: ${colors.textGrey};
  text-align: center;
`;

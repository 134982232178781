/**
 * The Customer Workers tab for creating and querying workers
 */

import { useCustomerProfilePermissions } from '@/services/useCustomerProfile';
import { PageLayout } from '@/shared/components/PageLayout/PageLayout';
import { useGetFeatureToggle } from '@/shared/feature-toggles/feature-toggles';
import { TabPermissions } from '@/shared/types';
import { useSearchParams } from 'react-router-dom';
import CreateWorkersCard from './CreateWorkersCard';
import WorkersBatchFileTable from './WorkersBatchFileTable';
import WorkersTable from './WorkersTable';

export default function CustomerWorkersTab() {
  const { data: permissions } = useCustomerProfilePermissions();
  const hasWritePermissions = permissions?.workersTab === TabPermissions.write;

  const [searchParams] = useSearchParams();
  const payWorkerId = searchParams.get('payWorkerId');

  const showNewUi = useGetFeatureToggle('showNewUi');
  if (showNewUi)
    return (
      <PageLayout title="Workers">
        {hasWritePermissions && <CreateWorkersCard />}
        {hasWritePermissions && <WorkersBatchFileTable />}
        <WorkersTable payWorkerId={payWorkerId || undefined} />
        {/* Extra div at the end for bottom spacing */}
        <div />
      </PageLayout>
    );
  return (
    <>
      {hasWritePermissions && <CreateWorkersCard />}
      {hasWritePermissions && <WorkersBatchFileTable />}
      <WorkersTable payWorkerId={payWorkerId || undefined} />
      {/* Extra div at the end for bottom spacing */}
      <div />
    </>
  );
}

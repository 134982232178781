import { FC, ReactNode } from 'react';

type Props = {
  title: string;
  headerRight?: ReactNode;
  headerLeft?: ReactNode;
  children: ReactNode;
};

export const PageLayout: FC<Props> = ({ title, headerRight, headerLeft, children }) => {
  return (
    <div className="flex w-full h-full flex-col">
      <div className="flex items-center justify-between min-h-[60px] bg-primaryWhite border-b px-3 text-primaryBlue text-[32px] font-semibold">
        <div className="flex items-center gap-6">
          <div data-testid="page-title">{title}</div>
          {headerLeft}
        </div>
        {headerRight}
      </div>
      <div className="grow">{children}</div>
    </div>
  );
};


/**
 * Just a placeholder view with some button component testing
 */

export default function CustomerSettingsTab() {
  return (
    <>
      <p>TODO!</p>
      <p>Settings Tab Coming Soon!</p>
      {/* Extra div at the end for bottom spacing */}
      <div />
    </>
  );
}

/**
 * A special fallback for routing errors, which are not caught by
 * our runtime BaseErrorFallback!
 *
 * They share an error page, and most of their functionality.
 * The most important piece of functionality here is 404 not-found
 * for bad or dead links, which will not be caught by our server,
 * but instead by our router!
 */
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import styled from 'styled-components';

import ErrorPage from '@/pages/error-page/ErrorPage';
import NotFoundErrorPage from '@/pages/error-page/NotFoundErrorPage';
import { colors } from '@/shared/styles';

// This is the only render in our entire application that isn't guaraunteed
// to have our top-level style wrappers! Wow!
const AppContainer = styled.div`
  display: inline-block;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: fit-content;

  background-color: ${colors.backgroundGrey};
  overflow-y: auto;
`;

const PageContainer = styled.div`
  // Expand to full height and width of the page
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  // Render as a flex column. Start at the top and horizontally center.
  display: inline-flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

export default function RoutingError() {
  // Routing Errors:
  const routingError = useRouteError();
  if (routingError) {
    if (isRouteErrorResponse(routingError)) {
      if (routingError.status === 404) {
        return (
          <AppContainer>
            <PageContainer>
              <NotFoundErrorPage />
            </PageContainer>
          </AppContainer>
        );
      }

      if (routingError.status === 401) {
        return <div>You aren&apos;t authorized to see this</div>;
      }
    }
  }
  return (
    <AppContainer>
      <PageContainer>
        <ErrorPage />
      </PageContainer>
    </AppContainer>
  );
}

import useWindowSize from '@/shared/hooks/useWindowSize';
import { Icon, Loader } from '@checkrx/pay-component-library';
import clsx from 'clsx';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { DEFAULT_RESULTS_PER_TABLE_PAGE } from '../TableComponents';
import { ListHeader } from './ListHeader';
import { ListItem } from './ListItem';

export type GenericSection<T> = {
  id: string | number;
  title?: ReactNode;
  content?: (row: T, rowIndex: number) => ReactNode;
  width: number;
  minScreenWidth?: number;
  tooltipText?: string;
};

type Props<T> = {
  items: T[];
  sections: GenericSection<T>[][];
  onRowClick?: (row: T, rowIndex: number) => void;
  loading?: boolean;
  emptyStateMessage?: string;
  hasMore?: boolean;
  onLoadMore?: () => void;
};

const List = <T,>({
  items = [],
  sections,
  onRowClick = undefined,
  loading = false,
  emptyStateMessage = 'No items found. Try again or change your filtering.',
  hasMore = false,
  onLoadMore,
}: Props<T>): JSX.Element => {
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const [visibleEndIndex, setVisibleEndIndex] = useState(DEFAULT_RESULTS_PER_TABLE_PAGE);
  const containerRef = useRef<HTMLDivElement>(null);

  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries?.[0]?.isIntersecting && hasMore) {
            onLoadMore?.();
          }
        },
        {
          threshold: 0.5,
        }
      );

      if (node) observer.current.observe(node);
    },
    [loading, hasMore, onLoadMore]
  );

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const { scrollTop, clientHeight } = containerRef.current;
      const rowHeight = 48;
      const buffer = 5;

      const startIndex = Math.max(0, Math.floor(scrollTop / rowHeight) - buffer);
      const visibleRows = Math.ceil(clientHeight / rowHeight) + 2 * buffer;
      const endIndex = Math.min(items.length, startIndex + visibleRows);

      setVisibleStartIndex(startIndex);
      setVisibleEndIndex(endIndex);
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [items.length]);

  const { width: windowWidth } = useWindowSize();

  const getVisibleSections = useCallback(
    (sectionGroup: GenericSection<T>[]) => {
      return sectionGroup.filter(
        (section) => !section.minScreenWidth || windowWidth >= section.minScreenWidth
      );
    },
    [windowWidth]
  );

  if (loading && items.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center h-full gap-5">
        <Loader size="extraLarge" />
        <p className="text-base text-secondary">Hang tight, we&apos;re getting things ready...</p>
      </div>
    );
  }

  if (items.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center h-full text-center gap-5">
        <Icon name="inbox" size="extraLarge" color="disabled" />
        <p className="text-base text-secondary">{emptyStateMessage}</p>
      </div>
    );
  }

  const visibleItems = items.slice(visibleStartIndex, visibleEndIndex);
  const paddingTop = visibleStartIndex * 48;
  const hasTitles = sections.some((section) => section.some((i) => !!i.title));

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-1 overflow-hidden">
        <div className="sticky top-0 w-full z-10 bg-primaryWhite">
          <ListHeader sections={sections.map(getVisibleSections)} />
        </div>
        <div ref={containerRef} className="w-full h-full overflow-y-auto overflow-x-auto">
          <div style={{ paddingTop }} className={clsx('min-w-fit', hasTitles && 'mb-[96px]')}>
            {visibleItems.map((item, index) => {
              const actualIndex = visibleStartIndex + index;
              return (
                <div
                  ref={actualIndex === items.length - 1 ? lastElementRef : undefined}
                  key={`list-item-${actualIndex}`}
                >
                  <ListItem
                    item={item}
                    index={actualIndex}
                    sections={sections.map(getVisibleSections)}
                    onRowClick={onRowClick}
                    isLastItem={actualIndex === items.length - 1}
                  />
                </div>
              );
            })}
          </div>
          {loading && hasMore && (
            <div className="w-full py-4 flex justify-center">
              <Loader size="medium" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default List;


/**
 * Just a placeholder view with some button component testing
 */

export default function AdminCustomersTab() {
  const val = false;
  // Render Time Error!
  if (val) {
    throw new Error('Run time error');
  }
  return (
    <>
      <p>TODO!</p>
      <p>Customer Tab Coming Soon!</p>
    </>
  );
}

/**
 * The Customer Users tab for creating and editing different customer profiles
 */
import { useCustomerProfilePermissions } from '@/services/useCustomerProfile';
import { PageLayout } from '@/shared/components/PageLayout/PageLayout';
import { useGetFeatureToggle } from '@/shared/feature-toggles/feature-toggles';
import { TabPermissions } from '@/shared/types';
import CreateUsersCard from './CreateUsersCard';
import UsersTable from './UsersTable';

export default function CustomerUsersTab() {
  const { data: permissions } = useCustomerProfilePermissions();
  const hasWritePermissions = permissions?.usersTab === TabPermissions.write;

  const showNewUi = useGetFeatureToggle('showNewUi');

  if (showNewUi)
    return (
      <PageLayout title="Users">
        {hasWritePermissions && <CreateUsersCard />}
        <UsersTable />
        {/* Extra div at the end for bottom spacing */}
        <div />
      </PageLayout>
    );

  return (
    <>
      {hasWritePermissions && <CreateUsersCard />}
      <UsersTable />
      {/* Extra div at the end for bottom spacing */}
      <div />
    </>
  );
}

import { useQuery } from '@tanstack/react-query';
import serverApi from './serverApi';

async function fetchPing() {
  const response = await serverApi.get('/');
  return response.data;
}

export const usePingQuery = () =>
  useQuery({
    queryKey: ['ping'],
    queryFn: () => fetchPing(),
  });

import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import debounce from 'p-debounce';

/**
 * A wrapper around useMutation() that adds a debounce to the given mutationFn
 */
export const useDebouncedMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext> & { debounceWaitMs?: number }
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const waitMs = options.debounceWaitMs ?? 200;
  const mutationFn = options.mutationFn
    ? debounce(options.mutationFn, waitMs, {
        before: true, // Call the fn on the leading edge of the timeout instead of waiting waitMs
      })
    : undefined;

  return useMutation({
    ...options,
    mutationFn,
  });
};

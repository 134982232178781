import { Dropdown, TextInput } from '@checkrx/pay-component-library';
import dayjs from 'dayjs';
import { useState } from 'react';

import { toast } from '@/app/wrappers/Toaster/Toaster';
import { parseApiErrorDetails } from '@/services/serverApi';
import { useBatchFileCancel, useBatchFiles } from '@/services/useBatchFiles';
import DateRangePicker from '@/shared/components/DateRangePicker';
import { GenericSection } from '@/shared/components/List/List';
import { ListView } from '@/shared/components/ListView/ListView';
import { NewFeatureAlert } from '@/shared/components/NewFeatureAlert';
import { BatchFile, BatchFileStatus, BatchFileType } from '@/shared/types';
import { PayoutBatchStatusText } from './PayoutBatchStatusText';
import { PayoutsBatchSheet } from './PayoutsBatchSheet';

export default function PayoutsBatchFileTable() {
  const defaultStartDate = dayjs().subtract(1, 'week').startOf('day').toDate();
  const defaultEndDate = dayjs().add(1, 'day').toDate();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [searchString, setSearchString] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<BatchFileStatus | ''>('');
  const [selectedBatchFile, setSelectedBatchFile] = useState<BatchFile>();
  const [showBatchSheet, setShowBatchSheet] = useState(false);

  const {
    data: batchFiles,
    isLoading: batchFilesIsLoading,
    isError: batchFilesIsError,
  } = useBatchFiles(
    startDate,
    endDate,
    BatchFileType.Payout,
    selectedStatus !== '' ? selectedStatus : undefined
  );

  const { mutateAsync: cancelBatchFile } = useBatchFileCancel();

  const handleCancelBatchFile = async (batchFileId: string) => {
    try {
      await cancelBatchFile({ batchFileId });
      toast({
        message: 'Batch file cancelled',
        type: 'success',
        duration: 5000,
      });
    } catch (err) {
      let message = 'Failed to cancel batch file';
      if (parseApiErrorDetails(err) === 'invalid_status') {
        message = 'This batch file has already been processed and cannot be cancelled';
      }
      toast({
        message,
        type: 'error',
        duration: 5000,
      });
    }
  };

  const sections: GenericSection<BatchFile>[][] = [
    [
      {
        id: 'fileName',
        title: 'File Name',
        content: (row) => row.inputFileName,
        width: 200,
      },
      {
        id: 'dateUploaded',
        title: 'Date Uploaded',
        content: (row) => dayjs(row.createdAt).format('D MMM YYYY, h:mma'),
        width: 150,
      },
      {
        id: 'scheduledFor',
        title: 'Scheduled For',
        content: (row) => dayjs(row.runAt || row.createdAt).format('D MMM YYYY, h:mma'),
        width: 150,
      },
      {
        id: 'status',
        title: 'Status',
        content: (row) => <PayoutBatchStatusText status={row.status} />,
        width: 100,
      },
      {
        id: 'uploader',
        title: 'Uploader',
        content: (row) => row.customerProfile?.email || 'Admin',
        width: 150,
      },
      {
        id: 'successCount',
        title: '# Success',
        content: (row) => row.successCount,
        width: 100,
      },
      {
        id: 'failureCount',
        title: '# Error',
        content: (row) => row.failureCount,
        width: 100,
      },
    ],
  ];

  return (
    <>
      <div className="flex flex-col gap-4 bg-primaryWhite p-4 h-full">
        <NewFeatureAlert>
          New Batch Payouts Table: Explore the new table by clicking on a row to view actions and
          more information about the batch payout.
        </NewFeatureAlert>
        <ListView
          sections={sections}
          data={batchFiles || []}
          loading={batchFilesIsLoading}
          error={batchFilesIsError}
          onRowClick={(row) => {
            setSelectedBatchFile(row);
            setShowBatchSheet(true);
          }}
          header={
            <div className="flex w-full min-w-fit h-fit items-start justify-between">
              <div className="flex flex-row items-center justify-start w-fit gap-5">
                <TextInput
                  value={searchString}
                  width={400}
                  placeholder="Filter by search term"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchString(e.target.value || '');
                  }}
                />
                <DateRangePicker
                  defaultStartDate={defaultStartDate}
                  defaultEndDate={defaultEndDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                  }}
                />
                <Dropdown
                  options={[
                    { label: 'Any Status', value: '' },
                    { label: 'Pending', value: BatchFileStatus.Pending },
                    { label: 'Started', value: BatchFileStatus.Started },
                    { label: 'Completed', value: BatchFileStatus.Success },
                    { label: 'Manual Review', value: BatchFileStatus.ManualReview },
                    { label: 'Error', value: BatchFileStatus.Error },
                    { label: 'Cancelled', value: BatchFileStatus.Cancelled },
                  ]}
                  closeOnOutsideClick
                  onSelect={(opt) => {
                    setSelectedStatus(opt.value as BatchFileStatus);
                  }}
                />
              </div>
            </div>
          }
        />
      </div>
      <PayoutsBatchSheet
        batchFile={selectedBatchFile}
        open={showBatchSheet}
        onOpenChange={setShowBatchSheet}
        onCancelBatchFile={handleCancelBatchFile}
      />
    </>
  );
}


import { Button } from '@checkrx/pay-component-library';
import styled from 'styled-components';
import { colors } from '../styles';

export const PaginationRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 5px;
`;

export const PaginationButton = styled(Button)<{ active?: boolean }>`
  border: ${(p) =>
    p.active ? `1px solid ${colors.primaryBlue}` : `1px solid ${colors.dividerGrey}`};
`;

interface TablePaginationProps {
  curPageNumber: number;
  lastPageNumber: number;
  setCurPageNumber: (page: number) => void;
}

export function TablePagination({
  curPageNumber,
  lastPageNumber,
  setCurPageNumber,
}: TablePaginationProps) {
  return (
    <PaginationRow>
      <PaginationButton
        text="<"
        width="35px"
        colorVariant="light"
        disabled={curPageNumber === 0}
        onClick={() => {
          setCurPageNumber(curPageNumber - 1);
        }}
      />
      {/* Always show the first page number button */}
      <PaginationButton
        text="1"
        width="35px"
        colorVariant="light"
        active={curPageNumber === 0}
        onClick={() => {
          setCurPageNumber(0);
        }}
      />
      {/* Add a magic ... button to the left if we are more than 2 away from the beginning */}
      {curPageNumber > 1 && (
        <PaginationButton text="..." width="35px" colorVariant="light" active={false} />
      )}
      {/* Add a button for the current page if we're >0 and < lastPage */}
      {curPageNumber > 0 && curPageNumber < lastPageNumber && (
        <PaginationButton
          text={`${curPageNumber + 1}`}
          width="35px"
          colorVariant="light"
          active={true}
        />
      )}
      {/* Add a magic ... button to the right if we are more than 2 away from the end */}
      {lastPageNumber - curPageNumber > 1 && (
        <PaginationButton text="..." width="35px" colorVariant="light" active={false} />
      )}
      {/* Always show the last page number button */}
      <PaginationButton
        text={`${lastPageNumber + 1}`}
        width="35px"
        colorVariant="light"
        active={curPageNumber >= lastPageNumber}
        disabled={curPageNumber >= lastPageNumber}
        onClick={() => {
          setCurPageNumber(lastPageNumber);
        }}
      />
      <PaginationButton
        text=">"
        width="35px"
        colorVariant="light"
        disabled={curPageNumber >= lastPageNumber}
        onClick={() => {
          setCurPageNumber(curPageNumber + 1);
        }}
      />
    </PaginationRow>
  );
}

/**
 * A very rudimentary Explainer Circle component for adding a circle
 * with some explanatory text.
 */
import { ReactComponent as QuestionCircle } from '@/shared/assets/question-circle.svg';
import { Tooltip } from '@checkrx/pay-component-library';
import styled from 'styled-components';

const ExplainerText = styled.p`
  padding: 5px;
  // TODO(Carter): Style the text itself

  // Note: The styles below are all required to text to play nice
  // with multiline tooltips.
  height: fit-content;
  width: 100%;
  max-width: 100%;
  height: 100%;
  word-wrap: break-word;
  white-space: normal;
`;

export default function ExplainerCircle({
  text,
  direction,
}: // TODO(Carter): Accept Width dynamically
{
  text: string;
  direction: 'left' | 'right';
}) {
  return (
    <Tooltip content={<ExplainerText>{text}</ExplainerText>} direction={direction} width="200px">
      <QuestionCircle />
    </Tooltip>
  );
}

/**
 * A Query that allows us to fetch and search workers from the backend
 *
 * When we query workers, we can pass in a few filtering params and
 * a search string.
 */
import { Worker, WorkerStatus } from '@/shared/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import serverApi from './serverApi';

// Refetch our worker list every Minute
const REFETCH_AFTER_SECONDS = 60;

async function fetchWorkers(
  status: WorkerStatus | null,
  workerId: string,
  email: string,
  legalName: string
) {
  const response = await serverApi.get('/dashboard/v0/workers', {
    params: {
      // Maybe pass in query params. Or don't. I'm not your supervisor.
      status: status ? status : undefined,
      workerId: workerId.length > 0 ? workerId : undefined,
      email: email.length > 0 ? email : undefined,
      legalName: legalName.length > 0 ? legalName : undefined,
    },
  });
  return response.data;
}

export const useWorkers = (
  status: WorkerStatus | null = null,
  workerId = '',
  email = '',
  legalName = '',
  searchString = ''
) => {
  return useQuery({
    queryKey: ['customer', 'workers', status, workerId, email, legalName],
    queryFn: () => fetchWorkers(status, workerId, email, legalName),
    refetchInterval: 1000 * REFETCH_AFTER_SECONDS,
    // Implement semi-fuzzy word search on the front end only.
    select: (data: { workers: Array<Worker> }): Array<Worker> => {
      const { workers } = data;
      if (searchString.length === 0) {
        return workers;
      }

      const searchWords = searchString.toLowerCase().split(' ');

      return workers.filter((worker: Worker) => {
        const serialized = JSON.stringify(worker).toLowerCase();
        return searchWords.some((word) => serialized.includes(word));
      });
    },
  });
};

// TODO(Carter): We can extend this fetch/query later to accept email / name / metadata
async function fetchOneWorker(workerId: string) {
  const response = await serverApi.get(`/dashboard/v0/workers/${workerId}`);
  return response.data.worker;
}

export const useOneWorker = (workerId: string) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['customer', 'worker', workerId],
    queryFn: () => fetchOneWorker(workerId),
    // Catch these errors and handle in-modal ourselves
    // Seed the cache with whatever's returned by the query
    initialData: () => {
      // Find the worker with the right id in the workers cache
      // TODO(Carter): There appears to be no good way to type this line!
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const cache = queryClient.getQueryData<any>(['customer', 'workers']);
      return cache ? cache.find((worker: Worker) => worker?.id === workerId) : undefined;
    },
    // Get the last fetch time of the list to account for staleness!
    initialDataUpdatedAt: () => queryClient.getQueryState(['customer', 'workers'])?.dataUpdatedAt,
  });
};
async function postCreateOneWorker(
  email: string,
  phoneNumber: string,
  candidateId?: string,
  firstName?: string,
  lastName?: string,
  metadata?: string
) {
  const response = await serverApi.post('/dashboard/v0/workers', {
    backgroundCheckId: candidateId || ''.length >= 1 ? candidateId : undefined,
    metadata,
    profile: {
      email,
      phoneNumber,
      firstName: (firstName || '').length >= 1 ? firstName : undefined,
      lastName: (lastName || '').length >= 1 ? lastName : undefined,
    },
  });
  return response.data;
}

export const useCreateOneWorker = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      candidateId,
      firstName,
      lastName,
      email,
      phoneNumber,
      metadata,
    }: {
      candidateId?: string;
      firstName?: string;
      lastName?: string;
      email: string;
      phoneNumber: string;
      metadata?: string;
    }) => postCreateOneWorker(email, phoneNumber, candidateId, firstName, lastName, metadata),
    // Catch 400s and 404s explicitly
    onSuccess: () => {
      // Clear all worker queries
      return queryClient.invalidateQueries({
        queryKey: ['customer', 'workers'],
      });
    },
  });
};

const postEnableWorkerDirectDeposit = async (workerProfileId: Worker['id']) => {
  const response = await serverApi.post(
    `/dashboard/v0/workers/${workerProfileId}/enable_direct_deposit`
  );
  return response.data;
};

export const useEnableWorkerDirectDeposit = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ workerProfileId }: { workerProfileId: Worker['id'] }) =>
      postEnableWorkerDirectDeposit(workerProfileId),
    onSuccess: () => {
      // Clear all worker queries
      return queryClient.invalidateQueries({
        queryKey: ['customer', 'workers'],
      });
    },
  });
};

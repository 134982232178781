/**
 * Just a placeholder view with some button component testing
 */

export default function AdminLogoutTab() {
  return (
    <>
      <p>Customer Logout Coming Soon!</p>
    </>
  );
}

import { useBankingAccount } from '@/services/useBankingAccount';
import { colors } from '@/shared/styles';
import { formatCentsAsDollars } from '@/shared/utils/formatters';
import { Icon } from '@checkrx/pay-component-library';
import styled from 'styled-components';
import Tooltip from '../Tooltip/Tooltip';

const BalanceOverviewContainer = styled.div`
  height: 60px;
  padding: 6px;
  display: flex;
  gap: 16px;
`;

const BalanceColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BalanceRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const BalanceText = styled.div`
  color: ${colors.primaryBlue};
  font-weight: 700;
  font-size: 18px;
`;

const TitleText = styled.div`
  color: ${colors.labelGrey};
  font-weight: 400;
  font-size: 16px;
`;

const OtherBalance = styled.div`
  color: ${colors.primaryBlue};
  font-weight: 500;
  font-size: 14px;
`;

const OtherTitle = styled.div`
  color: ${colors.textGrey};
  font-weight: 400;
  font-size: 14px;
`;

const TooltipWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TooltipText = styled.div``;

export const BalanceOverview = () => {
  const { data: account, isLoading } = useBankingAccount();
  if (isLoading || !account) return <BalanceOverviewContainer />;

  const stringifiedBalance = formatCentsAsDollars(account.balance);
  const stringifiedPending = formatCentsAsDollars(account.pending);
  const stringifiedAvailable = formatCentsAsDollars(account.balance - account.pending);

  return (
    <BalanceOverviewContainer>
      <BalanceColumn>
        <TooltipWrapper>
          <TitleText>Available Balance</TitleText>
          <Tooltip
            offset={{ vertical: 4 }}
            content={
              <TooltipContent>
                <TooltipText>The total amount you have to issue new payouts.</TooltipText>
              </TooltipContent>
            }
            align="bottom"
          >
            <Icon name="help-circle" color="primary" />
          </Tooltip>
        </TooltipWrapper>
        <BalanceText>{stringifiedAvailable}</BalanceText>
      </BalanceColumn>
      <BalanceColumn>
        <BalanceRow>
          <TooltipWrapper>
            <TitleText>Current Balance</TitleText>
            <Tooltip
              offset={{ vertical: 4 }}
              content={
                <TooltipContent>
                  <TooltipText>
                    Checkr Pay bank account balance, including pending payouts total.
                  </TooltipText>
                </TooltipContent>
              }
              align="bottom"
            >
              <Icon name="help-circle" color="primary" />
            </Tooltip>
          </TooltipWrapper>
          <OtherBalance>{stringifiedBalance}</OtherBalance>
        </BalanceRow>
        <BalanceRow>
          <TooltipWrapper>
            <OtherTitle>Pending Payouts </OtherTitle>
            <Tooltip
              offset={{ vertical: 4 }}
              content={
                <TooltipContent>
                  <TooltipText>
                    Pending Payouts represent the total amount you&apos;ve already issued but that
                    has not yet been processed or fulfilled.
                  </TooltipText>
                </TooltipContent>
              }
              align="bottom"
            >
              <Icon name="help-circle" color="primary" />
            </Tooltip>
          </TooltipWrapper>
          <OtherBalance>{stringifiedPending}</OtherBalance>
        </BalanceRow>
      </BalanceColumn>
    </BalanceOverviewContainer>
  );
};

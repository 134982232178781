import styled from 'styled-components';
import { colors } from '../styles';

// By default, a card is a centered column flex box!
// This can be overridden if you want to use a row or
// position content differently!
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.primaryWhite};
  border-radius: 10px;
  padding: 30px;
`;

// Content Cards are aligned for starting at the beginning
export const ContentCard = styled(Card)`
  align-items: start;
  justify-content: start;
  padding: 20px;
`;

export const CardTitle = styled.h2`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 28px;
  color: ${colors.primaryBlue};
`;

export default Card;

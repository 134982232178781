import { Button, Spinner } from '@checkrx/pay-component-library';

import { toast } from '@/app/wrappers/Toaster/Toaster';
import { useEnableWorkerDirectDeposit } from '@/services/useWorkers';
import { ContentWrapper, DescriptionText } from '@/shared/components/ConfirmationModals.styled';
import { Worker } from '@/shared/types';
import styled from 'styled-components';

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 24px;
  margin-top: 16px;
`;

type ConfirmDirectDepositModalProps = {
  worker: Worker;
  onClose: () => void;
};

const ConfirmDirectDepositModal = ({ worker, onClose }: ConfirmDirectDepositModalProps) => {
  const { mutateAsync: offerDirectDeposit, isLoading } = useEnableWorkerDirectDeposit();

  const handleOfferDirectDeposit = async () => {
    try {
      await offerDirectDeposit({ workerProfileId: worker.id });
      onClose();
      toast({
        message: `Offered Direct Deposit for ${worker.profile?.legalName}`,
        type: 'success',
        duration: 5000,
      });
    } catch (err) {
      toast({
        message: `Failed to offer direct deposit for ${worker.profile?.legalName}`,
        type: 'error',
        duration: 5000,
      });
    }
  };

  return (
    <ContentWrapper>
      <DescriptionText>
        You are offering Direct Deposit for {worker.profile?.legalName}. Please confirm or cancel.
      </DescriptionText>
      <ButtonRow>
        <Button text="Cancel" sizeVariant="big" width="155px" onClick={() => onClose()} />
        <Button
          text="Offer"
          sizeVariant="big"
          colorVariant="brand"
          width="155px"
          onClick={handleOfferDirectDeposit}
        />
        {isLoading && <Spinner size="30px" />}
      </ButtonRow>
    </ContentWrapper>
  );
};

export default ConfirmDirectDepositModal;

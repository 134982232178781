import { GenericSection } from './List';
import { TooltipLabel} from '@/components/ui/tooltip';

type Props<T> = {
  sections: GenericSection<T>[][];
};


export const ListHeader = <T,>({ sections }: Props<T>): JSX.Element => {
  const hasTitles = sections.some((section) => section.some((i) => !!i.title));
  if (!hasTitles) return <div />;
  return (
    <div className="flex h-12 border-b border-dividerGrey px-6 justify-between bg-primaryWhite">
      {sections?.map((section, i) => (
        <div key={`header-${i}`} className="flex items-center gap-3">
          {section?.map((s) => (
            <div
              key={`header-${s.id}`}
              className="flex items-center font-medium text-labelGrey"
              style={{ minWidth: `${s.width}px` }}
            >
              {s.tooltipText ? (
                <TooltipLabel label={s.title} tooltipText={s.tooltipText}/>
              ) : <span>{s.title}</span>}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

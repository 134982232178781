import { colors } from '@/shared/styles';
import styled from 'styled-components';
// Set up a top-level container for our entire page
export const AppContainer = styled.div`
  position: relative;
  display: inline-block;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: fit-content;

  background-color: ${colors.backgroundGrey};
`;

export const PageContainer = styled.div`
  // Expand to full height and width of the page
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;

  // Render as a flex column. Start at the top and horizontally center.
  display: inline-flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  overflow-y: scroll;
`;

/**
 * A Query that allows us to fetch and search transactions from the backend
 *
 * When we query transactions, we can pass in a few filtering params and
 * a search string.
 */
import { Transaction, TransactionStatus, TransactionType } from '@/shared/types';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import serverApi from './serverApi';
dayjs.extend(utc);

export type TransactionDirection = 'Credit' | 'Debit' | 'Both';

export async function fetchTransactions(startDate: Date, endDate: Date, perPage?: number) {
  // Get Date times between which we'll query. For date selecting, use
  // beginning of the start date, and end of last date (or 'now' if today)
  let start = dayjs.utc(startDate).startOf('day');
  let end = dayjs.utc(endDate).endOf('day');
  // If we're looking at today, make sure it is using the correct time
  if (end.isAfter(dayjs.utc())) {
    end = dayjs.utc();
  }

  // If there's less than 24 hours between start and end date (Due to UTC),
  // Set the start date to 24 hours before the end date.
  if (end.diff(start, 'hour') < 24) {
    start = end.subtract(24, 'hour');
  }

  const response = await serverApi.get<{ transactions: Transaction[] }>(
    '/dashboard/v0/finance/transactions',
    {
      params: {
        startDate: start.toDate(),
        endDate: end.toDate(),
        ...(perPage ? { perPage } : {}),
      },
    }
  );
  return response.data;
}

export const useTransactions = (
  startDate: Date,
  endDate: Date,
  type: TransactionType | null,
  searchString: string,
  direction: TransactionDirection,
  selectedTransactionStatus: TransactionStatus | null
) => {
  return useQuery({
    queryKey: ['customer', 'transactions', startDate, endDate],
    queryFn: () => fetchTransactions(startDate, endDate),
    // Implement semi-fuzzy word search on the front end only.
    // AKA, whatever the searchString is, if any part of the TXN matches
    // one of the words in the searchString, include it!
    select: (data: { transactions: Array<Transaction> }): Array<Transaction> => {
      let { transactions } = data;
      // sort in place by date (descending – RECENT FIRST!)
      transactions.sort(
        (a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      );

      // If we passed in a type, filter all transactions by type
      if (type) {
        transactions = transactions.filter((t) => t.transactionType === type);
      }

      if (selectedTransactionStatus) {
        transactions = transactions.filter((transaction) => {
          // Use a broader definition of 'Pending' to show more pending like statuses
          if (selectedTransactionStatus === TransactionStatus.Pending) {
            return [
              TransactionStatus.Authorized,
              TransactionStatus.Pending,
              TransactionStatus.PendingReview,
              TransactionStatus.Clearing,
            ].includes(transaction.transactionStatus);
          } else {
            return transaction.transactionStatus === selectedTransactionStatus;
          }
        });
      }

      if (direction !== 'Both') {
        transactions = transactions.filter((t) => t.direction === direction);
      }

      // If we passed in a search string, use it.
      if (searchString.length === 0) {
        return transactions;
      }

      const searchWords = searchString.toLowerCase().split(' ');

      return transactions.filter((txn: Transaction) => {
        const serialized = JSON.stringify(txn).toLowerCase();
        return searchWords.some((word) => serialized.includes(word));
      });
    },
  });
};

import { toast } from '@/app/wrappers/Toaster/Toaster';
import { Payout, TaxesStatus, Worker } from '@/shared/types';
import { TaxYears, WorkerWithEarnings } from './taxes.types';

export const TAX_YEARS = [2024, 2023] as const;

export const taxesStatusRenderMap: Record<
  TaxesStatus | 'INELIGIBLE' | 'NEEDS_ATTENTION' | 'MANUALLY_EXCLUDED',
  JSX.Element
> = {
  INELIGIBLE: <span className="text-yellow-500 font-medium">Ineligible</span>,
  NEEDS_ATTENTION: <span className="text-orange-500 font-medium">Ineligible</span>,
  NOT_STARTED: <span className="text-orange-500 font-medium">Not started</span>,
  CREATED: <span className="text-black font-medium">Created</span>,
  FILED: <span className="text-teal-500 font-medium">Filed</span>,
  ACCEPTED: <span className="text-green-500 font-medium">Accepted</span>,
  REJECTED: <span className="text-red-500 font-medium">Rejected</span>,
  QUEUED: <span className="text-black font-medium">Queued</span>,
  FAILED: <span className="text-red-500 font-medium">Failed</span>,
  MANUALLY_EXCLUDED: <span className="text-yellow-500 font-medium">Manually excluded</span>,
};

export const viewTaxPdf = (pdfUrl: string) => {
  toast({
    message: 'Opening 1099-NEC PDF in a new tab',
    type: 'info',
    duration: 5000,
  });
  const link = document.createElement('a');
  link.href = pdfUrl ?? '';
  window.open(link.href);
};

export const getWorkersWithPayoutTotals = (
  workers: Worker[],
  payouts: Payout[],
  year: TaxYears
): WorkerWithEarnings[] => {
  return workers
    ?.map((worker) => {
      const workerPayoutsMap = new Map();
      payouts?.forEach((payout) => {
        if (payout.workerId === worker.id && payout.status === 'completed') {
          workerPayoutsMap.set(payout.id, payout.amountCents);
        }
      });

      const earnedTotal = Array.from(workerPayoutsMap.values()).reduce(
        (sum, amount) => sum + amount,
        0
      );

      const externalEarnings = worker?.['1099nec']?.[year]?.nonPlatformIncome ?? 0;
      const overrideTotalEarnings = worker?.['1099nec']?.[year]?.overrideTotalEarningsAmount;
      const totalEarnings = overrideTotalEarnings || earnedTotal + externalEarnings;

      return {
        ...worker,
        earnedCentsTotal: earnedTotal,
        externalEarningsTotal: externalEarnings,
        totalEarnings,
        overrideTotalEarningsAmount: overrideTotalEarnings ?? 0,
      };
    })
    ?.sort((a, b) => {
      const statusPriorityMap = new Map([
        ['QUEUED', 3],
        ['CREATED', 4],
        ['FILED', 5],
        ['ACCEPTED', 6],
      ]);

      const getStatusPriority = (worker: WorkerWithEarnings) => {
        if (worker?.aboundTinVerification?.tinVerificationStatus !== 'MATCH') return 0;
        if (worker.totalEarnings < 600_00) return 3;
        const status = worker?.['1099nec']?.[year]?.status;
        if (!status) return 2;
        return statusPriorityMap.get(status) ?? 7;
      };

      return getStatusPriority(a) - getStatusPriority(b);
    });
};


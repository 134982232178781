import axios, { isAxiosError } from 'axios';
const BASE_URL = process.env.REACT_APP_CHECKR_PAY_SERVER_URL ?? 'http://localhost:5050/';

const serverApi = axios.create({
  baseURL: BASE_URL,
  //   withCredentials: true,
});
serverApi.defaults.headers.common['Content-Type'] = `application/json`;

serverApi.interceptors.request.use((config) => {
  // Add token to request headers at time or request
  config.headers['X-CHECKR-CUSTOMER-SESSION-TOKEN'] = window.localStorage.getItem('token');
  return config;
});

serverApi.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      window.localStorage.removeItem('token');
      window.location.replace(`${window.location.origin}/customer/logout`);
    }
    return Promise.reject(error);
  }
);

export default serverApi;

/**
 * Find the first value in an API error's `details` array
 */
export function parseApiErrorDetails(err: unknown) {
  if (isAxiosError<{ details?: string[] }>(err)) {
    return err.response?.data.details?.[0];
  }
}

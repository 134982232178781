/**
 * Higher-Order component that checks if current customer profile
 * has the required
 *
 * TODO(Carter):
 * - Decide if we want to update any time any data updates or only certain pieces
 */
import { useCustomerProfilePermissions } from '@/services/useCustomerProfile';
import useLoading from '@/shared/hooks/useLoading';
import { LoadingScope, Permissions, TabPermissions } from '@/shared/types';
import { ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export const PermissionGuard = ({
  children,
  permissionRequired,
  redirect = '/',
}: {
  children: ReactNode;
  permissionRequired: Array<{ tab: keyof Permissions; permissionRole: TabPermissions }>;
  redirect?: string;
}) => {
  const { data: permissions, isLoading, isSuccess } = useCustomerProfilePermissions();
  const setLoading = useLoading(isLoading, LoadingScope.customerDashboard);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  // Check if this user has permissions required
  if (isSuccess) {
    const hasRequiredPermissions = permissionRequired.every(({ tab, permissionRole }) => {
      const actualRole = (permissions ?? {})[tab] || TabPermissions.none;
      switch (permissionRole) {
        case TabPermissions.write:
          return actualRole === TabPermissions.write;
        case TabPermissions.read:
          return [TabPermissions.write, TabPermissions.read].includes(actualRole);
        default:
          return true;
      }
    });

    if (!hasRequiredPermissions) {
      return <Navigate to={redirect} />;
    }
  }

  return <>{children}</>;
};

/**
 * Just a placeholder view with some button component testing
 */
import { useAuth } from '@/services/useAuth';
import { Spinner } from '@checkrx/pay-component-library';
import { useEffect } from 'react';

export default function CustomerLogoutTab() {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  return <Spinner size="100%" />;
}

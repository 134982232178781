/**
 * Our landing page just redirects to the /customer page.
 *
 * The customer dashboard is auth guarded. If the user isn't logged in,
 * they'll be redirected to login!
 */
import { Navigate } from 'react-router-dom';

export default function LandingPage() {
  return <Navigate to="/customer" />;
}

import { clsx } from 'clsx';
import { WorkerWithEarnings } from '../taxes.types';
import { TAX_YEARS } from '../utils';
import { TaxWizardStep } from './TaxWizard';
import { taxWizardColorMap } from './utils';

type Props = {
  workers: WorkerWithEarnings[];
  workersReadyToGenerate: WorkerWithEarnings[];
  workersReadyToFile: WorkerWithEarnings[];
  setCurrentStep: (step: TaxWizardStep) => void;
};

export function StartTaxesStep({
  workersReadyToGenerate,
  workersReadyToFile,
  workers,
  setCurrentStep,
}: Props) {
  const sections = [
    {
      title: 'Update worker earnings',
      subtitle: 'View and update earnings for workers',
      count: workers.length,
      accentColor: taxWizardColorMap.excluded,
      step: 'earnings' as const,
      disabled: false,
      description: 'View and update earnings for workers to ensure accurate tax filing.',
      countDescription: 'to add external earnings',
    },
    {
      title: 'Generate 1099-NECs',
      subtitle: 'Create tax forms for eligible workers',
      count: workersReadyToGenerate.length,
      accentColor: taxWizardColorMap.readyToGenerate,
      step: 'generate' as const,
      disabled: workersReadyToGenerate.length === 0,
      description:
        'Generate 1099-NEC forms for workers who earned $600 or more and have valid TINs.',
      countDescription: 'to generate 1099-NECs',
    },
    {
      title: 'File with IRS',
      subtitle: 'Submit generated 1099-NECs',
      count: workersReadyToFile.length,
      accentColor: taxWizardColorMap.readyToFile,
      step: 'file' as const,
      disabled: workersReadyToFile.length === 0,
      description: 'Submit previously generated 1099-NEC forms to the IRS.',
      countDescription: 'to file 1099-NECs',
    },
  ];

  return (
    <div className="space-y-6">
      <div className="text-center space-y-2">
        <h2 className="text-2xl font-semibold">Let&apos;s Get Started with Tax Filing</h2>
        <p className="text-muted-foreground">
          We&apos;ll help you generate and file 1099-NECs for your eligible workers for{' '}
          {TAX_YEARS[0]}
        </p>
        <p className="text-muted-foreground">Choose an action to begin your tax filing process</p>
      </div>

      <div className="grid gap-4">
        {sections.map((section, index) => (
          <div
            key={section.title}
            className={clsx(
              'p-4 border rounded-lg transition-colors cursor-pointer',
              section.disabled ? 'opacity-50 !cursor-not-allowed' : 'hover:border-primary/50'
            )}
            onClick={() => !section.disabled && setCurrentStep(section.step)}
          >
            <div>
              <div className="flex items-center gap-2">
                <span className="flex items-center justify-center w-fit px-2 h-6 rounded-full bg-primary/10 text-primary/70 text-sm font-medium">
                  Step {index + 1}
                </span>
                <h3 className="font-medium">{section.title}</h3>
              </div>
              <p className="text-sm text-muted-foreground mt-1">{section.subtitle}</p>
              <p className="mt-2 text-sm">{section.description}</p>
              <p className="mt-1 text-sm font-medium" style={{ color: section.accentColor }}>
                {section.count} {section.count === 1 ? 'worker' : 'workers'} available{' '}
                {section.countDescription}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="text-md text-muted-foreground text-center">
        <p>
          Not sure where to start? Click Next to follow our guided flow through the tax process.
        </p>
      </div>
    </div>
  );
}


import { ConfirmationRow } from '@/shared/components/BatchUpload.styled';
import { ErrorText, SuccessText } from '@/shared/components/CreationComponents';
import { colors } from '@/shared/styles';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  padding: 15px 25px 5px 15px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: fit-content;
  min-height: 100%;
`;
export const ImgWrapper = styled.div`
  padding: 15px 25px 5px 15px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  min-height: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  width: 100%;
`;

export const LabelText = styled.p`
  width: 30%;
  font-size: 1rem;
  font-weight: 600;
  color: inherit;
  line-height: 18px;
  color: ${colors.primaryBlue};
`;

export const ValueText = styled.p`
  width: 70%;
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.trueBlack};
  line-height: 18px;
`;

export const DescriptionText = styled.p`
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.textGrey};
  line-height: 24px;
  margin-bottom: 20px;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

export const CheckRow = styled(ConfirmationRow)`
  margin-top: 15px;
  margin-bottom: 0px;
`;

export const CenteredErrorText = styled(ErrorText)`
  text-align: center;
`;
export const CenteredSuccessText = styled(SuccessText)`
  text-align: center;
`;

import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import styled from 'styled-components';
import { colors } from '../styles';

import 'react-datepicker/dist/react-datepicker.css';

const Overrides = styled.div`
  .react-datepicker__input-time-container {
    float: none;
    margin: 0;
    padding: 5px 10px;
    border-top: 1px solid #aeaeae; /* Match the default colors used by react-datepicker */
    background: #f0f0f0;
  }

  .react-datepicker-time__caption {
    font-size: 0.875rem;
    font-weight: 700;
  }

  /* There are two uses of this input class, one on a wrapping div and another for the input.
  Crazy specificity is needed to override the library's style definition */
  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    div.react-datepicker-time__input {
    margin-left: 0;
  }

  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    input.react-datepicker-time__input {
    min-width: 120px;
    font-size: 0.875rem;
    padding: 5px;
    border: 1px solid #aeaeae;
    border-radius: 4px;
  }
`;

const StyledReactDatePicker = styled(ReactDatePicker)`
  display: block;
  width: 100%;
  height: 60px;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${colors.primaryBlue};
  padding: 20px;
  background: ${colors.dividerGrey};
  border-radius: 10px;
`;

export default function DatePicker<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
>(props: ReactDatePickerProps<CustomModifierNames, WithRange>) {
  return (
    <Overrides>
      <StyledReactDatePicker {...props} />
    </Overrides>
  );
}

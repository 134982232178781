import styled from 'styled-components';
import { colors } from '../styles';
import { BatchFileStatus } from '../types';

export const ControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: fit-content;

  gap: 20px;
`;

export const TableStatusText = styled.p<{ color: string }>`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 18px;
  color: ${(p) => p.color};
`;

export const WarningText = styled.p`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 18px;
  width: 100%;
  text-align: center;
  color: ${colors.accentOrange};
`;

export const ActionButtonsGrid = styled.div`
  display: flex;
  gap: 5px;
`;

// We reuse this in multiple batch file tables
export const batchFileStatusToDisplay = (status: BatchFileStatus | null) => {
  if (!status) {
    return null;
  }

  switch (status) {
    case BatchFileStatus.Pending:
      return <TableStatusText color={colors.accentOrange}>Pending</TableStatusText>;
    case BatchFileStatus.Started:
      return <TableStatusText color={colors.accentOrange}>Started</TableStatusText>;
    case BatchFileStatus.Success:
      return <TableStatusText color={colors.accentGreen}>Completed</TableStatusText>;
    case BatchFileStatus.ManualReview:
      return <TableStatusText color={colors.accentOrange}>Manual Review</TableStatusText>;
    case BatchFileStatus.Error:
      return <TableStatusText color={colors.accentRed}>Error</TableStatusText>;
    case BatchFileStatus.Cancelled:
      return <TableStatusText color={colors.textGrey}>Cancelled</TableStatusText>;
    case BatchFileStatus.SystemError:
      return <TableStatusText color={colors.accentRed}>System Error</TableStatusText>;
    default:
      return <TableStatusText color={colors.accentOrange}>N/A</TableStatusText>;
  }
};

export const DEFAULT_RESULTS_PER_TABLE_PAGE = 15;

import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import refiner from 'refiner-js';
import { CustomerProfile } from './shared/types';

declare global {
  interface Window {
    $fvIdentity: {
      id?: string;
      name?: string;
      env?: string;
      roles?: string[];
    };
  }
}

export function identifyCustomerProfile(customerProfile: CustomerProfile) {
  const {
    createdAt,
    customer: { _id: customerId, humanReadableName: customerName, orgIdentifier },
    email,
    _id: id,
  } = customerProfile;

  // Identify to refiner
  refiner('identifyUser', {
    createdAt,
    customer: customerId,
    customerName,
    email,
    id,
    type: 'customer',
  });

  Sentry.setUser({ email, id });
  Sentry.getCurrentScope().setTag('customerName', customerName || orgIdentifier);

  // Identify to amplitude
  amplitude.setUserId(id);
  amplitude.setGroup('customer', customerName || orgIdentifier);
  const identifyEvent = new amplitude.Identify();
  identifyEvent.set('createdAt', createdAt.toString());
  identifyEvent.set('email', email);
  identifyEvent.set('type', 'customer');
  identifyEvent.set('customerProfileId', id);
  amplitude.identify(identifyEvent);

  // Identify FullView
  window.$fvIdentity = {
    id: customerProfile._id,
    name: customerProfile.email,
    env: process.env.REACT_APP_ENV,
    roles: ['Customer Dashboard'],
  };
}

export function initAnalytics() {
  if (['production', 'sandbox'].includes(process.env.REACT_APP_ENV || '')) {
    attachFullviewScript();
  }

  if (process.env.REACT_APP_REFINER_PROJECT_ID) {
    refiner('setProject', process.env.REACT_APP_REFINER_PROJECT_ID);
  }

  if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
  }
}

export const trackCoreCustomerEvent = (
  coreCustomerId: string,
  coreCustomerEmail: string,
  coreCustomerName: string,
  event: string,
  properties?: amplitude.Types.EventOptions
) => {
  amplitude.setUserId(coreCustomerId);
  amplitude.setGroup('customer', coreCustomerName);
  const identifyEvent = new amplitude.Identify();
  identifyEvent.set('email', coreCustomerEmail);

  amplitude.identify(identifyEvent);
  amplitude.track(event, properties);
};

export function trackAmplitudeEvent(event: string, properties?: amplitude.Types.EventOptions) {
  amplitude.track(event, properties);
}

function attachFullviewScript() {
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://install.fullview.io';
  script.dataset.org = '8a249d19-a210-4312-b6b6-b0816d34be5f';
  document.head.appendChild(script);
}

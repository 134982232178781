import { useAuth } from '@/services/useAuth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import serverApi from './serverApi';

async function postRequestMagicLink(email: string) {
  const response = await serverApi.post('/dashboard/v0/profile/login', {
    email,
  });
  return response.data;
}

export async function postAuthenticationToken(stytchToken: string) {
  const response = await serverApi.post(
    '/dashboard/v0/profile/authenticate',
    {
      stytchToken,
    },
    {
      // Pass an idempotency key so we don't accidentally cause 401s
      headers: { 'IDEMPOTENCY-KEY': stytchToken },
    }
  );
  return response.data;
}

export async function postLogoutProfile() {
  const response = await serverApi.post('/dashboard/v0/profile/logout');
  return response.data;
}

export const useRequestMagicLink = () => {
  return useMutation({
    mutationFn: (email: string) => postRequestMagicLink(email),
  });
};

async function postExchangeProfile(customerId: string) {
  const response = await serverApi.post<{ sessionToken: string }>(
    '/dashboard/v0/profile/exchange_session',
    {
      customerId,
    }
  );
  return response.data;
}

export const useExchangeProfile = () => {
  const queryClient = useQueryClient();
  const { setSessionToken } = useAuth();

  return useMutation({
    mutationFn: (customerId: string) => postExchangeProfile(customerId),
    useErrorBoundary: false,
    onSuccess: async ({ sessionToken }) => {
      setSessionToken(sessionToken);
      return queryClient.invalidateQueries();
    },
  });
};

import { Button } from '@/components/ui/button';
import { useBulkCreateTaxes } from '@/services/useWorkerTaxes';
import { useState } from 'react';
import { WorkerWithEarnings } from '../taxes.types';
import { TAX_YEARS } from '../utils';
import { TaxTotals } from './TaxTotals';
import { taxWizardColorMap } from './utils';

type Props = {
  workers: WorkerWithEarnings[];
  workersReadyToGenerate: WorkerWithEarnings[];
};

export const Generate1099Step = ({ workers, workersReadyToGenerate }: Props) => {
  const [successMessage, setSuccessMessage] = useState('');
  const bulkCreateTaxes = useBulkCreateTaxes();

  const handleGenerate = () => {
    if (workersReadyToGenerate.length === 0) return;

    bulkCreateTaxes.mutate(
      {
        year: TAX_YEARS[0],
      },
      {
        onSuccess: (data: { count: number; workersToCreate: string[] }) => {
          setSuccessMessage(
            `Successfully queued 1099-NEC generation for ${data.count} workers. You will receive an email when the forms are ready to review.`
          );
        },
      }
    );
  };

  const sections = [
    {
      title: 'Total Workers',
      count: workers.length,
      accentColor: taxWizardColorMap.default,
      items: workers.map((worker) => ({
        id: worker.id,
        label: (worker.profile?.legalName || worker.profile?.email) ?? '----',
        description: `$${(worker.totalEarnings / 100).toFixed(2)} earned`,
      })),
      emptyMessage: 'No workers found',
    },
    {
      title: 'Eligible for 1099-NEC generation',
      count: workersReadyToGenerate.length,
      accentColor: taxWizardColorMap.readyToGenerate,
      items: workersReadyToGenerate.map((worker) => ({
        id: worker.id,
        label: (worker.profile?.legalName || worker.profile?.email) ?? '----',
        description: `$${(worker.totalEarnings / 100).toFixed(2)} earned`,
      })),
      emptyMessage: 'No eligible workers found',
    },
  ];

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Generate 1099 Forms</h3>
        <p className="text-sm text-gray-500">
          Review and generate 1099-NEC forms for your workers based on their earnings.
        </p>
        <p className="text-sm text-gray-500 mt-1">
          Workers who earned $600 or more in total earnings (platform + external) are eligible for
          1099-NEC generation.
        </p>
      </div>

      <TaxTotals
        sections={sections}
        defaultExpanded={false}
        defaultExpandedSection="Eligible for 1099-NEC generation"
      />

      {successMessage && (
        <div className="rounded-md bg-green-50 p-4">
          <p className="text-sm text-green-700">{successMessage}</p>
        </div>
      )}

      <div className="flex items-center justify-end gap-4">
        {workersReadyToGenerate.length > 0 && !successMessage && (
          <p className="text-sm text-amber-500">Workers ready - click to generate 1099-NECs</p>
        )}
        <Button
          variant="outline"
          onClick={handleGenerate}
          disabled={workersReadyToGenerate.length === 0 || bulkCreateTaxes.isLoading}
        >
          {bulkCreateTaxes.isLoading ? 'Generating...' : 'Generate 1099-NECs'}
        </Button>
      </div>
    </div>
  );
};


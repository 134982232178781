import styled from 'styled-components';
import { colors } from '../styles';

export const CreateOneCol = styled.div`
  width: fit-content;
  min-width: fit-content;
  height: fit-content;
  min-height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: start;
  align-items: start;
`;

export const ControlsRow = styled.div`
  width: fit-content;
  height: fit-content;
  min-height: fit-content;

  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: start;
  align-items: start;
`;

export const ResultsRow = styled.div`
  width: fit-content;
  height: fit-content;
  gap: 20px;
`;

export const ControlsCol = styled.div`
  width: fit-content;
  min-width: fit-content;
  height: fit-content;
  min-height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
  align-items: start;
`;

export const LabelRow = styled.div<{ invalid?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(p) => (p.invalid ? colors.primaryRed : colors.primaryBlue)};
  justify-content: start;
  gap: 4px;
  width: 100%;
  max-width: 100%;
`;
export const LabelText = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  color: inherit;
  line-height: 18px;
`;

export const ErrorText = styled(LabelText)<{ maxWidth?: string }>`
  font-size: 1rem;
  color: ${colors.primaryRed};
  font-weight: 500;
  padding-bottom: 8px;
  word-wrap: break-word;
  max-width: ${(p) => p.maxWidth || 'fit-content'};
`;

export const SuccessText = styled(ErrorText)`
  max-width: fit-content;
  color: ${colors.accentGreen};
`;

// Account for the label that the submit button doesn't have
export const SubmitButtonBox = styled.div`
  margin-top: 28px;
  width: fit-content;
  height: fit-content;
`;

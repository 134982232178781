import { Button } from '@checkrx/pay-component-library';
import styled from 'styled-components';

import { ContentWrapper, DescriptionText } from '@/shared/components/ConfirmationModals.styled';
import { ReactNode, useState } from 'react';

const Wrapper = styled(ContentWrapper)`
  gap: 10px;
`;

const Container = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 15px;
  margin-top: -20px;
  margin-bottom: 20px;
`;

const SubmitRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface ModalContentProps {
  description?: string;
  children: ReactNode;
  submitText?: string;
  confirmSubmit?: boolean;
  confirmContent?: ReactNode;
  disableSubmit?: boolean;
  onSubmit: (e: React.MouseEvent | React.KeyboardEvent) => void;
  onCancel: (e: React.MouseEvent | React.KeyboardEvent) => void;
  isSubmitLoading: boolean;
}

export default function ModalContent({
  description,
  children,
  submitText = 'Submit',
  confirmSubmit = false,
  confirmContent,
  disableSubmit = false,
  onSubmit,
  onCancel,
  isSubmitLoading,
}: ModalContentProps) {
  const [showConfirmContent, setShowConfirmContent] = useState(false);

  const handleSubmit = (e: React.MouseEvent | React.KeyboardEvent) => {
    if (confirmSubmit && !showConfirmContent) {
      setShowConfirmContent(true);
    } else {
      onSubmit(e);
    }
  };

  const handleCancel = (e: React.MouseEvent | React.KeyboardEvent) => {
    if (showConfirmContent) {
      setShowConfirmContent(false);
    } else {
      onCancel(e);
    }
  };

  return (
    <Wrapper>
      <DescriptionText>{description}</DescriptionText>
      <Container>{showConfirmContent ? confirmContent : children}</Container>
      <SubmitRow>
        <Button
          text={showConfirmContent ? 'Back' : 'Cancel'}
          sizeVariant="small"
          colorVariant="white"
          onClick={handleCancel}
        />
        <Button
          text={showConfirmContent ? 'Confirm' : submitText}
          sizeVariant="small"
          colorVariant="brand"
          onClick={handleSubmit}
          loading={isSubmitLoading}
          disabled={disableSubmit}
        />
      </SubmitRow>
    </Wrapper>
  );
}

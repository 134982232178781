import { toast } from '@/app/wrappers/Toaster/Toaster';
import { Button } from '@/components/ui/button';
import { DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useCreateOneTaxes } from '@/services/useWorkerTaxes';
import DollarAmount from '@/shared/components/DollarAmount';
import { WorkerWithEarnings } from '../taxes.types';
import { TAX_YEARS } from '../utils';

type Props = {
  worker: WorkerWithEarnings;
  onSuccess?: () => void;
  onClose?: () => void;
};

const TOTAL_REQUIRED_FOR_TAX_LIABILITY = 600_00;

export const GenerateIndividual1099 = ({ worker, onSuccess, onClose }: Props) => {
  const createOneTaxes = useCreateOneTaxes();

  const externalEarnings = worker?.['1099nec']?.[TAX_YEARS[0]]?.nonPlatformIncome ?? 0;
  const overrideTotalEarningsAmount =
    worker?.['1099nec']?.[TAX_YEARS[0]]?.overrideTotalEarningsAmount ?? 0;

  const handleGenerate = () => {
    if (worker.totalEarnings < TOTAL_REQUIRED_FOR_TAX_LIABILITY) {
      toast({
        message: '1099-NEC not required for less than $600 total earnings',
        type: 'error',
        duration: 5000,
      });
      return;
    }

    createOneTaxes.mutate(
      {
        workerProfileId: worker.id,
        year: TAX_YEARS[0],
        nonPlatformEarningsAmount: externalEarnings,
      },
      {
        onSuccess: () => {
          toast({
            message: 'Successfully queued 1099-NEC generation',
            type: 'success',
            duration: 5000,
          });
          onSuccess?.();
        },
      }
    );
  };

  const isDisabled =
    worker.totalEarnings < TOTAL_REQUIRED_FOR_TAX_LIABILITY || createOneTaxes.isLoading;

  return (
    <DialogContent className="max-w-2xl">
      <DialogHeader>
        <DialogTitle>Generate 1099 Form</DialogTitle>
        <p className="text-sm text-muted-foreground">
          Generate a 1099-NEC form for {worker.profile?.legalName || worker.profile?.email} based on
          their earnings.
        </p>
      </DialogHeader>

      <div className="py-4 space-y-6">
        <div className="rounded-md border p-4 space-y-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-medium">{worker.profile?.legalName || worker.profile?.email}</p>
              <p className="text-sm text-gray-500">
                <DollarAmount amountCents={worker.earnedCentsTotal} /> earned on platform
              </p>
              {externalEarnings ? (
                <p className="text-sm text-gray-500">
                  <DollarAmount amountCents={externalEarnings} /> earned externally
                </p>
              ) : null}
              {overrideTotalEarningsAmount > 0 && (
                <p className="text-sm text-amber-500 mt-2">
                  Note: Total earnings have been manually overridden to{' '}
                  <DollarAmount amountCents={overrideTotalEarningsAmount} />
                </p>
              )}
            </div>
          </div>

          <div className="flex justify-between text-sm">
            <span className="font-medium">Total earnings:</span>
            <DollarAmount amountCents={worker.totalEarnings} />
          </div>
        </div>
      </div>

      <p className="text-sm text-muted-foreground mt-1">
        Note: A minimum of $600 in total earnings is required to generate a 1099-NEC form.
      </p>

      <div className="flex justify-between">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleGenerate} disabled={isDisabled}>
          {createOneTaxes.isLoading ? 'Generating...' : 'Generate 1099-NEC'}
        </Button>
      </div>
    </DialogContent>
  );
};


import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { BatchFile, BatchFileStatus } from '@/shared/types';
import { copyText } from '@/shared/utils/copy-text';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { PayoutBatchStatusText } from './PayoutBatchStatusText';

interface PayoutsBatchSheetProps {
  batchFile?: BatchFile;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCancelBatchFile: (batchFileId: string) => Promise<void>;
}

export const PayoutsBatchSheet = ({
  batchFile,
  open,
  onOpenChange,
  onCancelBatchFile,
}: PayoutsBatchSheetProps) => {
  if (!batchFile) return null;

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        menuOptions={[
          {
            label: 'Copy batch file ID',
            action: () => {
              copyText(batchFile._id);
            },
          },
          {
            label: 'Download input file',
            state: batchFile.inputFileData ? 'enabled' : 'hidden',
            action: () => {
              if (batchFile.inputFileData) {
                fileDownload(batchFile.inputFileData, batchFile.inputFileName);
              }
            },
          },
          {
            label: 'Download return file',
            state:
              batchFile.returnFileData &&
              [
                BatchFileStatus.ManualReview,
                BatchFileStatus.Error,
                BatchFileStatus.Success,
              ].includes(batchFile.status)
                ? 'enabled'
                : 'hidden',
            action: () => {
              if (batchFile.returnFileData) {
                fileDownload(batchFile.returnFileData, `return-${batchFile.inputFileName}`);
              }
            },
          },
          {
            label: 'Cancel batch file',
            state: batchFile.status === BatchFileStatus.Pending ? 'enabled' : 'hidden',
            action: () => {
              onCancelBatchFile(batchFile._id);
            },
          },
        ]}
      >
        <SheetHeader>
          <div className="flex justify-between items-center">
            <SheetTitle>Batch File Details</SheetTitle>
          </div>
        </SheetHeader>

        <div className="mt-6 space-y-6">
          <div className="space-y-2">
            <h3 className="text-sm font-medium text-labelGrey">File Information</h3>
            <div className="bg-backgroundGrey p-4 rounded-lg space-y-2">
              <p className="text-sm flex justify-between">
                <span className="text-labelGrey">File Name:</span>
                <span className="font-medium">{batchFile.inputFileName}</span>
              </p>
              <p className="text-sm flex justify-between">
                <span className="text-labelGrey">Status:</span>
                <PayoutBatchStatusText status={batchFile.status} />
              </p>
              <p className="text-sm flex justify-between">
                <span className="text-labelGrey">Uploader:</span>
                <span className="font-medium">{batchFile.customerProfile?.email || 'Admin'}</span>
              </p>
            </div>
          </div>

          <div className="space-y-2">
            <h3 className="text-sm font-medium text-labelGrey">Processing Information</h3>
            <div className="bg-backgroundGrey p-4 rounded-lg space-y-2">
              <p className="text-sm flex justify-between">
                <span className="text-labelGrey">Date Uploaded:</span>
                <span className="font-medium">
                  {dayjs(batchFile.createdAt).format('D MMM YYYY, h:mma')}
                </span>
              </p>
              <p className="text-sm flex justify-between">
                <span className="text-labelGrey">Scheduled For:</span>
                <span className="font-medium">
                  {dayjs(batchFile.runAt || batchFile.createdAt).format('D MMM YYYY, h:mma')}
                </span>
              </p>
              <p className="text-sm flex justify-between">
                <span className="text-labelGrey">Successful Payouts:</span>
                <span className="font-medium">{batchFile.successCount}</span>
              </p>
              <p className="text-sm flex justify-between">
                <span className="text-labelGrey">Failed Payouts:</span>
                <span className="font-medium">{batchFile.failureCount}</span>
              </p>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};


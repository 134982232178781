import DollarAmount from '@/shared/components/DollarAmount';
import { Button, Spinner } from '@checkrx/pay-component-library';

import { toast } from '@/app/wrappers/Toaster/Toaster';
import { useRepayPayout } from '@/services/usePayouts';
import {
  ContentWrapper,
  DescriptionText,
  LabelRow,
  LabelText,
  ValueText,
} from '@/shared/components/ConfirmationModals.styled';
import { Payout } from '@/shared/types';

type Props = {
  payout: Payout;
  onClose: () => void;
};

const ConfirmRepayPayoutModal = ({ payout, onClose }: Props) => {
  const { mutateAsync: repayPayout, isLoading: repayPayoutLoading } = useRepayPayout();

  const handleRepayPayout = async () => {
    try {
      await repayPayout({ payoutId: payout.id });
      onClose();
      toast({
        message: `Repayment made for payout ${payout.id}`,
        type: 'success',
        duration: 5000,
      });
    } catch (err) {
      toast({
        message: `Failed to repay payout ${payout.id}`,
        type: 'error',
        duration: 5000,
      });
    }
  };

  return (
    <ContentWrapper>
      <DescriptionText></DescriptionText>
      <LabelRow>
        <LabelText>Earner:</LabelText>
        <ValueText>
          {payout?.workerResource?.profile?.legalName ??
            payout?.workerResource?.profile?.email ??
            ''}
        </ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Amount:</LabelText>
        <DollarAmount amountCents={payout.amountCents} />
      </LabelRow>
      <LabelRow>
        <LabelText>Payout id:</LabelText>
        <ValueText>{payout?.id ?? ''}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Description:</LabelText>
        <ValueText>{payout?.description ?? ''}</ValueText>
      </LabelRow>
      <Button
        text="Repay Payout"
        sizeVariant="big"
        colorVariant="red"
        width="155px"
        onClick={handleRepayPayout}
        disabled={repayPayoutLoading}
      />
      {repayPayoutLoading && <Spinner size="30px" />}
    </ContentWrapper>
  );
};

export default ConfirmRepayPayoutModal;

import styled from 'styled-components';
import { colors } from '../styles';

export const BatchUploadIconButton = styled.button`
  width: 155px;
  min-width: 155px;
  height: 135px;
  min-height: 135px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border: 1px dashed ${colors.accentTeal};
  border-radius: 5px;
  background: none;

  &:hover {
    cursor: pointer;
    filter: brightness(70%);
  }
`;

export const BatchUploadIconText = styled.h3`
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  color: ${colors.accentTeal};
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 30px;
  height: fit-content;
  min-height: fit-content;
  max-height: 100%;
`;

const BatchUploadMessage = styled.p`
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
`;

export const BatchUploadSuccessMessage = styled(BatchUploadMessage)`
  color: ${colors.primaryBlue};
`;

export const BatchUploadErrorMessage = styled(BatchUploadMessage)`
  color: ${colors.primaryRed};
`;

export const Label = styled.label<{ required?: boolean; inline?: boolean }>`
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${colors.primaryBlue};
  margin-bottom: ${(p) => (p.inline ? '0' : '10px')};

  ${({ required }) =>
    required &&
    `
    &::after {
      content: '*';
    }
  `};
`;

export const HelpText = styled.p`
  font-size: 0.875rem;
  font-weight: 300;
  color: ${colors.labelGrey};
  margin-top: 4px;
`;

export const BatchUploadFileInput = styled.input`
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${colors.primaryBlue};
  cursor: pointer;
`;

export const BatchUploadWarning = styled.p`
  font-weight: 500;
  font-size: 1rem;
  color: ${colors.textGrey};
`;

export const BatchUploadBoldSpan = styled.span`
  font-weight: 600;
  font-size: 1rem;
  color: ${colors.primaryBlue};
`;

export const ConfirmationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 10px;
`;

export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const Legalese = styled.p`
  font-weight: 400;
  font-size: 0.875rem;
  color: ${colors.trueBlack};
`;

export const ModalWrapper = styled.div`
  height: fit-content;
  width: 100%;
  padding: 30px 40px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 30px;
`;

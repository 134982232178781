/**
 * Higher-Order component that listens for Customer Profile changes and
 * Navigates to failure if there is no-such profile.
 *
 * TODO(Carter):
 * - Decide if we want to update any time any data updates or only certain pieces
 */
import { identifyCustomerProfile } from '@/analytics';
import { useCustomerProfile } from '@/services/useCustomerProfile';
import useLoading from '@/shared/hooks/useLoading';
import { LoadingScope } from '@/shared/types';
import { ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export const CustomerProfileGuard = ({ children }: { children: ReactNode }) => {
  const { data: profile, isLoading, isSuccess } = useCustomerProfile();
  const setLoading = useLoading(isLoading, LoadingScope.customerDashboard);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  useEffect(() => {
    if (isSuccess && profile?._id) {
      identifyCustomerProfile(profile);
    }
  }, [isSuccess, profile]);

  if (isSuccess && !profile?._id) {
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};

import { useMutation } from '@tanstack/react-query';
import serverApi from './serverApi';

type GetInTouchRequest = {
  name: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  accountId: string;
  companyWebsite: string;
  payoutVolume: string;
  activeEarners: string;
};

async function postGetInTouch(req: GetInTouchRequest) {
  const response = await serverApi.post('/dashboard/v0/get_in_touch', req);

  return response.data;
}

export const useGetInTouch = () => {
  return useMutation({
    mutationFn: (req: GetInTouchRequest) => postGetInTouch(req),
  });
};

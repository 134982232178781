/**
 * UserPermissionsSelector.tsx
 * A reusable way to display / set permissions
 */
import ExplainerCircle from '@/shared/components/ExplainerCircle';
import { colors } from '@/shared/styles';
import { Permissions, TabPermissions } from '@/shared/types';
import { Check } from '@checkrx/pay-component-library';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  min-width: 480px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.h3`
  color: ${colors.trueBlack};
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
`;

const LeftLabel = styled(Label)`
  flex: 1;
  width: 25%;
  text-align: start;
`;

const HelperRow = styled.div`
  flex: 1;
  width: auto;
  display: flex;
  height: fit-content;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: start;
  align-items: end;
`;
const CenteredCheck = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const permissionToLabel: Partial<Record<keyof Permissions, string>> = {
  //   apiTab: 'API Keys',
  //   taxTab: 'Taxes',
  //   settingsTab: 'Integration Settings',
  workersTab: 'Workers',
  payoutsTab: 'Payouts',
  financeTab: 'Finances',
  usersTab: 'Users',
  taxTab: 'Taxes',
  sensitiveAccountData: 'Sensitive Data',
  executeRepayments: 'Execute Repayments',
};

// TODO(Carter): Privilege Explainers by the labels
export default function UserPermissionsSelector({
  permissions,
  setPermissions,
}: {
  permissions: Permissions;
  setPermissions: (p: Permissions) => void;
}) {
  return (
    <Wrapper>
      <Row>
        <HelperRow />
        <HelperRow>
          <Label>None</Label>
          <ExplainerCircle
            text={'This user will have no access to the relevant tab'}
            direction="right"
          />
        </HelperRow>
        <HelperRow>
          <Label>Read Only</Label>
          <ExplainerCircle
            text={
              'This user will access to the relevant relevant tab and will be ' +
              'able to see data tables and search them. They will not be ' +
              'able to create, upload batch files, or edit resources on this ' +
              'tab.'
            }
            direction="right"
          />
        </HelperRow>
        <HelperRow>
          <Label>Read & Write</Label>
          <ExplainerCircle
            text={
              'This user will access to the relevant relevant tab and will be ' +
              'able to see and search data tables, as well as ' +
              'create new resources, upload batch files, and edit existing resources on' +
              'this tab.'
            }
            direction="right"
          />
        </HelperRow>
      </Row>
      {Object.entries(permissions).map(([privilege, value]) => {
        return privilege in permissionToLabel ? (
          <Row key={`row-${privilege}`}>
            <LeftLabel>{permissionToLabel[privilege as keyof Permissions]}</LeftLabel>
            <CenteredCheck>
              <Check
                checked={value === TabPermissions.none}
                onClick={() => {
                  setPermissions(
                    Object.assign({}, permissions, {
                      [privilege]: TabPermissions.none,
                    })
                  );
                }}
              />
            </CenteredCheck>
            <CenteredCheck>
              <Check
                checked={value === TabPermissions.read}
                onClick={() => {
                  setPermissions(
                    Object.assign({}, permissions, {
                      [privilege]: TabPermissions.read,
                    })
                  );
                }}
              />
            </CenteredCheck>
            <CenteredCheck>
              <Check
                checked={value === TabPermissions.write}
                onClick={() => {
                  setPermissions(
                    Object.assign({}, permissions, {
                      [privilege]: TabPermissions.write,
                    })
                  );
                }}
              />
            </CenteredCheck>
          </Row>
        ) : (
          <></>
        );
      })}
    </Wrapper>
  );
}

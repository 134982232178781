/**
 * DollarAmount – display cents to dollars in a nice-looking component.
 *
 * Reusable and customizable for different sizes, colors, and views (EG positive / negative signs)
 */
import { colors } from '@/shared/styles';
import styled from 'styled-components';
import { formatCentsAsDollars } from '../utils/formatters';

// Separate out the optional style props from the required amountCents
// so that we don't have to needlessly amountCents that into styled components
interface DollarAmountStyleProps {
  color?: string;
  dollarSize?: string;
  preambleSize?: string;
  centSize?: string;
  positive?: boolean;
  showSign?: boolean;
}
interface DollarAmountProps extends DollarAmountStyleProps {
  amountCents: number;
}

const DollarsText = styled.span<DollarAmountStyleProps>`
  font-weight: 600;
  color: ${(p) => p.color};
  font-size: ${(p) => p.dollarSize};
`;

const Preamble = styled.span<DollarAmountStyleProps>`
  font-size: ${(p) => p.preambleSize || p.dollarSize};
`;

const Cents = styled.span<DollarAmountStyleProps>`
  font-size: ${(p) => p.centSize};
`;

export default function DollarAmount({
  amountCents,
  color = colors.primaryBlue,
  dollarSize = '0.875rem',
  preambleSize, // By default, preambleSize => dollarSize
  centSize = '0.625rem',
  positive = true,
  showSign = false,
}: DollarAmountProps) {
  const stringifiedDollars = formatCentsAsDollars(amountCents);
  // Skip the $
  const stringAmount = stringifiedDollars.slice(1);
  // Separate the dollars and the cents
  const [dollarString, centString] = stringAmount.split('.');

  const preamble = `${showSign ? (positive ? '+ $' : '- $') : '$ '}`;

  return (
    <DollarsText color={color} dollarSize={dollarSize}>
      <Preamble preambleSize={preambleSize} dollarSize={dollarSize}>
        {preamble}
      </Preamble>
      {dollarString}
      <Cents centSize={centSize}>.{centString}</Cents>
    </DollarsText>
  );
}

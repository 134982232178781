/**
 * Just a placeholder view with some button component testing
 */

export default function AdminOverviewTab() {
  return (
    <>
      <p>Overview Tab Coming Soon!</p>
    </>
  );
}

import DollarAmount from '@/shared/components/DollarAmount';
import { Button, Spinner } from '@checkrx/pay-component-library';

import { toast } from '@/app/wrappers/Toaster/Toaster';
import { useCancelPayout } from '@/services/usePayouts';
import {
  ContentWrapper,
  DescriptionText,
  LabelRow,
  LabelText,
  ValueText,
} from '@/shared/components/ConfirmationModals.styled';
import { Payout } from '@/shared/types';
import { isAxiosError } from 'axios';

type Props = {
  payout: Payout;
  onClose: () => void;
};

const ConfirmPayoutCancellationModal = ({ payout, onClose }: Props) => {
  const { mutateAsync: cancelPayout, isLoading: cancelPayoutLoading } = useCancelPayout();

  const handlePayoutCancellation = async () => {
    try {
      await cancelPayout({ payoutId: payout.id });
      onClose();
      toast({
        message: `Payout ${payout.id} has been canceled.`,
        type: 'success',
        duration: 5000,
      });
    } catch (err) {
      const message = errorToMessage(err as Error);
      toast({
        message,
        type: 'error',
        duration: 5000,
      });
    }
  };

  return (
    <ContentWrapper>
      <DescriptionText></DescriptionText>
      <LabelRow>
        <LabelText>Earner:</LabelText>
        <ValueText>
          {payout?.workerResource?.profile?.legalName ??
            payout?.workerResource?.profile?.email ??
            ''}
        </ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Amount:</LabelText>
        <DollarAmount amountCents={payout.amountCents} />
      </LabelRow>
      <LabelRow>
        <LabelText>Payout id:</LabelText>
        <ValueText>{payout?.id ?? ''}</ValueText>
      </LabelRow>
      <LabelRow>
        <LabelText>Description:</LabelText>
        <ValueText>{payout?.description ?? ''}</ValueText>
      </LabelRow>
      <Button
        text="Cancel Payout"
        sizeVariant="big"
        colorVariant="red"
        width="155px"
        onClick={handlePayoutCancellation}
        disabled={cancelPayoutLoading}
      />
      {cancelPayoutLoading && <Spinner size="30px" />}
    </ContentWrapper>
  );
};

const ERROR_MESSAGES: { [id: string]: string } = {
  cannot_cancel_canceled_payout: 'The payout has already been canceled.',
  cannot_cancel_issued_payout: 'The payout cannot be canceled as it has been fully processed.',
  cannot_cancel_payout_being_processed:
    'The payout cannot be canceled as we are processing it. Please try again later.',
  generic: 'Failed to cancel payout. Please contact support for assistance.',
};

const errorToMessage = (err: Error): string => {
  let message;

  if (isAxiosError(err)) {
    const {
      data: { details: errorDetails },
    } = err?.response || { data: {} };

    if (errorDetails && errorDetails[0]) {
      message = ERROR_MESSAGES[errorDetails[0]];
    }
  } else {
    message = err.message;
  }

  return message || (ERROR_MESSAGES.generic as string);
};

export default ConfirmPayoutCancellationModal;

/**
 * An Generic Error page with a nice graphic and refresh button.
 */
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';

import error from '@/shared/assets/error-graphic.svg';
import StandaloneSidebar from '@/shared/components/StandaloneSidebar';
import { colors } from '@/shared/styles';
import { Button } from '@checkrx/pay-component-library';
import { useState } from 'react';

import HardResetErrorPage from './HardResetErrorPage';

import { ErrorContentBox, ErrorTitle, Graphic, Text } from './ErrorPage.styled';
import { ErrorPageProps } from './ErrorPage.types';

const SupportLink = styled.a`
  color: ${colors.accentTeal};
  font-size: 600;
  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: ${colors.accentTeal};
  }
`;
const HardResetLink = styled.button`
  margin-top: -20px;
  font-size: 1rem;
  line-height: 18px;
  font-weight: 400;
  color: ${colors.accentTeal};
  text-align: center;

  &:hover {
    font-weight: 600;
  }
`;

function ErrorPage({ resetErrorBoundary }: ErrorPageProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [shouldHardReset, setShouldHardReset] = useState(false);

  // Has user clicked to request hard-reset?
  if (shouldHardReset) {
    return <HardResetErrorPage resetErrorBoundary={resetErrorBoundary} />;
  }

  return (
    <>
      <StandaloneSidebar />
      <ErrorContentBox>
        <Graphic src={error} />
        <ErrorTitle>Uh oh! Something went wrong!</ErrorTitle>
        {/* TODO(Carter): Display Error in development mode or trigger react app overlay*/}
        <Text>
          There was a mistake on our end! Click below to refresh your dashboard, and if the error
          persists, please visit our{' '}
          <SupportLink
            href="https://customersupport.checkrpay.com/hc/en-us"
            target="_blank"
            rel="noreferrer"
          >
            Customer Support Center
          </SupportLink>{' '}
          or contact{' '}
          <SupportLink href="mailto:customersupport@checkrpay.com" rel="noreferrer">
            CustomerSupport@checkrpay.com
          </SupportLink>{' '}
          for more help.
        </Text>
        <Button
          className="error-page-refresh-btn"
          width="200px"
          sizeVariant="small"
          colorVariant="dark"
          //   leftIcon={<RefreshIcon />}
          text="Back to Safety"
          onClick={async () => {
            // Invalidate all the cached queries
            await queryClient.invalidateQueries();

            if (resetErrorBoundary) {
              resetErrorBoundary();
            }
            navigate('/customer');
          }}
        />
        <HardResetLink onClick={() => setShouldHardReset(true)}>
          Or click here to log out and try again
        </HardResetLink>
      </ErrorContentBox>
    </>
  );
}

export default ErrorPage;

export const colors = {
  trueWhite: '#FFFFFF',
  trueBlack: '#000000',
  // Primary colors
  primaryWhite: '#F6F6F7',
  primaryBlue: '#003975',
  primaryRed: '#FF0000',
  // Accent colors
  accentTeal: '#009BB5',
  accentRed: '#F56E60',
  accentGreen: '#3FB79C',
  accentOrange: '#F9A758',
  // Functional Greys
  dividerGrey: '#E4E7ED',
  backgroundGrey: '#F0F0F4',
  textGrey: '#8799AB',
  labelGrey: '#576C82',
};
